<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div>

      <div class="overflow-auto rounded main-height">
        <div v-if="loading" class="flex justify-center items-center loading-dialog2">
          <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
        </div>

        <div class="flex mb-2 sticky top-0 bg-white">
          <div class="ml-2">
            <input
              type="search"
              name="site" 
              placeholder="物件名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filter.site"
              @change="getData()"
            />
          </div>
          <div class="mx-3">
            <input
              type="search"
              name="client" 
              placeholder="得意先or支店名 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filter.client"
              @change="getData()"
            />
          </div>
          <div>
            <input
              type="search"
              name="address" 
              placeholder="住所 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filter.address"
              @change="getData()"
            />
          </div>
          <div class="mx-3">
            <input
              type="search"
              name="契約" 
              placeholder="契約 検索"
              class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
              v-model="filter.agreement"
              @change="getData()"
            />
          </div>
        </div>

        <div v-if="!loading && !list.length" class="main w-full flex justify-center items-center">
          <p class="text-gray-400 font-bold">対象データがありません</p>
        </div>

        <table class="mx-1 mb-12">
          <thead class="sticky top-10 bg-white">
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider h-10 cursor-pointer hover-color" @click="clickRow(row)">

            <!-- 選択 -->
            <td class="b-top px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 w-20">
              <input
                type="radio"
                :id="row.siteCode"
                :value="row"
                class="cursor-pointer"
                v-model="selectedSite"
              >
            </td>

            <!-- 物件コード -->
            <td class="px-6 py-3 text-sm text-gray-700">
              {{ row.siteCode }}
            </td>

            <!-- 物件名 -->
            <td class="px-6 py-3 text-sm text-gray-700">
              {{ row.site1 }}
              <p>{{ row.site2 }}</p>
            </td>

            <!-- 得意先コード -->
            <td class="px-6 py-3 text-sm text-gray-700">
              {{ row.clientCode }}
            </td>

            <!-- 得意先名 -->
            <td class="px-6 py-3 text-sm text-gray-700">
              {{ row.client1 }}
              <p class="text-xs text-gray-400">{{ row.client2 }}</p>
            </td>

            <!-- 住所 -->
            <td class="px-6 py-3 text-sm text-gray-700">
              〒 {{ row.zip }}
              <p>{{ row.address1 }}</p>
              <p>{{row.address2}}</p>
            </td>

            <!-- 契約 -->
            <td class="px-6 py-3 whitespace-pre-line text-sm text-gray-700">
              {{ row.agreementFlg }}
              <p>{{ row.agreement }}</p>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <PrimaryButton text="選択" size="normal mt-3 w-full" :disabled="selectedSite === null"  @click="selectedBtn()" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
export default {
  components: {
    Modal,
    PrimaryButton
  },

  props: ['open'],

  data() {
    return {
      // カラム
      columns: [
        { title: '選択' },
        { title: '物件コード' },
        { title: '物件名1/物件名2' },
        { title: '得意先コード' },
        { title: '得意先名/支店名' },
        { title: '住所' },
        { title: '契約' }
      ],
      // 検索
      filter: {
        site: '',
        client: '',
        address: '',
        agreement: ''
      },
      // 一覧
      list: [],
      // 選択した物件
      selectedSite: null,
      // ローディング
      loading: false
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * リスト取得
     */
    async getData() {
      this.loading = true
      let res = await backend.searchData('akbsTable/getSiteListForEstimate', this.filter)
      if (res.data && res.data.data && res.data.data.length) {
        this.list = res.data.data
      } else {
        this.list = []
      }
      this.loading = false
    },

    /**
     * 行クリックイベント
     */
    clickRow(target) {
      this.selectedSite = target
    },

    /**
     * 選択ボタンイベント
     */
    selectedBtn() {
      this.$emit('modalSiteSelected', this.selectedSite)
      this.closed()
    },

    /**
     * 閉じる
     */
    closed() {
      this.list = []
      // 検索
      this.filter = {
        site: '',
        client: '',
        address: '',
        agreement: ''
      }
      // 選択した物件
      this.selectedSite = null
    }
  }
}
</script>
<style scoped>
  .main-height {
    height: 70vh;
  }

  .b-top {
    border-top: 1px solid rgb(228, 227, 227);
  }

  .bg-colu {
    background: rgb(233, 244, 247);
  }

  .hover-color:hover {
    background: rgb(233, 244, 247);
  }
</style>