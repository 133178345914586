<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <div class="p-2 text-right">
        <SecondaryButton text="追加" size="sm" @click="edit(null)">
          <Icon iconName="PlusCircle" slot="before" class="h-4 w-4" />
        </SecondaryButton>
      </div>
      <div v-if="!notes || !notes.length" class="text-sm font-bold m-auto">特記事項がありません。</div>
      <div v-else class="flex flex-col h-full border border-gray-300 rounded bg-white">
        <div class="mt-4 mb-1">  
          <Tab 
            :tabs="classifications" 
            @change="classificationChange($event.name)"
          />
        </div>
        <div class="overflow-auto flex-1 max-h-60vh">
          <table class="min-w-full divide-y divide-gray-200 table-auto">
            <thead class="bg-gray-50 ">
              <tr>
                <th scope="col" class="bg-gray-100 p-2 text-xs font-medium text-gray-500 uppercase tracking-wider w-20 text-center sticky top-0 z-10">
                  選択
                </th>
                <th scope="col" class="bg-gray-100 p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10">
                  特記事項
                </th>
                <th scope="col" class="bg-gray-100 p-2 text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 text-left">
                  注意点
                </th>
                <th scope="col" class="bg-gray-100 p-2 text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 w-24 text-center" v-if="filterClassificationName === '全て'">
                  区分名
                </th>
                <th scope="col" class="bg-gray-100 p-2 text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-10 w-24 text-center">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(note, index) in filteredNotes" :key="note._id" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td class="px-2 py-3 whitespace-nowrap text-sm text-gray-500 text-center">
                  <input 
                    name="note._id" 
                    type="checkbox" 
                    class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" 
                    v-model="note.selected"
                  />
                </td>
                <td class="px-2 py-3 text-sm font-medium text-gray-900 whitespace-pre-line">
                  <label :for="note._id" class="cursor-pointer">
                    {{ note.note }}
                  </label>
                </td>
                <td class="px-2 py-3 whitespace-pre-line text-sm text-gray-500 text-center">
                  <span class="inline-flex text-xs leading-5 text-red-600">
                    {{ note.points }}
                  </span>
                </td>
                <td class="px-2 py-3 whitespace-nowrap text-sm text-gray-500 text-center" v-if="filterClassificationName === '全て'">
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {{ note.classification }}
                  </span>
                </td>
                <td class="px-2 py-3 whitespace-nowrap text-center text-sm font-medium" @click="edit(note)">
                  <div class="text-indigo-600 hover:text-indigo-900">編集</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="p-3 flex justify-end">
          <PrimaryButton text="反映" size="sm" class="w-full" @click="selected()" />
        </div>
      </div>
    </Modal>

    <!-- 特記事項を編集 -->
    <EstimateCommonNoteEditModal
      v-model="openEditModal"
      :open="openEditModal"
      :target="target"
      @closeEditModal="closeEditModal"
    />
  </div>
</template>
<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Tab from '@components/Tab.vue'
import SecondaryButton from '@components/SecondaryButton.vue'
import Icon from '@components/Icon.vue'
import EstimateCommonNoteEditModal from '../components/EstimateCommonNoteEditModal.vue'
import * as dialogs from '@libs/dialogs'
import * as estimateManager from '@managers/estimateManager'
import * as utils from '@libs/utils'

export default {
  components: {
    Modal,
    PrimaryButton,
    Tab,
    SecondaryButton,
    Icon,
    EstimateCommonNoteEditModal
  },

  data() {
    return {
      // 大項目リスト
      classifications: [],
      // 特記事項リスト
      notes: [],
      // 選択中の大項目
      filterClassificationName: '',
      // 編集対象
      target: {},
      // 編集モーダルopen
      openEditModal: false,
      // 選択した特記事項
      selectedList: []
    }
  },

	props: ['open'],

  computed: {
    filteredNotes() {
      return this.notes.filter((n) => {
        return this.filterClassificationName === '' || this.filterClassificationName === '全て' || n.classification === this.filterClassificationName
      })
    },

    selectedNotes() {
      return this.notes.filter((n) => {
        return n.selected
      })
    }
  },

  watch: {
    open() {
      if (!this.open) {
        return
      }
      this.getData()
    }

  },

  methods: {
    /**
     * データ取得
     */
    async getData() {
      this.notes = []
      this.notes = await estimateManager.getEstimateCommonNoteText(this)
      this.createClassifications()
    },

    /**
     * 大項目リスト生成
     */
    createClassifications() {
      const classificationNames = utils.unique(this.notes.map((note) => {
        note.classification = note.classification || 'その他'
        return note.classification
      }))
      const classifications = classificationNames.map((name) => {
        return {
          name,
          active: false,
        }
      })
      // 先頭に「全て」を追加、選択中にする
      classifications.unshift({
        name: '全て',
        active: true,
      })
      this.classifications = classifications
    },

    /**
     * 大項目選択
     * @param classificationName 選択した大項目名
     */
    classificationChange(classificationName) {
      this.filterClassificationName = classificationName
    },

    /**
     * 特記事項選択
     */
    noteSelectChange() {
      const res = this.notes.filter((n) => {
        return n.selected
      })
      this.selectedList = res
    },

    /**
     * 反映ボタンイベント
     */
    async selected() {
      this.noteSelectChange()
      if (!this.selectedList || !this.selectedList.length) {
        await dialogs.showErrorDialog('未選択', '反映したい特記事項を選択してください。')
        return
      }
      const res = this.selectedList.map((s) => {
        return s.note
      })
      this.$emit('selectedCommonNote', res)
    },

    /**
     * 新規追加、編集モーダルopen
     * @param row 編集対象データ
     */
    edit(row) {
      this.target = row
      this.openEditModal = true
    },

    /**
     * 新規追加、編集モーダルclose
     */
    closeEditModal() {
      this.target = {}
      this.openEditModal = false
      this.getData()
    },

    closed() {
      this.notes = []
    }
  }
}
</script>
<style scoped>
</style>