<template>
  <div>
    <FixedCheckListTableComponent
      typeName='定期清掃'
      :type=3
      :routerName="routerName"
      :scheduledDate="scheduledDate"
      :siteLocation="siteLocation"
    >
    </FixedCheckListTableComponent>
  </div>
</template>

<script>
import FixedCheckListTableComponent from '../components/FixedCheckListTableComponent.vue'

export default {
  components: {
    FixedCheckListTableComponent,
  },

  props: {
    // 報告書を閉じた後の遷移画面
    routerName: {
      type: String,
      default: 'FixedScheduleListByStaff'
    },
    // 予約詳細から遷移した際の予定日
    scheduledDate: {
      type: String,
      default: null
    },
    // 位置情報
    siteLocation: {
      type: Object
    },
  },

}
</script>