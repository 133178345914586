<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div class="main-height overflow-auto">
        <div class="flex justify-around">
          <div>
            <div class="font-bold title mb-3">
              求人媒体ドロップダウンリスト 編集
            </div>

            <div class="text-sm font-bold text-red-600">
              どの媒体が多いのか、調査に使用します。「その他」などの抽象的ではなく、具体的な媒体名にしてください。
            </div>
          </div>
          <div class="py-1 px-2 mt-auto cursor-pointer" @click="add()">
            <Icon
              iconName="PlusCircle"
              class="w-8 h-8 text-blue-800"
            />
          </div>
        </div>
        <table class="overflow-auto main table-auto mb-3">
          <thead class="main sticky top-0 bg-white">
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="pl-3 pr-1 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu text-left" :class="index==0?'pl-3':'pl-1' + c.class">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color">

            <!-- 表示順 -->
            <td class="pl-3 pr-1 py-0.5 whitespace-nowrap text-sm font-medium text-gray-500">
              No.{{ i + 1 }}
            </td>

            <!-- 名前 -->
            <td class="pl-3 pr-1 py-0.5 whitespace-nowrap text-sm font-medium text-gray-500 w-full">
              <input
                type="text"
                class="border border-gray-300 rounded-md focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-full" 
                v-model="row.name"
                @input="edit(row)"
              />
            </td>

            <td class="px-1 py-0.5 text-center text-sm font-medium border-gray">
              <div class="flex justify-center">
                <PrimaryButton text="保存" size="normal" class="w-20" :disabled="!row.editFlg" :class="!row.editFlg?'bg-gray-300':'bg-blue-800'" @click="save(row)" />
                <PrimaryButton text="削除" size="normal" class="ml-2 w-20 bg-red-700" @click="remove(row, i)" />
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div v-if="!loading && (!list || !list.length)" class="flex justify-center items-center text-gray-500 font-bold nothing">
        <p class="text-gray-400 font-bold">対象データがありません</p>
      </div>
      <div class="text-transparent">{{reload}}</div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import * as employmentMediaManager from '@managers/employmentMediaManager'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'

export default {
  components: {
    Modal,
    PrimaryButton,
    Icon
  },

  props: ['open'],

  data() {
    return {
      // ローディング
      loading: false,
      // カラム
      columns: [
        {title: ''},
        {title: '名前'},
        {title: ''},
      ],
      // リスト
      list: [
        { editFlg: false, name: '' }
      ],
      reload: 0
    }
  },

  computed: {
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 初期にリセット
     */
    closed() {
      this.loading = false
      this.$emit('close')
    },

    /**
     * リスト取得
     */
    async getData() {
      this.loading = true
      this.list = await employmentMediaManager.getMediaList(this)
      this.loading = false
    },

    /**
     * 編集ボタンイベント
     * @param row 編集対象データ
     */
    edit(row) {
      row.editFlg = true
      this.reload++
    },

    /**
     * 保存
     * @param row 対象データ
     */
    async save(row) {
      if (!row.name) {
        await dialogs.showErrorDialog('求人媒体名', '未入力です。具体的な媒体名を記入してください。')
        return
      }
      if (row.name.indexOf('その他') >= 0) {
        await dialogs.showErrorDialog('求人媒体名', 'どの媒体が多いのか、調査に使用します。\r\n「その他」などの抽象的ではなく、具体的な媒体名にしてください。')
        return
      }
      const saveData = utils.clone(row)
      delete saveData.editFlg
      await employmentMediaManager.saveData(this, saveData)
      await dialogs.showSuccessDialog('保存しました')
      row.editFlg = false
      this.reload++
    },

    /**
     * 削除
     * @param row 対象データ
     * @param i インデックス
     */
    async remove(row, i) {
      if (row._id) {
        const res = await dialogs.showConfirmDialog('削除してよろしいですか？', row.name + 'を削除します。')
        if (res != 'YES') {
          return
        }
        await employmentMediaManager.deleteTask(this, row)
        await dialogs.showSuccessDialog('削除しました')
      }
      this.list.splice(i, 1)
    },

    add() {
      this.list.push({ name: '' })
    }
  }
}
</script>
<style scoped>
.main-height {
  height: 85vh;
}

.h-95vh {
  height: 95vh;
}

.h-93vh {
  height: 93vh;
}

.main {
  min-width: 700px;
}

.b-top {
  border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
  background: #edecf7;
}

.hover-color:hover {
  background: #edecf7 !important;
}

.title {
  color: #928ec7;
}
</style>