import { render, staticRenderFns } from "./PriceOther.vue?vue&type=template&id=48325f7c&scoped=true&"
import script from "./PriceOther.vue?vue&type=script&lang=js&"
export * from "./PriceOther.vue?vue&type=script&lang=js&"
import style0 from "./PriceOther.vue?vue&type=style&index=0&id=48325f7c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48325f7c",
  null
  
)

export default component.exports