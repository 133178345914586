<template>
	<div>
		<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
			<template #action>
				<div class="content-container-app overflow-auto">
					<div class="text-lg text-blue-800 font-bold" v-if="isNew">新規登録</div>
					<div class="text-lg text-blue-800 font-bold" v-else>編集</div>
					<div class="w-full mt-3">
						<!-- 区分 -->
						<div class="text-xs mb-1 text-gray-700 font-bold">区分<span class="-top-0.5 ml-1 text-red-500"> * </span></div>
						<div class="flex mx-auto w-fit">
							<label class="mr-5 px-3 py-1 cursor-pointer">
								<input type="radio" name="classification" value="1" v-model="sub.classification" @change="getSub2()">
								受注以外
							</label>
							<label class="ml-5 px-3 py-1 cursor-pointer">
								<input type="radio" name="classification" value="2" v-model="sub.classification" @change="getSub2()">
								受注
							</label>
						</div>

						<!-- 名前 -->
						<div class="mt-5">
							<WmsTextInput 
								name="name" 
								caption="名前"
								:required="true"
								v-model="sub.name"
							/>
						</div>

						<div class="flex mt-5 mb-1">
							<div class="text-xs text-gray-700 font-bold mt-auto flex">
                サブタスク
                
                <!-- 並べ替え -->
                <div class="toggle flex items-center mt-auto ml-10 mr-5">
                  <span class="mr-1 text-green-700 font-bold text-xs">並べ替え</span>
                  <Toggle v-model="sortable" size="small" />
                </div>
              </div>
							<PrimaryButton text="追加" size="sm bg-blue-700 py-1 ml-auto" @click="add()" />
						</div>
            
            <draggable
              key="sub"
              :list="sub.group"
              direction="vertical"
              handle=".row-drag-handle"
              tag="div"
            >
              <div v-for="(row, i) in sub.group" :key="i" class="mb-1">
                <div class="flex">
                  <div>
                    <div v-if="sortable" class="mx-1.5 items-center my-1 row-drag-handle prasp">
                      <MenuIcon class="text-gray-400" />
                    </div>
                    <div v-else class="ml-1 my-1 w-8">
                      {{ i + 1 }}.
                    </div>
                  </div>
                  <select
                    v-model="sub.group[i].task"
                    class="border-gray w-full rounded py-1"
                  >
                  <option v-for="(task, index) in sub2" :value="task.name" :key="'s' + index">
                    {{ task.name }}
                  </option>
                  </select>

                  <!-- 削除 -->
                  <div @click="deleteRow(i)" class="ml-2">
                    <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-7 h-7 my-auto" />
                  </div>
                </div>
              </div>
            </draggable>

						<div>
							<PrimaryButton text="登録" size="normal px-10 mb-5 mt-5 w-full" @click="register()" />
						</div>
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import { TrashIcon, MenuIcon } from '@vue-hero-icons/solid'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'

const FORMID = 'task_request_set_master'

const SUB = {
	classification: '1',
	name: '',
	group: [{ task: '' }]
}

export default {
	components: {
		Modal,
		PrimaryButton,
		WmsTextInput,
		TrashIcon,
    MenuIcon,
    Toggle,
    draggable
	},

	props: {
		target: {
			type: Object
		},
		subList: {
			type: Array
		},
		open: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			// 新規作成
			isNew: false,
			// サブタスク依頼
			sub: utils.clone(SUB),
			// サブタスク
			sub2: [],
			// サブタスク（全データ）
			tasks: [],
      // 並べ替え
      sortable: false
		}
	},

	watch: {
		async open() {
			if (!this.open) {
				return
			}
			this.getTarget()
			await this.getSubTask()
		}
	},

	methods: {
		/**
		 * 表示するデータ取得
		 */
		getTarget() {
			// 編集の場合
			if (this.target && Object.keys(this.target).length) {
				this.isNew = false
				this.sub = utils.clone(this.target)
			// 新規の場合
			} else {
				this.isNew = true
				this.sub = utils.clone(SUB)
			}
		},

		/**
		 * サブタスク取得
		 */
		async getSubTask() {
			this.tasks = []
			this.sub2 = []
			this.tasks = await this.$pigeon.getTasksByFormId('task_master')
			this.getSub2()
		},

		/**
		 * 対象の区分と同じサブタスクを取得
		 */
		getSub2() {
			this.sub2 = []
			if (this.sub.classification) {
				this.sub2 = this.tasks.filter((r) => {
					return r.classification == this.sub.classification
				})
			}
		},

		/**
		 * サブタスク追加
		 */
		add() {
			if (!this.sub.group) {
				this.sub.group = [{ task: '' }]
			} else {
				this.sub.group.push({ task: '' })
			}
		},

		/**
		 * サブタスク削除
		 */
		deleteRow(i) {
			this.sub.group.splice(i, 1)
			if (!this.sub.group || !this.sub.group.length) {
				this.add()
			}
		},

		/**
		 * 初期化
		 */
		closed() {
			// 新規作成
			this.isNew = false
			// サブタスク依頼セット
			this.sub = utils.clone(SUB)
			// スタッフリスト
			this.staffList = []
		},

		/**
		 * 登録処理
		 */
		async register() {
			// 必須
			if (!this.sub.classification) {
				await this.errRequired('区分')
				return
			}
			if (!this.sub.name) {
				await this.errRequired('名前')
				return
			}
			// サブタスクを選んでいるか
			const notSub = this.sub.group.find((g) => {
				return !g.task
			})
			if (notSub) {
				await dialogs.showErrorDialog('サブタスク未選択があります', 'サブタスクを選択するか、削除してください。')
				return
			}

			// 名前がかぶっていないか
			const err = this.subList.find((s) => {
				return (s.name == this.sub.name && s._id != this.sub._id && s.classification == this.sub.classification)
			})
			if (err && err.name) {
				await dialogs.showErrorDialog('同じ名前が既にあります。', '同じ名前が既に存在します。\r\n名前を変更してください。')
				return
			}

			// 登録
			try {
				// 新規
				if (this.isNew) {
					await this.$pigeon.registerTask(FORMID, this.sub, {})
				// 更新
				} else {
					await this.$pigeon.updateTask(this.sub._id, this.sub, {})
				}

				// ログ登録
				logManager.recordingByRegist(
					this,
					this.isNew,
					'サブタスク依頼セットマスタ',
					'サブタスク依頼セット（' + this.sub.task + '）'
				)
			} catch (error) {
				alert(error)
			}
				this.$emit('modalClose')
				this.closed()
		},

		/**
		 * 必須項目アラート
		 * @param item 項目名
		 */
		async errRequired(item) {
				await dialogs.showErrorDialog(item + 'は必須です', item + 'が選択されていません。')
		}
	}
}

</script>
<style scoped>
.content-container-app {
	width: 700px;
	padding: 0 10px;
}

.border-gray {
	border: solid #d3cfcf 1px;
}

.drop {
	width: 92%;
	margin-right: auto;
}
</style>