<template>
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
      <template #title>{{ isNew?'新規登録':'編集' }}</template>
      <div class="p-2 text-right">
        <PrimaryButton text="削除" size="sm" class="bg-red-500" @click="deleteNote()">
        </PrimaryButton>
      </div>
      <div class="rounded min-w-740px p-1">
        <!-- 区分名 -->
        <div>
          <WmsTextInput 
            name="classification" 
            caption="区分名" 
            v-model="edit.classification"
          />
        </div>

        <!-- 内容 -->
        <div class="my-5">
          <WmsTextInput 
            name="note" 
            caption="特記事項" 
            :multiline="true"
            :rows="5"
            :require="true"
            v-model="edit.note"
          />
        </div>

        <!-- 注意事項 -->
        <div>
          <WmsTextInput 
            name="points" 
            caption="注意事項（この特記事項に関する備考）" 
            :multiline="true"
            :rows="2"
            v-model="edit.points"
          />
        </div>

        <!-- 保存ボタン -->
        <div>
          <PrimaryButton text="保存" size="sm" class="mt-5 w-full" @click="save()" />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
// import SecondaryButton from '@components/SecondaryButton.vue'
// import { PlusCircleIcon } from '@vue-hero-icons/solid'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as estimateManager from '@managers/estimateManager'
import * as logManager from '@managers/logManager'
import * as dialogs from '@libs/dialogs'
import * as utils from '@libs/utils'

const ROW = { classification: '', note: '', points: '' }

export default {
  components: {
    Modal,
    PrimaryButton,
    WmsTextInput,
    // SecondaryButton,
    // PlusCircleIcon,
  },

  data() {
    return {
      // 編集対象
      edit: utils.clone(ROW),
      // 新規かどうか
      isNew: false
    }
  },

	props: ['open', 'target'],

  computed: {
  },

  watch: {
    open() {
      if (!this.open) {
        return
      }
      this.getData()
    }

  },

  methods: {
    /**
     * データ取得
     */
    getData() {
      this.edit = utils.clone(ROW)
      if (this.target && this.target._id) {
        this.edit = utils.clone(this.target)
        this.isNew = false
      } else {
        this.isNew = true
      }
    },

    /**
     * 保存
     */
    async save() {
      let msg = '新規登録'
      if (!this.edit.note) {
        await dialogs.showErrorDialog('特記事項未記入', '特記事項を記入してください。')
      }

      let res

      // 新規の場合
      if (this.isNew) {
        res = await estimateManager.registerEstimateCommonNote(this, this.edit)
      // 更新の場合
      } else {
        res = await estimateManager.updateEstimateCommonNote(this, this.edit)
        msg = '更新'
      }
      // エラー
      if (!res) {
        await dialogs.showErrorDialog('エラー', '保存できませんでした。')
      // 成功
      } else {
        logManager.recording(this, logManager.Loglevel.INFO, '見積共通特記事項', msg, '見積り共通の特記事項マスタに保存しました。')
        this.$emit('closeEditModal')
        this.closed()
      }
    },

    /**
     * 特記事項削除ボタンイベント
     */
    async deleteNote() {
      if (!this.target.estimateCommonNoteCode) {
        await dialogs.showErrorDialog('エラー', '削除できません。')
        return
      }
      const dia = await dialogs.showConfirmDialog('削除', '本当に削除してよろしいでしょうか？')
      if (dia != 'YES') {
        return
      }
      const res = await estimateManager.deleteEstimateCommonNote(this, this.target.estimateCommonNoteCode)
      // エラー
      if (!res) {
        await dialogs.showErrorDialog('エラー', '削除できませんでした。')
      // 成功
      } else {
        logManager.recording(this, logManager.Loglevel.INFO, '見積共通特記事項', '削除', '見積り共通の特記事項マスタから特記事項を削除しました。')
        this.$emit('closeEditModal')
        this.closed()
      }
    },

    closed() {
      this.edit = utils.clone(ROW)
    }
  }
}
</script>
<style scoped>
</style>