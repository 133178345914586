import * as utils from '@libs/utils'
import * as backend from '@libs/backend'

const TASKS_TYPE ='task_request'
const TASK_REQUEST_ORDER_COLLECTION_NAME ='task_request_order'

export const TaskRequestSchema = {
  requestPostId: '',
  taskRequestRequestNo: '',
  subTaskNo: '',
  requestTask: '',
  departmentId: '',
  departmentName: '',
  toStaffId: '',
  toStaffName: '',
  limitDate: '',
  owner: '',
  commission: '',
  finishPoint: '0',
  finishYear: null,
  finishMonth: null,
  estimationCleanCategory: [],
  estimationInspectCategory: [],
  estimationOtherCategory: [],
  finalCompletionTask: '',
  allCopy: false,
  displayOrder: 0,
  details: []
}

export const OrderTaskRequestSchema = {
  estimateNo: '',
  subTaskNo: '',
  requestTask: '',
  departmentId: '',
  departmentName: '',
  toStaffId: '',
  toStaffName: '',
  limitDate: '',
  finalCompletionTask: '',
  displayOrder: 0,
  details: []
}

/** 
 *  タスク依頼 取得(リクエストポストIDを条件に全件取得)
 */
export async function getByRequestPostId(context, requestPostId, status) {
  const searchCondition = {
    searchCondition: {
      type: TASKS_TYPE,
      status: { $ne: "remove" },
      requestPostId,
    },
    sort: {
      displayOrder: 1
    }
  }
  // 削除済みの場合
  if (status && status == 'remove') {
    delete searchCondition.searchCondition.status
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData || null
    return allocation
  } catch (error) {
    console.log('getByRequestPostId error !!!')
    console.log(error)
    return null
  }
}

/**
 * タスク依頼 _id 取得
 * @param {*} context 
 * @param {*} _id 
 * @returns 
 */

export async function get(context, _id) {
  const searchCondition = {
    searchCondition: {
      type: TASKS_TYPE,
      _id
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData[0] || null
    return allocation
  } catch (error) {
    console.log('getRequest error !!!')
    console.log(error)
    return null
  }
}

/**
 * タスク依頼取得（リクエストポストID、statusでの絞込み）
 * @param {*} context 
 * @param {*} requestPostIds リクエストポストID 配列
 * @param {*} status ステータス
 * @param {*} wording キーワード（部分一致）
 * @returns 
 */
export async function getByRequestPostIdAndStatus(context, requestPostIds, status, wording) {
  const searchCondition = {
    searchCondition: {
      type: TASKS_TYPE,
      status: status,
      requestTask: { $regex: wording },
      requestPostId: { $in: requestPostIds }
    },
    sort: {
      limitDate: 1
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData || null
    return allocation
  } catch (error) {
    console.log('getByRequestPostIdAndStatus error !!!')
    console.log(error)
    return null
  }
}

/** 
 * タスク依頼 登録
 */
export async function register(context, target, cwCooperation) {
  try {
    
    const subTaskNo = await createSubTaskNo(context, target)
    target.subTaskNo = subTaskNo
    const registrationData = createRegistrationData(target)
    console.log('registrationData : ')
    console.log(registrationData)

    const q = {}
    if (cwCooperation === true) {
      q.cwCooperation = 'cw-on'
    } else if (cwCooperation === false) {
      q.cwCooperation = 'cw-off'
    }

    let result = await context.$pigeon.registerTask(TASKS_TYPE, registrationData, q)

    return result._id
  } catch (error) {
    console.log('register error !!!')
    console.log(error)
    return false
  }
}

/**
 * サブタスク番号生成
 * @param {*} context 
 * @param {*} target データ
 * @returns 
 */
async function createSubTaskNo(context, target) {
  const res = await context.$pigeon.getAutomaticNumber(
    TASKS_TYPE,
    'subTaskNo',
    'rp_sub_task_no_setting',
    target
  )
  return res
}

/**
 * 更新
 * @param {*} context 
 * @param {*} target 
 * @param {*} cwCooperation 
 * @returns 
 */
export async function update(context, target, cwCooperation) {
  try {
    const registrationData = createRegistrationData(target)
    registrationData.type = TASKS_TYPE
    console.log('registrationData : ')
    console.log(registrationData)

    const q = {}
    if (cwCooperation === true) {
      q.cwCooperation = 'cw-on'
    } else if (cwCooperation === false) {
      q.cwCooperation = 'cw-off'
    }

    await context.$pigeon.updateTask(registrationData._id, registrationData, q)
    
    return true
  } catch (error) {
    console.log('update error !!!')
    console.log(error)
    return false
  }
}

/** 
 * タスク削除
 */
export async function deleteTask(context, id) {
  try {
    await context.$pigeon.deleteTask(id)
    return true
  } catch (error) {
    console.log('deleteEstimate error !!!')
    console.log(error)
    return false
  }
}

/** 
 * タスク物理削除
 */
export async function deleteTask2(context, subTaskNo) {
  try {
    const deleteCondition = {
      type: TASKS_TYPE,
      subTaskNo
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deleteEstimate error !!!')
    console.log(error)
    return false
  }
}

/**
 * 新規タスク依頼生成
 * @returns 
 */
export function create() {
  const newTaskRequest = { ...utils.clone(TaskRequestSchema) }
  return newTaskRequest
}

// /**
//  * 更新
//  * @param {*} context 
//  * @param {*} org 
//  * @param {*} target 
//  * @returns 
//  */
// export async function updateDispatchDate(context, org, target) {

//   if (!org._id) {
//     console.log('IDが指定されていません。')
//     return false
//   }
  
//   const updateData = {
//     type: TASKS_TYPE,
//     target
//   }

//   try {
//     await context.$pigeon.updateTask(org._id, updateData, {})
//     return true
//   } catch (error) {
//     console.log('updateLeasePeriod error !!!')
//     console.log(error)
//     return false
//   }
// }

/**
 * 登録データ 生成
 */
function createRegistrationData(target) {
  const registrationData = utils.clone(target)
  return registrationData
}

/**
 * タスク依頼検索
 * @param {*} context 
 * @param {*} searchKeys 
 * @param {*} keyword 
 * @param {*} status 
 * @param {*} periodKey 
 * @param {*} period 
 * @returns 
 */
export async function searchTaskRequests(context, searchKeys, keyword, status, periodKey, period) {
  console.log('searchTaskRequests')
  console.log(keyword)
  console.log(status)
  
  const searchCondition = {
    searchCondition: {
      type: TASKS_TYPE,
      status: { $ne: "remove" }
    },
    sort: {
      createDate: -1
    }
  }

  if (periodKey && period) {
    searchCondition.searchCondition[periodKey] = {}
    if (period.start) {
      searchCondition.searchCondition[periodKey].$gte = period.start + ' 00:00:00'
    }
    if (period.end) {
      searchCondition.searchCondition[periodKey].$lte = period.end + ' 23:59:59'
    }
    if (Object.keys(searchCondition.searchCondition[periodKey]).length === 0) {
      delete searchCondition.searchCondition[periodKey]
    }
    // {
    //   $gte: period.start,
    //   $lte: period.end
    // }
  }

  const keywordCondition = buildMultipleKeywordSearchCondition(keyword || '', searchKeys|| [])
  if (keywordCondition) {
    searchCondition.searchCondition = { ...searchCondition.searchCondition, ...keywordCondition } //.$and = keywordCondition
  }

  // if (selectFields) {
  //   searchCondition.selectFields = selectFields
  // }

  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const searchTaskRequests = responseData || []
    return searchTaskRequests
  } catch (error) {
    console.log('searchTaskRequests error !!!')
    console.log(error)
    return []
  }
}

function buildMultipleKeywordSearchCondition(keywordText, searchKeys) {
  keywordText = keywordText.trim()
  if (keywordText === '') {
    return null
  } else {
    const keywords = keywordText.split(/\s+/g)
    const condition = { $and: [] }
    keywords.forEach(function(keyword) {
      const query = { $or: [] }
      searchKeys.forEach(function(searchKey) {
        const expression = {}
        expression[searchKey] = { $regex: keyword, $options: 'i'}
        query.$or.push(expression)
      });
      condition.$and.push(query);
    });
    return condition;
  }
}

/**
 * ステータスをdoneにする
 * @param {*} context 
 * @param {*} id タスクID
 * @returns 
 */
export async function statusDone(context, id) {
  try {
    await context.$pigeon.completeTask(id)
    return true
  } catch (error) {
    console.log('statusDone error !!!')
    console.log(error)
    return false
  }
}

/**
 * ステータスをopenにする
 * @param {*} context 
 * @param {*} id タスクID
 * @returns 
 */
export async function statusOpen(context, id) {
  try {
    await context.$pigeon.incompleteTask(id)
    return true
  } catch (error) {
    console.log('statusOpen error !!!')
    console.log(error)
    return false
  }
}

/**
 * 新規受注タスク依頼 生成
 * @returns 
 */
 export function newOrderTaskRequestCreate() {
  const newOrderTaskRequest = { ...utils.clone(OrderTaskRequestSchema) }
  return newOrderTaskRequest
}

/** 
 * 受注タスク依頼 登録
 */
 export async function orderTaskRequestRegister(context, target, cwCooperation) {
  try {
    const subTaskNo = await createOrderSubTaskNo(context, target)
    target.subTaskNo = subTaskNo
    const registrationData = createRegistrationData(target)
    console.log('registrationData : ')
    console.log(registrationData)

    const q = {}
    if (cwCooperation === true) {
      q.cwCooperation = 'cw-on'
    } else if (cwCooperation === false) {
      q.cwCooperation = 'cw-off'
    }

    await context.$pigeon.registerTask(TASK_REQUEST_ORDER_COLLECTION_NAME, registrationData, q)

    return true
  } catch (error) {
    console.log('register error !!!')
    console.log(error)
    return false
  }
}

/**
 * 受注タスク依頼 更新
 * @param {*} context 
 * @param {*} target 
 * @param {*} cwCooperation 
 * @returns 
 */
 export async function orderTaskRequestUpdate(context, target, cwCooperation) {
  try {
    const registrationData = createRegistrationData(target)
    registrationData.type = TASK_REQUEST_ORDER_COLLECTION_NAME
    console.log('registrationData : ')
    console.log(registrationData)

    const q = {}
    if (cwCooperation === true) {
      q.cwCooperation = 'cw-on'
    } else if (cwCooperation === false) {
      q.cwCooperation = 'cw-off'
    }

    await context.$pigeon.updateTask(registrationData._id, registrationData, q)
    
    return true
  } catch (error) {
    console.log('update error !!!')
    console.log(error)
    return false
  }
}

/** 
 *  受注タスク依頼 取得(受注IDを条件に全件取得)
 */
export async function getByOrderId(context, orderId, status) {
  const searchCondition = {
    searchCondition: {
      type: TASK_REQUEST_ORDER_COLLECTION_NAME,
      status: { $ne: "remove" },
      orderId,
    },
    sort: {
      displayOrder: 1
    }
  }
  // 削除済みの場合
  if (status && status == 'remove') {
    delete searchCondition.searchCondition.status
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    const allocation = responseData || null
    return allocation
  } catch (error) {
    console.log('getByRequestPostId error !!!')
    console.log(error)
    return null
  }
}

/**
 * 受注サブタスク番号生成
 * @param {*} context 
 * @param {*} target データ
 * @returns 
 */
async function createOrderSubTaskNo(context, target) {
  const res = await context.$pigeon.getAutomaticNumber(
    TASK_REQUEST_ORDER_COLLECTION_NAME,
    'subTaskNo',
    'order_sub_task_no_setting',
    target
  )
  return res
}

/** 
 * 受注タスク物理削除
 */
export async function orderTaskRequestDeleteTask(context, subTaskNo) {
  try {
    const deleteCondition = {
      type: TASK_REQUEST_ORDER_COLLECTION_NAME,
      subTaskNo
    }
    await context.$pigeon.generalDelete('Tasks', deleteCondition)
    return true
  } catch (error) {
    console.log('deleteEstimate error !!!')
    console.log(error)
    return false
  }
}

/**
 * サブタスク登録時にpostgresに「提出」サブタスクの完了日を保存
 * @param {*} task サブタスク
 * @param insertUser 更新者
 * @param subtask サブタスクすべて
 */
export async function saveEstimateSubmission(task, insertUser, subtask) {
  // postgresに登録
  let res
  // サブタスクが配列の場合
  if (Array.isArray(task)) {
    // 違うサブに見積作成がある場合のみ
    const isEstimate = task.find((t) => {
      return t.requestTask.indexOf('見積作成') > -1
    })
    if (isEstimate && isEstimate.requestTask) {
      res = await backend.postData('estimateSubmission/saveArry', { task, insertUser })
      if (!res || res.data == 'Internal Server Error') {
        alert('エラーが発生しました。estimateSubmission/save')
      }
    }
  } else {
    // 違うサブに見積作成がある場合のみ
    const isEstimate = subtask.find((t) => {
      return t.requestTask.indexOf('見積作成') > -1
    })
    if (isEstimate && isEstimate.requestTask) {
      res = await backend.postData('estimateSubmission/saveObject', { task, insertUser})
      if (!res || res.data == 'Internal Server Error') {
        alert('エラーが発生しました。estimateSubmission/save')
      }
    }
  }
}