import axiosBase from 'axios'
import { PIGEON_CONFIG } from '@/config'
import * as dialogs from '@libs/dialogs'

export async function contractOutput(orderData) {
  const endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/excel/contract`

  const axios = axiosBase.create()
  const res = await axios({
    method: 'post',
    url: endpoint,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    data: orderData,
    responseType: "blob"
  })

  const title = orderData.order.buildingName2 + '.xlsx'

  try {
    const uint8Array = res.data
    const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const a = document.createElement("a");
    a.href = (window.URL || window.webkitURL).createObjectURL(blob);
    a.download = title;
    a.click();
    a.remove();
  } catch (e) {
    console.log(e)
  }
}

/**
 * 取得
 * @param {*} url URL
 */
export async function getData(url) {
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'get',
    url: endpoint,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'json'
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * 検索
 * @param {*} url URL
 * @param {*} param パラム
 */
export async function searchData(url, param) {
  console.log('searchData url:'+url)
  console.log(param)
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'get',
    url: endpoint,
    params: param,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'json'
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * 保存、検索
 * @param {*} url URL
 * @param {*} data 保存するデータ
 */
export async function postData(url, data) {
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'post',
    url: endpoint,
    data: data,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'json'
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * 保存、検索（Form）
 * @param {*} url URL
 * @param {*} data 保存するデータ
 */
export async function postForm(url, form) {
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()

  let res = await axios({
    method: 'post',
    url: endpoint,
    data: form,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID,
      'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN,
      accept: 'application/json',
      'content-type': 'multipart/form-data'
    }
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * IDで物理削除（物理削除です。ご注意ください）
 * @param {*} url URL
 * @param {*} data 削除したいデータ
 * @param {*} warning 確認ダイアログ
 */
export async function deleteData(url, data, warning) {
  if (!data.id || data.id == '') {
    console.log('削除したいデータのIDが不正です')
    return false
  }

  if (warning) {
    const deleteConf = await dialogs.showWarningConfirmDialog('削除', '削除してよろしいですか？')
    if (deleteConf != 'YES') {
      return false
    }
  }

  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'post',
    url: endpoint,
    data: data,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'json'
  })
  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * ID以外で物理削除（物理削除です。ご注意ください）
 * @param {*} url URL
 * @param {*} key 削除したいデータに紐づくキー
 * @param {*} warning 確認ダイアログ
 */
export async function deleteDataByKey(url, data, warning) {
  if (!data.key || data.key == '') {
    console.log('削除したいデータのkeyが不正です')
    return false
  }

  if (warning) {
    const deleteConf = await dialogs.showWarningConfirmDialog('削除', '削除してよろしいですか？')
    if (deleteConf != 'YES') {
      return false
    }
  }

  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'post',
    url: endpoint,
    data: data,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'json'
  })
  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * 論理削除（削除フラグを立てます）
 * @param {*} url URL
 * @param {*} data 削除するデータ
 */
export async function deleteFlag(url, data) {
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'post',
    url: endpoint,
    data: data,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'json'
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}
/**
 * エクセル出力
 * @param {*} url URL
 * @param {*} param パラム
 */
export async function postBlob(url, param) {
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'post',
    url: endpoint,
    params: param,
    data: param,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'blob'
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}

/**
 * エクセル出力（パラムに配列あり）
 * @param {*} url URL
 * @param {*} param パラム
 */
export async function postBlob2(url, param) {
  let endpoint = `${PIGEON_CONFIG.requestPostEndpoint}/${url}`
  let axios = axiosBase.create()
  let res = await axios({
    method: 'post',
    url: endpoint,
    data: param,
    headers: {'x-tms-app-id': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_ID, 'x-tms-app-token': process.env.VUE_APP_REQUESTPOST_SERVICE_ACCOUNT_APP_TOKEN}, 
    responseType: 'blob'
  })

  try {
    return res
  } catch (e) {
    console.log(e)
  }
}