<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div v-if="loading" class="flex justify-center items-center loading-dialog"></div>
		
		<div v-if="list && list.length">
			<div v-if="type!=4" class="text-xs">行をクリックすると参照画面が開きます</div>
			<div class="flex mb-1">
				<div v-if="type==4" class="text-xs">行をクリックすると参照画面が開きます</div>
				<div v-if="type==4" class="mx-auto">-- {{target.month}}月に承認された見積り（集計対象外含む）--</div>
				<div v-else class="ml-auto text-gray-800 text-xs">
					<span class="mr-10">※「提出」を含むサブを完了した日が提出日</span>
					<span class="mr-10">※目安・実績日数は営業日でカウント</span>
					<span >※見積り総件数は同じメインタスクに対して承認済の見積り件数</span>
				</div>
				<!-- Excelダウンロード -->
				<div class="mt-auto ml-8 mr-2">
					<PrimaryButton text="Excel" class="w-40" @click="download()">
						<Icon iconName="DocumentDownload" slot="before" class="h-4 w-4" />
					</PrimaryButton>
				</div>
			</div>

			<div class="flex flex-wrap">
				<!-- スタッフごとの平均・件数 -->
				<div>
					<div class="flex flex-wrap">
						<div v-if="type!=4" class="tex-right mr-2 mt-27px text-blue-800 font-bold">
							<div>平均日数</div>
							<div>合計件数</div>
							<div>平均金額</div>
							<div>合計金額</div>
              <div v-if="type==1 && department == 1" class="mt-2">基準点</div>
              <div v-if="type==1 && department == 1">目標点</div>
						</div>
						<div v-else class="tex-right mr-2 mt-27px text-blue-800 font-bold">
							<div>新規物件</div>
							<div>追加変更</div>
							<div>スポット</div>
							<div>合計件数</div>
							<div class="mt-2">平均金額</div>
							<div>合計金額</div>
						</div>
						<div v-for="(row, idx) in detailByStaff" :key="'staff' + idx" class="border border-blue-600 mr-1">
							<div class="text-center bg-blue-700 text-white px-2 font-bold">
								{{ row.name }}
							</div>
							<div class="font-bold w-full">
								<!-- 平均日数 -->
								<div v-if="type!=4" class="px-2 text-right">{{ row.ave }} <span class="font-normal text-xs text-gray-700">日</span></div>
								<!-- 新規件数 -->
								<div v-if="type==4" class="px-2 text-right">{{ row.countNew || 0 }} <span class="font-normal text-xs text-gray-700">件</span></div>
								<!-- 追加変更件数 -->
								<div v-if="type==4" class="px-2 text-right">{{ row.countAdd || 0 }} <span class="font-normal text-xs text-gray-700">件</span></div>
								<!-- スポット件数 -->
								<div v-if="type==4" class="px-2 text-right">{{ row.countSpot || 0 }} <span class="font-normal text-xs text-gray-700">件</span></div>
								<!-- 合計件数 -->
								<div class="px-2 text-right">{{ row.count }} <span class="font-normal text-xs text-gray-700">件</span></div>

								<div v-if="type==4" class="border border-blue-600 w-full my-1"></div>

								<!-- 平均金額 -->
								<div class="px-2 text-right">{{ row.avePrice }} <span class="font-normal text-xs text-gray-700">円</span></div>
								<!-- 合計金額 -->
								<div class="pl-3 pr-2 text-right">{{ row.price }} <span class="font-normal text-xs text-gray-700">円</span></div>

								<div v-if="type==1 && department == 1" class="border border-blue-700 w-full my-1"></div>

								<!-- 基準点 -->
								<div v-if="type==1 && department == 1" class="px-2 text-right">{{ row.point }} <span class="font-normal text-xs text-gray-700">点</span></div>
								<!-- 目標点 -->
								<div v-if="type==1 && department == 1" class="px-2 text-right">{{ row.pointG }} <span class="font-normal text-xs text-gray-700">点</span></div>
							</div>
						</div>
					</div>
				</div>
				<!-- 全体の平均、合計 -->
				<div v-if="type != 4" class="flex ml-10">
					<div class="tex-right mr-2 mt-27px text-pink-800 font-bold">
						<div>平均日数</div>
						<div>合計件数</div>
						<div>平均金額</div>
						<div>合計金額</div>
						<div v-if="type==1 && department == 1" class="mt-2">平均基準</div>
						<div v-if="type==1 && department == 1">平均目標</div>
					</div>
					<div class="border border-pink-600 mr-1">
						<div class="text-center bg-pink-700 text-white px-2 font-bold">
							全体
						</div>
						<div class="font-bold w-full">
							<!-- 平均実績日数 -->
							<div class="px-2 text-right">{{ ave }} <span class="font-normal text-xs text-gray-700">日</span></div>
							<!-- 合計件数 -->
							<div class="px-2 text-right">{{ target.totalCount }} <span class="font-normal text-xs text-gray-700">件</span></div>
							<!-- 平均金額 -->
							<div class="px-2 text-right">{{ avePrice }} <span class="font-normal text-xs text-gray-700">円</span></div>
							<!-- 合計金額 -->
							<div class="px-2 text-right">{{ totalPrice }} <span class="font-normal text-xs text-gray-700">円</span></div>

							<div v-if="type==1 && department == 1" class="border border-pink-700 w-full my-1"></div>

							<!-- 平均基準点 -->
							<div v-if="type==1 && department == 1" class="px-2 text-right">{{ avePoint }} <span class="font-normal text-xs text-gray-700">点</span></div>
							<!-- 平均目標点 -->
							<div v-if="type==1 && department == 1" class="px-2 text-right">{{ avePointGoal }} <span class="font-normal text-xs text-gray-700">点</span></div>
						</div>
					</div>
				</div>
				<!-- 全体の件数 -->
				<div v-if="type==4" class="flex border border-black font-bold ml-auto h-w-fit min-w-fit mt-auto">
					<div class="text-xs m-auto px-2 py-3">
						{{target.month}}月承認件数
					</div>
					<div class="m-auto px-2 py-3 border-l border-black">
						{{ list.length }} <span class="font-normal text-xs text-gray-700">件</span>
					</div>
				</div>
				<!-- 平均実績日数推移 -->
				<div v-if="type==1&&department==1" class="ml-10">
					<PrimaryButton text="平均日数の推移" size="sm" :class="chartLoading?'cursor-wait':''" :disabled="chartLoading" @click="openModal()"></PrimaryButton>
				</div>
			</div>
		</div>

    <div v-if="!loading && list.length && type == 1 && department == 1" class="my-1 cursor-pointer rounded-md bg-blue-300 text-white text-sm font-bold w-full px-5 py-1 hover:bg-blue-500" @click="openPointDetail=!openPointDetail">
      <span v-if="!openPointDetail" class="text-xs animate-pulse">▶▶▶</span><span v-else class="text-xs">▼▼▼</span> 点数詳細
    </div>

    <!-- 点数詳細 -->
    <div v-if="openPointDetail && !loading" class="py-1 px-4 bg-gray-50 ">
			<div class="flex">
				<!-- 比重・配分表示トグル -->
				<div class="toggle flex items-center mr-5 mb-1">
					<span class="mr-1 text-gray-500 font-bold text-xs">比重・配分点を表示</span>
					<Toggle v-model="opentWeightPonit" size="small" />
				</div>
				<div class="text-xs text-red-700 ml-20 my-auto">赤文字：基準値より低い値</div>
				<div class="text-xs text-blue-800 ml-auto my-auto mr-3">※負担とは、件数が多い分、日数がかかることを考慮し「速さ」のポイントに加算する分です。</div>
				<!-- マスタ値保存ボタン -->
				<PrimaryButton text="基準値・目標値を保存" size="sm" class="" @click="savePoint()"></PrimaryButton>
			</div>

			<div>
				<div>
					<!-- マスタ値 -->
					<div class="mb-1 w-fit flex">
						<!-- 項目名 -->
						<div class="font-bold px-2">
							<div class="py-1.5 mt-14">件数</div>
							<div class="py-2">合計金額</div>
							<div class="py-2">速さ</div>
							<div v-if="opentWeightPonit" class="py-1.5">負担</div>
						</div>

						<!-- 基準値 -->
						<table class="border border-light-blue-600 text-sm rounded-md">
							<thead>
								<tr class="font-normal px-2 text-light-blue-600">
									<th v-if="opentWeightPonit" class="py-2">比重<div class="text-xs text-red-600">※高いほど重視</div></th>
									<th class="py-2" :class="opentWeightPonit?'':'pb-6'">基準値</th>
									<th class="py-2"></th>
									<th v-if="opentWeightPonit" class="py-2">配分</th>
									<th v-if="opentWeightPonit" class="py-2"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(p, i) in pointMst" :key="'p2' + i" class="">
									<td v-if="opentWeightPonit" class="px-2 text-right">
										<input
											v-if="p.itemCode!=4"
											type="number"
											autocomplete="off"
											name="weight" 
											placeholder="比重"
											class="px-1 py-1 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-11 text-right text-sm"
											v-model="p.weight"
											@change="changePointMst()"
										/> 
									</td>
									<td v-if="p.itemCode!=4 || (opentWeightPonit && p.itemCode==4)" class="pl-2 pr-1 py-1">
										<div class="mx-auto w-fit">
											<input
												type="number"
												autocomplete="off"
												name="value" 
												placeholder="基準値"
												class="px-1 py-1 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-20 text-right text-sm"
												:class="p.itemCode==4?'cursor-not-allowed':''"
												:disabled="p.itemCode==4"
												v-model="p.value"
												@change="changePointMst()"
											/> 
										</div>
									</td>
									<td v-if="p.itemCode!=4 || (opentWeightPonit && p.itemCode==4)" class="pr-2 text-xs text-gray-600">
										{{ getUnit(p.itemCode) }}
									</td>
									<td v-if="opentWeightPonit" class="px-2 text-right">
										<span v-if="p.itemCode!=4">{{ p.points }}</span> <span v-if="p.itemCode!=4" class="text-xs text-gray-600">点</span>
									</td>
									<td v-if="opentWeightPonit" class="px-2 text-xs text-blue-700">
										{{ p.memo }}
									</td>
								</tr>
							</tbody>
						</table>
						<!-- 個々の目標値 -->
						<div class="ml-5">
							<div class="w-full bg-light-blue-600 font-bold text-white text-center text-sm py-1">目標値</div>
							<div class="flex">
								<div v-for="(row, i) in goalPointMst" :key="'goal' + i" class="border border-light-blue-600" :class="i!=goalPointMst.length-1?'mr-1':''">
									<div class="text-center bg-light-blue-600 text-white px-2 text-sm font-bold">
										{{ row.staff }}
									</div>
									<div class="w-full px-2 mt-1">
										<!-- 件数 -->
										<div class="w-fit py-1 mb-0.5">
											<input
												type="number"
												autocomplete="off"
												name="value" 
												placeholder="件数"
												class="px-1 py-1 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-20 text-right text-sm"
												:class="baseCount>row.count?'text-red-700':''"
												v-model="row.count"
												@change="changePointMst()"
											/> 
											<span class="text-xs text-gray-500 pl-1 pr-1">
												件
											</span>
											<!-- 備考 -->
											<span v-if="opentWeightPonit" class="px-2 text-xs text-blue-700">
												{{ row.countMemo }}
											</span>
										</div>
										<!-- 合計金額 -->
										<div class="w-fit py-1 mb-0.5">
											<input
												type="number"
												autocomplete="off"
												name="value" 
												placeholder="合計金額"
												class="px-1 py-1 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-20 text-right text-sm"
												:class="basePrice>row.price?'text-red-700':''"
												v-model="row.price"
												@change="changePointMst()"
											/>
											<span class="text-xs text-gray-500 pl-1 pr-1">
												円
											</span>
											<!-- 備考 -->
											<span v-if="opentWeightPonit" class="px-2 text-xs text-blue-700">
												{{ row.priceMemo }}
											</span>
										</div>
										<!-- 速さ -->
										<div class="w-fit py-1">
											<input
												type="number"
												autocomplete="off"
												name="value" 
												placeholder=""
												class="px-1 py-1 border border-gray-300 rounded-md leading-5 focus:bg-white focus:text-gray-900 w-20 text-right text-sm"
												:class="baseFast>row.fast?'text-red-700':''"
												v-model="row.fast"
												@change="changePointMst()"
											/>
											<!-- 備考 -->
											<span v-if="opentWeightPonit" class="px-2 text-xs text-blue-700">
												{{ row.fastMemo }}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 結果 -->
					<div class="mt-5 mb-3">
						<div class="flex flex-wrap">
							<div class="mr-2 text-light-blue-600 font-bold mt-auto">
								<div class="px-2 my-auto text-gray-400">結果</div>
								<div class="px-2 py-1.5 bg-count">件数</div>
								<div class="px-2 py-1.5 bg-price">金額</div>
								<div class="px-2 py-1.5 bg-fast">速さ<span class="text-xs">（負担含む）</span></div>
								<div v-if="opentWeightPonit" class="px-2 py-0.5 font-normal">負担</div>
								<div class="px-2 py-1.5">点数</div>
							</div>
							<div v-for="(p, idx) in detailByStaff" :key="'staff' + idx" class="border border-light-blue-600 mr-1">
								<div class="text-center bg-light-blue-700 text-white px-2 font-bold text-sm py-1">
									{{ p.name }}
								</div>
								<div class="flex">
									<!-- 基準値に対して -->
									<div class="border-r border-light-blue-700">
										<div class="text-center text-sm font-bold text-light-blue-600 px-5 py-1 bg-light-blue-50 border-b">基準</div>
										<div class="w-full">
											<div class="px-3 py-1.5 text-right bg-count flex justify-around">
												<div :class="p.msg1?'mr-auto':'mx-auto'">{{ p.rate1 }} <span class="text-xs text-gray-500 ">％</span></div>
												<div class="ml-2" v-if="opentWeightPonit">（{{ p.point1 }} <span class="text-xs text-gray-500">点</span>）</div>
												<div v-if="p.msg1" class="my-auto ml-2 text-xs text-blue-700">{{ p.msg1 }}</div>
											</div>
											<div class="px-3 py-1.5 text-right bg-price flex justify-around">
												<div :class="p.msg2?'mr-auto':'mx-auto'">{{ p.rate2 }} <span class="text-xs text-gray-500 ">％</span></div>
												<div v-if="opentWeightPonit">（{{ p.point2 }} <span class="text-xs text-gray-500">点</span>）</div>
												<div v-if="p.msg2" class="my-auto ml-2 text-xs text-blue-700">{{ p.msg2 }}</div>
											</div>
											<div class="px-3 py-1.5 text-right bg-fast flex justify-around" v-tooltip="p.tooltip">
												<div :class="p.msg3?'mr-auto':'mx-auto'">{{ p.rate3 }} <span class="text-xs text-gray-500 ">％</span></div>
												<div v-if="opentWeightPonit">（{{ p.point3 }} <span class="text-xs text-gray-500">点</span>）</div>
												<div v-if="p.msg3" class="my-auto ml-2 text-xs text-blue-700">{{ p.msg3 }}</div>
											</div>
											<div v-if="opentWeightPonit" class="py-0.5 text-gray-500 text-right flex justify-around">
												<div>{{ p.point4 }}</div>
											</div>
											<div class="py-1.5 text-gray-500 text-right flex justify-around">
												<div>{{ p.point }} <span class="text-xs text-gray-500">点</span></div>
											</div>
										</div>
									</div>
									<!-- 目標値に対して -->
									<div class="">
										<div class="text-center text-sm font-bold text-white bg-light-blue-600 px-4 py-1">目標</div>
										<div class="w-full">
											<div class="px-3 py-1.5 text-right bg-count flex justify-around">
												<div :class="p.msgG1?'mr-auto':'mx-auto'">{{ p.rateG1 }} <span class="text-xs text-gray-500 ">％</span></div>
												<div v-if="opentWeightPonit">（{{ p.pointG1 }} <span class="text-xs text-gray-500">点</span>）</div>
												<div v-if="p.msgG1" class="my-auto ml-2 text-xs text-blue-700">{{ p.msgG1 }}</div>
											</div>
											<div class="px-3 py-1.5 text-right bg-price flex justify-around">
												<div :class="p.msgG2?'mr-auto':'mx-auto'">{{ p.rateG2 }} <span class="text-xs text-gray-500 ">％</span></div>
												<div v-if="opentWeightPonit">（{{ p.pointG2 }} <span class="text-xs text-gray-500">点</span>）</div>
												<div v-if="p.msgG2" class="my-auto ml-2 text-xs text-blue-700">{{ p.msgG2 }}</div>
											</div>
											<div class="px-3 py-1.5 text-right bg-fast flex justify-around" v-tooltip="p.tooltipG">
												<div :class="p.msgG3?'mr-auto':'mx-auto'">{{ p.rateG3 }} <span class="text-xs text-gray-500 ">％</span></div>
												<div v-if="opentWeightPonit">（{{ p.pointG3 }} <span class="text-xs text-gray-500">点</span>）</div>
												<div v-if="p.msgG3" class="my-auto ml-2 text-xs text-blue-700">{{ p.msgG3 }}</div>
											</div>
											<div v-if="opentWeightPonit" class="py-0.5 text-gray-500 text-right flex justify-around">
												<div>{{ p.pointG4 }}</div>
											</div>
											<div class="py-1.5 text-gray-500 text-right flex justify-around">
												<div>{{ p.pointG }} <span class="text-xs text-gray-500">点</span></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- レーダーチャート -->
				<!-- <div v-if="department==1" class="ml-5">
					<RadarChart
						:chart-data="chartData"
						:options="options"
						:height="400"
						:width="400">
					</RadarChart>
				</div> -->
			</div>
    </div>

		<div class="rounded overflow-auto" :class="type==4?'main-height1':'main-height2'">
			<table class="main mx-auto w-full">
				<thead class="main sticky top-0">
					<tr v-if="list && list.length" class="border border-gray-200 ">
						<th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu whitespace-pre" :class="c.class">
							{{c.title}}
						</th>
					</tr>
				</thead>

				<!-- 総数 -->
				<tbody v-if="type==4">
					<tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color " @click="toEstimateView(row.estimateNo)">
						<!-- お問い合わせ -->
						<td v-if="i==0||list[i-1].requestNo!=row.requestNo" :rowspan="row.countEstimate" class="border-t-4 b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-yellow-700 bg-white">
							{{ row.requestNo }}
						</td>

						<!-- 見積りNo -->
						<td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-blue-700" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.estimateNo }}
						</td>

						<!-- 担当者 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.staff }}
						</td>
						
						<!-- 区分 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.typeName }}
						</td>
						
						<!-- 物件名 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 font-bold" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.site }}
						</td>
						
						<!-- 承認日 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.approved }}
						</td>
						
						<!-- 税抜金額 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-right min-w-90px" :class="row.countEstimate==1||(list[i-1]&&list[i-1].requestNo!=row.requestNo)?'border-t-4':''">
							{{ row.withoutTaxPrice }}
						</td>
					</tr>
				</tbody>

				<!-- 総数以外 -->
				<tbody v-else>
					<tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color " @click="toEstimateView(row.estimateNo)">
						<!-- 見積りNo -->
						<td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-blue-700">
							{{ row.estimateNo }}
						</td>

						<!-- 担当者 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.staff }}
						</td>
						
						<!-- 得意先 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.client }}
						</td>
						
						<!-- 得意先住所 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.clientAddress }}
						</td>
						
						<!-- 物件名 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 font-bold">
							{{ row.site }}
						</td>
						
						<!-- 物件住所 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500">
							{{ row.siteAddress }}
						</td>
						
						<!-- 依頼日 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.request }}
						</td>
						
						<!-- 見積申請日（自己承認の場合は承認日と同じ。過去データには申請日がないので依頼日が保存されており申請日がないデータが自己承認と判断） -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.approvalPetition || row.request?row.approved:null }}
						</td>
						
						<!-- 承認日 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.approved }}
						</td>
						
						<!-- 提出日 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.submission }}
						</td>
						
						<!-- 目安日数 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.scheduledDays }}
						</td>
						
						<!-- 実績日数（土日祝除く） -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-90px">
							{{ row.actualDays }}
						</td>
						
						<!-- 税抜金額 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-right min-w-90px">
							{{ row.withoutTaxPrice }}
						</td>
						
						<!-- 見積り総件数（同じメインタスクに対しての見積り作成件数 -->
						<td class="font-bold b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center min-w-80px">
							{{ row.countEstimate }}
						</td>
						
						<!-- 点数 -->
						<td class="b-top px-2 py-2 whitespace-nowrap text-sm  text-gray-500 text-center">
							{{ row.point }}
						</td>
					</tr>
				</tbody>
			</table>
			<div v-if="loading" class="main w-full h-full flex justify-center items-center">
				<p class="text-gray-400 font-bold">Loading...</p>
			</div>
			<div v-else-if="(!list || !list.length)" class="main w-full h-full flex justify-center items-center">
				<p class="text-gray-400 font-bold">対象データがありません</p>
			</div>
		</div>
		<CountingDetailAveDaysModal
      v-model="openAveDaysModal"
			:open="openAveDaysModal"
			:chartDataDays="chartDataDays"
			:chartLoading="chartLoading"
			@closed="closeModal()"
		/>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import Icon from '@components/Icon.vue'
import Toggle from '@components/Toggle.vue'
import CountingDetailAveDaysModal from '../components/CountingDetailAveDaysModal.vue'
// import RadarChart from '../components/RadarChart.vue'
import * as backend from '@libs/backend'
import * as moment from 'moment'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import BigNumber from 'bignumber.js'

const COLUMNS1 = [
				{title: '見積りNo', class: ''},
				{title: '担当者', class: ''},
				{title: '得意先', class: ''},
				{title: '得意先住所', class: ''},
				{title: '物件名', class: ''},
				{title: '物件住所', class: ''},
				{title: '依頼日', class: 'text-center'},
				{title: '承認 \r\n 申請日', class: 'text-center'},
				{title: '承認日', class: 'text-center'},
				{title: '提出日', class: 'text-center'},
				{title: '目安 \r\n 日数', class: 'text-center'},
				{title: '実績 \r\n 日数', class: 'text-center'},
				{title: '税抜金額', class: 'text-right pr-2'},
				{title: '見積り\r\n 総件数', class: ''},
        {title: '速さ', class: 'text-right'},
			]

const COLUMNS2 = [
				{title: 'お問い合わせNo \r\n (メインタスク)', class: 'text-center'},
				{title: '見積りNo', class: ''},
				{title: '担当者', class: ''},
				{title: '区分', class: ''},
				{title: '物件名', class: ''},
				{title: '承認日', class: 'text-center'},
				{title: '税抜金額', class: 'text-right pr-2'},
			]
export default {
	components: {
		Modal,
		PrimaryButton,
		Icon,
		Toggle,
		CountingDetailAveDaysModal
    // RadarChart,
	},

	props: ['target', 'department', 'departmentName', 'type', 'typeName', 'open'],

	data() {
		return {
			// カラム
			columns: [],
			// 表示データ
			list: [],
			// 実績日数平均
			ave: null,
			// スタッフごとの情報
			detailByStaff: [],
			// ローディング中
			loading: false,
      // 点数詳細
      openPointDetail: false,
			// 比重・配分点
			opentWeightPonit: false,
      // 点数基準値カラム
      // pointMstColumn: ['項目', '比重', '基準値', '', '配分', ''],
      // 点数基準値
      pointMst: [
        { itemCode: null, item: '', weight: 0, value: 0, points: 0, memo: '' }
      ],
			// 基準値の件数
			baseCount: 0,
			// 基準値の金額
			basePrice: 0,
			// 基準値の速さ
			baseFast: 0,
      // 個々の目標値
      goalPointMst: {},
      // 点数カラム
      pointColumn: ['名前', '件数', '金額', '速さ（負担含む）', '負担'],
			// 平均金額
			avePrice: '0',
			// 合計金額
			totalPrice: '0',
			// 平均基準点数
			avePoint: 0,
			// 平均目標点数
			avePointGoal: 0,
			// チャートデータ（平均実績日数の推移）
			chartDataDays: {},
			// チャートデータモーダル
			openAveDaysModal: false,
			// チャートローディング
			chartLoading: false

			// スタッフごとのカラー
			// staffColor: [],
			// // レーダーチャート
			// chartData: {
			// 	labels: ['件数', '合計金額', '全体の平均金額に対して', '目安日数に対して', '速さ'],
			// 	datasets: [
			// 		{
			// 			label: '',
			// 			borderColor: '',
			// 			fill: false,
			// 			data: []
			// 		}
			// 	]
			// },
			// options: {
			// 	scales: {
			// 		r: {
			// 			min:0,
			// 			max:100
			// 		}
			// 	}
			// }
		}
	},

	computed: {
	},

	watch: {
		async target() {
			await this.getList()
		},
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
			this.openPointDetail = false
			this.opentWeightPonit = false
			this.pointMst = []
			this.goalPointMst = {}
			this.chartDataDays = {}
			this.$emit('closeCountingDetailShow')
		},

		/**
		 * データ取得
		 */
		async getList() {
			if (!this.open) {
				return
			}
			// カラム設定
			this.columns = utils.clone(COLUMNS1)
			// 総数
			if (this.type == 4) {
				this.columns = utils.clone(COLUMNS2)
			} else {
				// スタッフごとのカラーを取得
				// const color = await backend.searchData('estimateCountingDisplay/getAll')
				// if (color.data.data && color.data.data.length) {
				// 	this.staffColor = color.data.data
				// }
			}
			this.list = []
			this.loading = true
			const param = { department: this.department, type: this.type, sMonth: this.target.sMonth, eMonth: this.target.eMonth }
			let result = await backend.searchData('estimateApproved/getByPeriod', param)
			if (result.data.data) {
				this.list = result.data.data
				// 平均など情報を取得
				this.getDetail()
			}

			// 平均日数推移
			if (this.department == 1 && this.type == 1) {
				this.chartLoading = true
				this.getAveDays(result.data.data3)
			}

      // 点数基準値
			if (this.department == 1 && result.data.data2 && this.type == 1) {
				// 基準値
				this.pointMst = []
				// 個々の目標値
				this.goalPointMst = {}

				for (let i = 0; i < result.data.data2.length; i++) {
					const r = result.data.data2[i]
					if (r.type == '基準値') {
						this.pointMst.push(r)
					} else {
						if (!this.goalPointMst[r.staff]) {
							this.goalPointMst[r.staff] = []
						}
						this.goalPointMst[r.staff].push(r)
					}
				}
        this.getPoint(true)
			} else {
				this.getTotalInfo()
			}

			this.loading = false
		},

		/**
		 * 詳細画面を開く
		 */
		toEstimateView(estimateNo) {
			if (estimateNo) {
				let resolvedRoute = this.$router.resolve({ 
					name: 'EstimateView',
					query: {
						estimateNo
					}
				})
				window.open(resolvedRoute.href, '_blank')
			}
		},

		/**
		 * 日付フォーマット
		 * @param val 対象日
		 */
		formatDate(val) {
			if (val) {
				return moment(val).format('YYYY/MM/DD')
			}
			return val
		},

		/**
		 * 平均などの情報を取得
		 */
		getDetail() {
			if (!this.list || !this.list.length) {
				return
			}

			this.ave = null
			this.detailByStaff = []


			// 総数
			if (this.type == 4) {
				// スタッフごと
				let staff = []
				// スタッフごと＆区分ごと
				let staffType = []

				for (let i = 0; i < this.list.length; i++) {
					const l = this.list[i]
					if (!staff.includes(l.staff)) {
						// 平均金額（小数は四捨五入）
						let avePrice = null
						avePrice = Math.round(Number(l.averagePriceStaff)).toLocaleString()
						// 合計金額
						let price = null
						price = Number(l.priceStaff).toLocaleString()

						this.detailByStaff.push({ name: l.staff, count: Number(l.countStaff), avePrice, price })
						staff.push(l.staff)
					}

					// スタッフ＆区分ごとの件数
					if (!staffType.includes(l.staff + l.type)) {
						for (let i = 0; i < this.detailByStaff.length; i++) {
							const d = this.detailByStaff[i]
							if (d.name == l.staff) {
								if (l.typeName == '新規物件') {
									d.countNew = Number(l.countStaffType)
								} else if (l.typeName == '追加変更') {
									d.countAdd = Number(l.countStaffType)
								} else if (l.typeName == 'スポット') {
									d.countSpot = Number(l.countStaffType)
								}
							}
						}
						staffType.push(l.staff + l.type)
					}
				}
			// 総数以外
			} else {
				// 全体の平均実績日数
				if (this.list && this.list[0].average && !isNaN(this.list[0].average)) {
					this.ave = Number(this.list[0].average)
					// 表示は少数第二までにする
					this.ave = Math.floor(BigNumber(this.ave).times(100))
					this.ave = BigNumber(this.ave).div(100)
				}

				// スタッフごと
				let staff = []
				for (let i = 0; i < this.list.length; i++) {
					const l = this.list[i]
					if (!staff.includes(l.staff)) {
						let ave = Number(l.averageStaff)
						// 表示は少数第二までにする
						ave = Math.floor(ave * 100) / 100
						// 平均金額（小数は四捨五入）
						let avePrice = null
						avePrice = Math.round(Number(l.averagePriceStaff)).toLocaleString()
						// 合計金額
						let price = null
						price = Number(l.priceStaff).toLocaleString()

            let fast = l.fastStaff

						this.detailByStaff.push({ name: l.staff, ave, count: Number(l.countStaff), avePrice, price, fast })
						staff.push(l.staff)
					}
				}
			}
		},

		/**
		 * エクセルダウンロード
		 */
		async download() {
			this.loading = true
			const month = utils.daySt(this.target.sMonth, 'YYYY年M月')
			const param = { list: this.list, ave: this.ave, detailByStaff: this.detailByStaff, month, departmentName: this.departmentName, typeName: this.typeName, type: this.type, pointMst: this.pointMst, goalPointMst: this.goalPointMst }
			const res = await backend.postBlob2('estimateCountingDownload/detail', param)

			try {
				const uint8Array = res.data
				const blob = new Blob([uint8Array], { "Content-Type" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
				const a = document.createElement("a");
				a.href = (window.URL || window.webkitURL).createObjectURL(blob)
				a.download = '見積集計_' + this.departmentName + '_' + this.typeName + '_' + month + '.xlsx'
				a.click();
				a.remove();
			} catch (e) {
				console.log(e)
				alert(e)
				this.loading = false
			}
			this.loading = false
		},

    /**
     * 点数基準値の単位
     */
    getUnit(code) {
      switch (code) {
        case 1:
          return '件'
        case 2:
          return '円'
        case 3:
          return ''
        case 4:
          return '件ごとに速さに＋1'
      
        default:
          break;
      }
    },

    /**
     * 点数を取得
		 * @param isInit 初期状態
     */
    getPoint(isInit) {
			// 目標値画面用に成型
			const goalArr = []
			// 基準値
			const base = this.formatPointMst(this.pointMst, null)

      for (let i = 0; i < this.detailByStaff.length; i++) {
        const d = this.detailByStaff[i]
				// 個人の目標値
				let goal = {}

				// 初期状態の場合
				if (isInit) {
					goal = this.formatPointMst(this.goalPointMst[d.name], d.name)
				// 値を変更した場合
				} else {
					goal = this.goalPointMst.find((g) => {
						return g.staff == d.name
					})
				}
				goalArr.push({ ...goal })

        // 件数（基準値）
				this.baseCount = 0
        d.rate1 = 0
        d.point1 = 0
				// 件数（目標）
				d.rateG1 = 0
				d.pointG1 = 0
        if (base.count && base.countP) {
					this.baseCount = base.count
          let res = (d.count / base.count)
          d.rate1 = Math.round(res * 100)
          d.point1 = Math.round(res * base.countP)

					d.msg1 = ''
					const msg = base.count - d.count
					if (msg > 0) {
						d.msg1 = `あと ${msg}件`
					}
        }
        if (goal.count && base.countP) {
          let res = (d.count / goal.count)
          d.rateG1 = Math.round(res * 100)
					// 配分点は基準値のを共有
          d.pointG1 = Math.round(res * base.countP)

					d.msgG1 = ''
					const msg = goal.count - d.count
					if (msg > 0) {
						d.msgG1 = `あと ${msg}件`
					}
        }

				// 金額（基準値）
				this.basePrice = 0
        d.rate2 = 0
        d.point2 = 0
        // 金額（目標）
        d.rateG2 = 0
        d.pointG2 = 0
				// 金額（基準）
        if (base.price && base.priceP && d.price) {
					this.basePrice = base.price
          let res = (Number(d.price.replace(/,/g, '')) / base.price)
          d.rate2 = Math.round(res * 100)
          d.point2 = Math.round(res * base.priceP)

					d.msg2 = ''
					const msg = base.price - Number(d.price.replace(/,/g, ''))
					if (msg > 0) {
						d.msg2 = `あと ${msg.toLocaleString()}円`
						// 万を超えている
						if (msg >= 10000) {
							// 和表記
							let msg2 = Math.ceil(msg / 10000)
							d.msg2 = `あと ${msg2.toLocaleString()}万円`
						}
					}
        }
				// 金額（目標）
        if (goal.price && base.priceP && d.price) {
          let res = (Number(d.price.replace(/,/g, '')) / goal.price)
          d.rateG2 = Math.round(res * 100)
					// 配分点は基準値のを共有
          d.pointG2 = Math.round(res * base.priceP)

					d.msgG2 = ''
					const msg = goal.price - Number(d.price.replace(/,/g, ''))
					if (msg > 0) {
						d.msgG2 = `あと ${msg.toLocaleString()}円`
						// 万を超えている
						if (msg >= 10000) {
							// 和表記
							let msg2 = Math.ceil(msg / 10000)
							d.msgG2 = `あと ${msg2.toLocaleString()}万円`
						}
					}
        }

        // 速さ（基準値）
				this.baseFast = 0
        d.rate3 = 0
        d.point3 = 0
        let f = 0
        // 速さ（目標）
        d.rateG3 = 0
        d.pointG3 = 0
        let fG = 0
				// 速さ（基準値）
        if (base.fast && base.fastP) {
					this.baseFast = base.fast
          // 負担分を加算（基準値）
          if (base.fast2) {
            let res = Math.floor(d.count / base.fast2)
            f = res
            d.point4 = '(' + res + ')'
          }
          if (d.fast) {
            f += Number(d.fast)
          }
          let res = (f / base.fast)
          d.rate3 = Math.round(res * 100)
          d.point3 = Math.round(res * base.fastP)

					d.msg3 = ''
					d.tooltip = ''
					const msg = BigNumber(base.fast).minus(f)
					if (msg > 0) {
						d.msg3 = `あと ${msg}`
						d.tooltip = this.getFastAchieveMemo(msg)
					}
        }
				// 速さ（目標）
        if (goal.fast && base.fastP) {
          // 負担分を加算（目標だが基準値のを使用）
          if (base.fast2) {
            let res = Math.floor(d.count / base.fast2)
            fG = res
            d.pointG4 = '(' + res + ')'
          }
          if (d.fast) {
            fG += Number(d.fast)
          }
          let res = (fG / goal.fast)
          d.rateG3 = Math.round(res * 100)
					// 配分点は基準値のを共有
          d.pointG3 = Math.round(res * base.fastP)

					d.msgG3 = ''
					d.tooltipG = ''
					const msg = BigNumber(goal.fast).minus(f)
					if (msg > 0) {
						d.msgG3 = `あと ${msg}`
						d.tooltipG = this.getFastAchieveMemo(msg)
					}
        }
        // 合計点（基準値）
        d.point = d.point1 + d.point2 + d.point3
        // 合計点（目標）
        d.pointG = d.pointG1 + d.pointG2 + d.pointG3
      }
			this.getTotalInfo()
			// 目標値を画面表示用に成型
			this.goalPointMst = goalArr
    },

		/**
		 * 基準値、目標値を成型
		 * @param mstData マスタ値
		 */
		formatPointMst(mstData, staff) {
      // 基準値の値
      let count = 0
      let price = 0
      let fast = 0
      let fast2 = 0
      // 基準値の点数配分
      let countP = 0
      let priceP = 0
      let fastP = 0
			// 備考
			let countMemo = ''
			let priceMemo = ''
			let fastMemo = ''

			// 基準値
			if (mstData && mstData.length) {
				mstData.forEach((p) => {
					// 件数
					if (p.itemCode == 1) {
						count = p.value
						countP = p.points
						countMemo = p.memo
					// 金額
					} else if (p.itemCode == 2) {
						price = p.value
						priceP = p.points
						priceMemo = p.memo
					// 速さ
					} else if (p.itemCode == 3) {
						fast = p.value
						fastP = p.points
						fastMemo = p.memo
					// 負担
					} else if (p.itemCode == 4) {
						fast2 = p.value
					}
				})
			}
			return { count, price, fast, fast2, countP, priceP, fastP, countMemo, priceMemo, fastMemo, staff }
		},

		/**
		 * 全体の数値を取得
		 */
		getTotalInfo () {
			if (this.type == 4) {
				return
			}
			this.totalPrice = '0'
			let price = 0
			this.avePrice = '0'
			this.avePoint = 0
			this.avePointGoal = 0
			let totalPoint = 0
			let totalPointG = 0

			if (this.detailByStaff.length) {
				for (let i = 0; i < this.detailByStaff.length; i++) {
					const d = this.detailByStaff[i]
					// 合計金額
					if (d.price) {
						price += Number(d.price.replace(/,/g, ''))
					}
					// 基準合計点数
					if (d.point) {
						totalPoint += Number(d.point)
					}
					// 目標合計点数
					if (d.pointG) {
						totalPointG += Number(d.pointG)
					}
				}

				// 3桁区切り
				this.totalPrice = price.toLocaleString()
				// 平均金額
				this.avePrice = Math.floor(price / Number(this.target.totalCount)).toLocaleString()
				// 平均基準点数
				this.avePoint = Math.floor(totalPoint / this.detailByStaff.length)
				// 平均目標点数
				this.avePointGoal = Math.floor(totalPointG / this.detailByStaff.length)
			}
			// this.getDataset()
		},

		/**
		 * レーダーチャート生成
		 */
		getDataset() {
			if (this.department == 2) {
				return
			}
			this.chartData.datasets = []
			let dataset = []
			for (let i = 0; i < this.detailByStaff.length; i++) {
				const d = this.detailByStaff[i]
				// カラーを取得
				let borderColor = ''
				if (this.staffColor.length) {
					const color = this.staffColor.find((s) => {
						return s.staff == d.name
					})
					if (color && color.color) {
						borderColor = color.color
					}
				}
				// 平均金額に対する達成度を取得
				let avePrice = 0
				if (this.avePrice && this.avePrice !='0' && d.avePrice && d.avePrice != '0') {
					avePrice =  Math.floor((Number(d.avePrice.replace(/,/g, '')) / Number(this.avePrice.replace(/,/g, ''))) * 100)
				}
				let row = {
					label: d.name,
					borderColor,
					fill: false,
					data: [d.rate1, d.rate2, avePrice, d.achieved, d.rate3]
				}
				dataset.push(row)
			}
			this.chartData.datasets = dataset
		},

    /**
     * 点数基準値の備考
     */
    changePointMst() {
      // 基準値
      let count = 0
      let price = 0
      // let fast = 0
      // 比重
      let countW = 0
      let priceW = 0
      let fastW = 0
      // 配分点合計
      let total = 0

			// 基準値
      this.pointMst.forEach((p) => {
        if (p.itemCode == 1) {
          countW = Number(p.weight)
        } else if (p.itemCode == 2) {
          priceW = Number(p.weight)
        } else if (p.itemCode == 3) {
          fastW = Number(p.weight)
        } 
      })
      let weight = countW + priceW + fastW

      for (let i = 0; i < this.pointMst.length; i++) {
        const p = this.pointMst[i]

        // 件数
        if (p.itemCode == 1) {
          let res = Math.ceil(p.value / 4.3)
          p.memo = `1週間で約${res}件のペース`
          count = p.value
					this.baseCount = Number(p.value)
          p.points = this.getBasePoint(countW, p.value, weight)
          total += p.points
        // 金額
        } else if (p.itemCode == 2) {
					this.basePrice = Number(p.value)
          if (count) {
            let res = Math.ceil(p.value / 10000 / count)
            p.memo = `1件 約${res}万円の見積り`
            price = res
            p.points = this.getBasePoint(priceW, p.value, weight)
            total += p.points
          } else {
            p.memo = ''
            p.points = 0
          }
        // 速さ
        } else if (p.itemCode == 3) {
					this.baseFast = Number(p.value)
          if (count && price) {
            p.memo = this.getFastMemo(p.value)
            p.points = this.getBasePoint(fastW, p.value, weight)
            total += p.points
            // 100点を超えている場合
            if (total > 100) {
              // 速さの配分点から超えている分を引く
              p.points -= total - 100
            }
          } else {
            p.memo = ''
            p.points = 0
          }
          // fast = p.value
        // 負担は画面で変更不可にした
        // } else if (p.itemCode == 4) {
        //   if (count && price && fast && p.value) {
        //     // 加算ポイント
        //     let res1 = Math.floor(count / p.value)
        //     // 速さからポイント分を引く
        //     res1 = fast - res1
            
        //     let res = Math.round((price / 10) / (res1 / count) * 10) / 10
        //     p.memo = `${count}件達成した場合、${price}万円の見積りを${res - 1}日で作る速さ`
        //   } else {
        //     p.memo = ''
        //   }
        }
      }

			// 目標値の備考を生成
			for (let i = 0; i < this.goalPointMst.length; i++) {
				const g = this.goalPointMst[i]
				g.countMemo = ''
				g.priceMemo = ''
				g.fastMemo = ''

				if (g.count) {
					g.count = Number(g.count)
					g.countMemo = `1週間で約${Math.ceil(g.count / 4.3)}件のペース`
					
					if (g.price) {
						g.price = Number(g.price)
						g.priceMemo = `1件 約${Math.ceil(g.price / 10000 / g.count)}万円の見積り`
					}
				}
				if (g.fast) {
					g.fast = Number(g.fast)
				}
				g.fastMemo = this.getFastMemo(g.fast)
			}
      this.getPoint(false)
    },

		/**
		 * 速さの備考を生成
		 * @param fast 速さの値
		 */
		getFastMemo(fast) {
			if (!fast) {
				return ''
			} else {
				// (100万 ÷ 10) ÷ (速さの値 ÷ 20件)の少数第一位まで求める
				const v1 = BigNumber(fast).div(20)
				let res = Math.round(BigNumber(10).div(v1).times(10))
				res = BigNumber(res).div(10)
				// 1日の速さは、金額÷（日数+1）で出しているので、結果から-1する
				res = BigNumber(res).minus(1)
				return `100万円の見積りを${res}日/件 × 20件`
			}
		},

		/**
		 * 速さの達成までの具体例
		 * @param fast 残りの速さの値
		 */
		getFastAchieveMemo(fast) {
			let res = 0
			res = Math.ceil(BigNumber(fast).div(5))
			return `100万円の見積りを1日/件 × ${res}件で達成`
		},

    /**
     * 点数配分を取得
     */
    getBasePoint(tar, val, total) {
      if (val) {
        return Math.round(100 / (total) * tar)
      } else {
        return 0
      }
    },

		/**
		 * 今までの平均日数を取得
		 * @param res 保存されていた平均日数データ
		 */
		async getAveDays(res) {
			if (!res.length) {
				this.chartLoading = false
				return
			}

			let res2 = []
			// 保存された平均日数の対象日MAXを取得
			const daySt = res[res.length - 1]
			// MAX値が今月でない場合
			if (daySt.daySt !== moment().format('YYYY-MM')) {
				// start以降から先月までのを取得
				let result = await backend.searchData('estimateApproved/getAveDays', daySt)
				if (result.data.data) {
					res2 = result.data.data
					res2.forEach((r) => {
						// 少数第二位までにする
						const val = Math.floor(BigNumber(Number(r.aveDays)).times(100))
						r.aveDays = BigNumber(val).div(100)
					})
				}
			}

			const ave = res.concat(res2)
			// チャート生成
			const labels = []
			const data = []

			for (let i = 0; i < ave.length; i++) {
				const a = ave[i]
				labels.push(a.daySt)
				data.push(Number(a.aveDays))
			}

			// 開いている画面が今月の場合
			if (this.target.sMonth == moment().format('YYYY-MM-01') && this.ave) {
				// 今月分を追加
				labels.push(moment().format('YYYY-MM'))
				data.push(Number(this.ave))
			}
			this.chartDataDays = {
				labels,
				datasets: [{
					label: '平均実績日数（最大で2年分表示）',
					data,
					fill: false,
					borderColor: 'rgb(75, 192, 192)'
				}]
			}
			this.chartLoading = false
		},

		/**
		 * チャートモーダルopen
		 */
		openModal() {
			this.openAveDaysModal = true
		},

		/**
		 * チャートモーダルclose
		 */
		closeModal() {
			this.openAveDaysModal = false
		},

    /**
     * 基準値を保存
     */
    async savePoint() {
      await backend.postData('estimateApproved/savePoint', { list: this.pointMst, list2: this.goalPointMst, user: this.$store.getters.user.id })
      await dialogs.showSuccessDialog('保存完了', '基準値と目標値を保存しました。')
    }
	}
}
</script>
<style scoped>
.main-height1 {
	max-height: 66vh;
}

.main-height2 {
	max-height: 66vh;
}

.main {
	min-width: 700px;
}

.col-no {
	width: 70px;  
}

.min-300 {
	min-width: 300px;
}

.min-70 {
	min-width: 70px;
}

.b-top {
	border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
	background: #ebf3fb;
}

.hover-color:hover {
	background: rgba(183, 238, 236, 0.67);
	cursor: pointer;
}

.mt-27px {
	margin-top: 27px;
}

.mb-5px {
	margin-bottom: 5px;
}

.bg-count {
  background: rgb(230 244 234);
}

.bg-price {
  background: rgb(255, 247, 219);
}

.bg-fast {
  background: rgb(249, 240, 255);
}

@media screen and (max-width: 1200px) {
	.main {
		overflow: auto;
	}
}
</style>