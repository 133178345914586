import axiosBase from 'axios'
import * as backend from '@libs/backend'
import { PIGEON_CONFIG } from '@/config'

const CHATWORK_API_PROXY_ENDPOINT = `${PIGEON_CONFIG.apiProxyServiceEndpoint}/chatwork-api/v2`

export async function sendChatWorkMssage(roomId, message, token) {
  const endpoint = `${CHATWORK_API_PROXY_ENDPOINT}/rooms/${roomId}/messages`

  const axios = axiosBase.create()
  const res = await axios({
    method: 'post',
    url: endpoint,
    headers: {'X-ChatWorkToken': token},
    data: `body=${encodeURIComponent(message)}`
  })
  return res
}

/**
 * ファイルアップロード(blob)
 * @param {*} roomId ルームID
 * @param {*} token APIトークン
 * @param {*} message メッセージ内容
 * @param {*} blob 対象ファイル
 * @param {*} fileName ファイル名
 */
export async function uploadFile(roomId, token, message, blob, fileName) {
  const form = new FormData()
  form.append('roomId', roomId)
  form.append('token', token)
  form.append('message', message)
  form.append('file', blob)
  form.append('fileName', fileName)
  await backend.postForm('apiProxy/chatworkApi/files', form)
}

/**
 * ファイルアップロード(base64)
 * @param {*} roomId ルームID
 * @param {*} token APIトークン
 * @param {*} message メッセージ内容
 * @param {*} base64 対象ファイル
 * @param {*} fileName ファイル名
 */
export async function uploadFileBase64(roomId, token, message, base64, fileName) {
  await uploadFile(roomId, token, message, toBlob(base64), fileName)
}

/**
 * base64 ⇒ Blob変換
 * @param {*} base64 対象データ
 * @returns 
 */
function toBlob(base64) {
  var bin = atob(base64.replace(/^.*,/, ''));
  var buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
  }
  const contentType = base64.toString().slice(base64.indexOf(':') + 1, base64.indexOf(';'))

  // Blobを作成
  try{
      var blob = new Blob([buffer.buffer], {
          type: contentType
      });
  }catch (e){
      return false;
  }
  return blob;
}