<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">

        <div class="overflow-hidden rounded main-height">
            <div class="overflow-auto">
                <div v-if="selecter" class="font-bold text-sm">報告書に反映したい行をクリック（タップ）してください。</div>
                <div class="flex mt-1">
                    <div class="">
                        <input
                            type="search"
                            name="filterName" 
                            placeholder="名称 検索"
                            class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                            v-model="filterName"
                        />
                    </div>
                    <div class="ml-auto mt-auto">
                        <PlusCircleIcon v-tooltip="'追加'" class="ml-auto mr-3 text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer w-8 h-8" @click="edit(null)" />
                    </div>
                </div>
                <table class="overflow-auto main table-auto mb-3">
                    <thead class="main sticky top-0 bg-white">
                        <tr>
                            <th v-for="(f, index) in columns" :key="index" scope="col" class="p-2 text-center text-xs font-medium text-gray-500 tracking-wider">
                            </th>
                        </tr>
                        <tr v-if="list && list.length" class="border border-gray-200">
                            <th v-for="(c, index) in columns" :key="index" scope="col" class="p-2 text-xs font-medium text-gray-500 tracking-wider bg-colu text-left">
                                {{c.title}}
                            </th>
                        </tr>
                    </thead>

                    <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider cursor-pointer hover-color">

                        <!-- 名称 -->
                        <td class="p-2 whitespace-nowrap text-sm text-gray-700" @click="selectColor(row)">
                            {{ row.name }}
                        </td>

                        <!-- カラー１ -->
                        <td class="p-2 whitespace-nowrap text-sm text-gray-700" @click="selectColor(row)">
                            <input type="color" class="" v-model="row.color1" disabled>
                        </td>

                        <!-- カラー２ -->
                        <td class="p-2 whitespace-nowrap text-sm text-gray-700" @click="selectColor(row)">
                            <input type="color" class="" v-model="row.color2" disabled>
                        </td>

                        <!-- カラー３ -->
                        <td class="p-2 whitespace-nowrap text-sm text-gray-700" @click="selectColor(row)">
                            <input type="color" class="" v-model="row.color3" disabled>
                        </td>
                        
                        <td class="px-2 text-center text-sm font-medium border-gray">
                            <div class="flex justify-center">
                                <PrimaryButton text="編集" size="normal" @click="edit(row)" />
                                <PrimaryButton text="削除" size="bg-red-600 text-white font-bold rounded-md px-3 py-2 ml-1" @click="remove(row)" class=""/>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <template v-if="(!list || !list.length)">
                <div class="flex justify-center items-center text-gray-500 font-bold nothing">
                    <p class="text-gray-400 font-bold">対象データがありません</p>
                </div>
            </template>
    
            <!-- 編集モーダル -->
            <PhotoReportColorEditModal
                v-model="editModalShow"
                :editModalShow="editModalShow"
                :target="editData"
                :originalList="originalList"
                @closePhotoReportColorEditModal="closePhotoReportColorEditModal"
            />
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import PhotoReportColorEditModal from '../components/PhotoReportColorEditModal.vue'
import { PlusCircleIcon } from '@vue-hero-icons/solid'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'

export default {
    components: {
        Modal,
        PrimaryButton,
        PhotoReportColorEditModal,
        PlusCircleIcon
    },

    props: ['photoReportColorModalShow', 'isChoice', 'selecter'],

    data() {
        return {
            // カラム
            columns: [
                {title: '名称'},
                {title: 'カラー①'},
                {title: 'カラー②'},
                {title: 'カラー③'},
                {title: ''},
            ],
            // 登録するデータ
            saveList: [],
            // リスト
            originalList: [],
            list: [],
            // 名称絞込み
            filterName: [],
            // 編集モーダル
            editModalShow: false,
            // 編集対象データ
            editData: {},
        }
    },

    computed: {
    },

    watch: {
        filterName() {
            this.filtering()
        },

        photoReportColorModalShow() {
            if (this.photoReportColorModalShow) {
                this.getList()
            }
        }
    },

    methods: {
        /**
         * リスト取得
         */
        async getList() {
            const result = await backend.searchData('photoReportColor/getList')
            this.originalList = result.data.data
            this.filtering()
        },

        /**
         * 検索
         */
        filtering() {
            this.list = []
            let res = []

            if (this.filterName != '') {
                this.filterName = this.filterName.trim()
                for (let i = 0; i < this.originalList.length; i++) {
                    const o = this.originalList[i]
                    if (utils.hankaku(o.name).toLowerCase().indexOf(utils.hankaku(this.filterName).toLowerCase()) != -1) {
                        res.push(o)
                    }
                }
            } else {
                res = utils.clone(this.originalList)
            }
            this.list = res
        },

        /**
         * 編集ボタンイベント
         * @param row 編集対象データ
         */
        edit(row) {
            this.editData = null
            // 編集
            if (row) {
                this.editData = row
            }

            this.editModalShow = true
        },

        /**
         * 削除ボタンイベント
         * @param row 対象データ
         */
        async remove(row) {
            let resDlog = await dialogs.showConfirmDialog('削除', '削除してよろしいでしょうか？')
            if (resDlog != 'YES') {
                return
            }

            row.updateUserId = this.$store.getters.user.id
            const result = await backend.postData('photoReportColor/delete', row)
            if (result) {
                logManager.recording(this, logManager.Loglevel.INFO, '写真報告書カラー', '削除', `写真報告書カラー（${row.name}）を削除しました。`)
                this.getList()
            } else {
                await dialogs.showErrorDialog('エラー', '削除中にエラーが発生しました。')
            }
        },

        /**
         * 編集モーダルclode
         */
        closePhotoReportColorEditModal() {
            this.editModalShow = false
            this.getList()
        },

        /**
         * カラー選択
         */
        selectColor(row) {
            if (!this.isChoice) return
            this.closed(row)
        },

        closed(row) {
            // 登録するデータ
            this.saveList = []
            // リスト
            this.originalList = []
            this.list = []
            // 発注先絞込み
            this.filterName = []
            this.$emit('closePhotoReportColorModal', row)
        }
    }
}
</script>
<style scoped>
.main-height {
    min-height: 30vh;
}

.bg-colu {
    background: #ebf8fb;
}

.hover-color:hover {
    background: rgba(163, 231, 228, 0.67) !important;
}

.nothing {
    /* position: absolute; */
    /* top: 45%;
    left: 40%; */
    width: fit-content;
    margin: auto;
}

</style>