// 得意先メールマスタ
const CLIENT_MAIL_MASTER = 'client_mail_master'
// 得意先メール送信履歴
const CLIENT_MAIL_HISTORY = 'client_mail_history'

/**
 * メールアドレス全件取得
 * @param {*} context 
 * @returns 
 */
export async function getMail(context) {
  const searchCondition = {
    searchCondition: {
      type: CLIENT_MAIL_MASTER,
      status: 'open'
    },
    sort: {
      client: 1
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    // console.log(searchCondition)
    const res = responseData || null
    // console.log(res)
    return res
  } catch (error) {
    console.log('getMail error !!!')
    console.log(error)
    return null
  }
}

/**
 * 得意先メール登録
 * @param {*} context 
 * @param {*} data 対象データ
 * @returns 
 */
export async function registerClient(context, data) {
  try {
    data.type = CLIENT_MAIL_MASTER
    await context.$pigeon.registerTask(CLIENT_MAIL_MASTER, data, {})
    return true
  } catch (error) {
    console.log('registerClient error !!!')
    console.log(error)
    return false
  }
}

/**
 * 得意先メール更新
 * @param {*} context 
 * @param {*} data データ
 * @returns 
 */
export async function updateClient(context, data) {
  try {
    data.type = CLIENT_MAIL_MASTER
    await context.$pigeon.updateTask(data._id, data, {})
    return true
  } catch (error) {
    console.log('updateClient error !!!')
    console.log(error)
    return false
  }
}

/**
 * 得意先メール削除
 * @param {*} context 
 * @param {*} id _id
 * @returns 
 */
export async function deleteClient(context, id) {
  try {
    await context.$pigeon.deleteTask(id)
    return true
  } catch (error) {
    console.log('deleteClient error !!!')
    console.log(error)
    return false
  }
}

/**
 * 問い合わせ番号に紐づく送信履歴データ取得
 * @param {*} context 
 * @param {*} requestNo お問合せNo
 * @returns 
 */
export async function getHistoryByRequestNo(context, requestNo) {
  // console.log(requestNo)
  if (!requestNo || requestNo == '') {
    return null
  }
  const searchCondition = {
    searchCondition: {
      type: CLIENT_MAIL_HISTORY,
      requestNo
    },
    sort: {
      createDate: 1
    }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    // console.log(searchCondition)
    const res = responseData || null
    // console.log(res)
    return res
  } catch (error) {
    console.log('getHistoryByRequestNo error !!!')
    console.log(error)
    return null
  }
}

/**
 * 得意先メール送信履歴登録
 * @param {*} context 
 * @param {*} data 対象データ
 * @returns 
 */
export async function historyRegister(context, data) {
  try {
    data.type = CLIENT_MAIL_HISTORY
    await context.$pigeon.registerTask(CLIENT_MAIL_HISTORY, data, {})
    return true
  } catch (error) {
    console.log('historyRegister error !!!')
    console.log(error)
    return false
  }
}
