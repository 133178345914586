<template>
  <div>
    <div class="flex items-start main">
      <table class="mb-5">
        <tr class="sticky top-0 z-20">
          <th v-for="(c, i) in column1" :key="i" :colspan="c.colspan">
            <div v-if="c.name!=null" :class="c.class" class="p-1 ml-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr class="sticky top-8 z-20">
          <th v-for="(c, i) in column2" :key="i">
            <div v-if="c.name" :class="c.class" class="p-1 ml-1 mb-1">
              {{ c.name }}
            </div>
          </th>
        </tr>
        <tr v-for="(c, i) in column3" :key="i" class="hover-color text-right">
          <th class="sticky left-0 z-10 bg-white">
            <div class="ml-3 mr-2 bg-white whitespace-pre">
              {{ c.min }} ～ {{ c.max }} 戸
            </div>
          </th>
          <td>
            <div v-if="single1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="single1[i].price"
                @change="changeData(single1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="family1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-teal-50"
                v-model="family1[i].price"
                @change="changeData(family1[i])"
              />
            </div>
          </td>
          <!-- <td>
            <div v-if="singleM1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="singleM1[i].price"
                @change="changeData(singleM1[i])"
              />
            </div>
          </td>
          <td>
            <div v-if="familyM1[i]">
              <NumericTextInput
                name="price"
                class="text-right rounded border-gray-300 w-28 ml-5 bg-blue-50"
                v-model="familyM1[i].price"
                @change="changeData(familyM1[i])"
              />
            </div>
          </td> -->
        </tr>
      </table>
    </div>
    <div class="text-xs">※月1回は、なくなりました。（2024年9月）</div>
  </div>
</template>
<script>

import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

/**
 * フロントサポートプラス
 */
const CATEGORY = '7'

export default {
  components: {
    NumericTextInput,
  },

  props: {
    code: {
      type: String
    }
  },

  data() {
    return {
      // 清掃回数カラム
      column1: [
        { name: '', colspan: 1, class: 'bg-white'},
        { name: '週 1 回', colspan: 2, class: 'ml-5 bg-teal-50'},
        // { name: '月 1 回', colspan: 2, class: 'ml-5 bg-blue-50'},
      ],
      // タイプカラム
      column2: [
        { name: '' },
        { name: 'シングル', class: 'ml-5 bg-teal-50' },
        { name: 'ファミリー', class: 'ml-5 bg-teal-50' },
        // { name: 'シングル', class: 'ml-5 bg-blue-50' },
        // { name: 'ファミリー', class: 'ml-5 bg-blue-50' },
      ],
      // 戸数カラム
      column3: [
        { min: '1', max: '20' },
        { min: '21', max: '30' },
        { min: '31', max: '40' },
        { min: '41', max: '50' }
      ],
      // 週1シングル
      single1: [],
      // 週1ファミリ
      family1: [],
      // 月1シングル
      // singleM1: [],
      // 月1ファミリ
      // familyM1: []
    }
  },

  created() {
    this.dataByCategory()
  },

  computed: {
  },

  methods: {
    /**
     * 単価データを取得
     */
    async dataByCategory() {

      // カテゴリーに関するデータ取得
      const list = utils.clone(this.$store.getters.estimateItemPriceList)
      const dbc = list.filter((l) => {
        return l.parentCode == CATEGORY
      })

      let s1 = []
      let f1 = []
      // let sM1 = []
      // let fM1 = []

      for (let i = 0; i < dbc.length; i++) {
        const d = dbc[i]
        const code = d.priceCode.split('-')
        switch (code[0]) {
          // シングル
          case '1':
            // 週1
            if (code[1] == '1') {
              s1.push(d)
            // 月1
            // } else if (code[1] == 'm1') {
            //   sM1.push(d)
            }
            break;

          // ファミリ
          case '2':
            // 週1
            if (code[1] == '1') {
              f1.push(d)
            // 月1
            // } else if (code[1] == 'm1') {
            //   fM1.push(d)
            }
            break;
        }
      }

      this.single1 = this.setSort(s1)
      this.family1 = this.setSort(f1)
      // this.singleM1 = this.setSort(sM1)
      // this.familyM1 = this.setSort(fM1)

    },

    /**
     * 戸数によるソート
     * @param target 対象データ
     */
    setSort(target) {
      const sortArr = ['1', '21', '31', '41']
      let result = []
      for (let l = 0; l < sortArr.length; l++) {
        const sort = sortArr[l]
        for (let m = 0; m < target.length; m++) {
          const er = target[m]
          if (er.minHouseholds == sort) {
            result.push(er)
          }
        }
      }
      return result
    },

    /**
     * 変更イベント
     */
    changeData(target) {
      // ログに出力する項目名を生成
      let code = target.priceCode.split('-')
      let name1 = ''
      let name2 = ''
      let name3 = ''

      name1 = `${target.minHouseholds}~${target.maxHouseholds}戸`
      
      name2 = `${code[1]}回`
      if (name2.indexOf('m') != -1) {
        name2 = name2.replace('m', '・月')
      } else {
        name2 = `・週${name2}`
      }
      
      name3 = '・シングル'
      if (code[0] == '2') {
        name3 = '・ファミリー'
      }

      target.itemName = name1 + name2 + name3
      this.$emit('changePrice', target)
    }
  }
}
</script>
<style scoped>
/* .main {
  min-width: 2700px;
} */

.hover-color:hover {
  background: rgba(163, 231, 228, 0.67) !important;
  color: rgb(16, 16, 208);
}

.border-orange {
  border: 1px solid rgb(241, 171, 135);
}

.border-orange-b {
  border-bottom: 1px solid rgb(241, 171, 135);
}

.orange {
  background: rgba(247, 201, 173, 0.67);
}

.hover-color2:hover {
  background: rgba(247, 201, 173, 0.67) !important;
}

.left-100p {
  left: 100px;
}

.text-orange {
  color: rgb(163, 108, 4);
}
</style>
