<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div v-if="loading" class="w-full h-full flex justify-center items-center">
        <p class="text-gray-400 font-bold animate-pulse">Loading...</p>
      </div>
      <div class="main-height overflow-auto">
        <table class="overflow-auto main table-auto mb-3">
          <thead class="main sticky top-0 bg-white">
            <tr>
              <th v-for="(f, index) in columns" :key="index" scope="col" class="py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                <div v-if="f.title == '得意先コード'" class="">
                  <input
                    type="search"
                    name="clientCode" 
                    placeholder="コード 検索"
                    class="border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-36" 
                    v-model="filter.clientCode"
                  />
                </div>
                <div v-if="f.title == '得意先名'" class="">
                  <input
                    type="search"
                    name="client1" 
                    placeholder="得意先 検索"
                    class="border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                    v-model="filter.client"
                  />
                </div>
                <div v-if="f.title == '支店名'" class="">
                  <input
                    type="search"
                    name="client2" 
                    placeholder="支店 検索"
                    class="border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                    v-model="filter.client2"
                  />
                </div>
                <div v-if="f.title == '手数料率(％)'" class="mx-2">
                  <select
                    name="rate"
                    v-model="filter.rate"
                    class="focus:bg-white border-gray-300 text-xs rounded"
                  >
                    <option
                      v-for="r in rateList" :key="r.name + 'r'"
                      :value="r.name"
                      class="bg-white">
                      {{ r.name }}
                    </option>
                  </select>
                </div>
                <div v-if="f.title == '備考'" class="">
                  <input
                    type="search"
                    name="memo" 
                    placeholder="備考 検索"
                    class="border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900 w-32"
                    v-model="filter.memo"
                  />
                </div>
                <div v-if="f.title == '住所'" class="">
                  <input
                    type="search"
                    name="address" 
                    placeholder="住所 検索"
                    class="border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
                    v-model="filter.address"
                  />
                </div>
                <div v-else></div>
              </th>
            </tr>
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="pr-1 py-3 text-xs font-medium text-gray-500 tracking-wider bg-colu text-left" :class="index==0?'pl-3':'pl-1' + c.class">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider hover-color">

            <!-- code -->
            <td class="pl-3 pr-1 py-0.5 whitespace-nowrap text-sm font-medium text-gray-500">
              {{ row.clientCode }}
            </td>

            <!-- 得意先 -->
            <td class="px-1 py-0.5 whitespace-nowrap text-sm text-gray-700 font-bold">
              {{ row.clientName }}
            </td>

            <!-- 支店名 -->
            <td class="px-1 py-0.5 whitespace-nowrap text-xs text-gray-600">
              {{ row.clientName2 }}
            </td>

            <!-- 手数料率 -->
            <td class="px-1 py-0.5 whitespace-nowrap text-sm text-blue-700 font-bold text-center">
              {{ row.rate }}
            </td>

            <!-- 備考 -->
            <td class="px-1 py-0.5 whitespace-pre-line text-sm text-gray-700">
              {{ row.memo }}
            </td>

            <!-- 住所 -->
            <td class="px-1 py-2 text-xs text-gray-700">
              <div>{{ row.address1 }}</div>
              <div>{{ row.address2 }}</div>
            </td>

            <td class="px-1 py-0.5 text-center text-sm font-medium border-gray">
              <div class="flex justify-center">
                <PrimaryButton text="編集" size="normal" @click="edit(row)" />
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div v-if="!loading && (!list || !list.length)" class="flex justify-center items-center text-gray-500 font-bold nothing">
        <p class="text-gray-400 font-bold">対象データがありません</p>
      </div>

      <!-- 手数料マスタ編集 -->
      <EstimateCommissionEditModal
        v-model="editModalShow"
        :open="editModalShow"
        :target="editData"
        @closeModal="closeModal"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import EstimateCommissionEditModal from './EstimateCommissionEditModal.vue'
import * as utils from '@libs/utils'
import * as backend from '@libs/backend'

const RATE_LIST = [
  { name: '' },
  { name: '手数料なし' },
  { name: '手数料あり' },
]

export default {
  components: {
    Modal,
    PrimaryButton,
    EstimateCommissionEditModal
  },

  props: ['open'],

  data() {
    return {
      // ローディング
      loading: false,
      // カラム
      columns: [
        {title: '得意先コード'},
        {title: '得意先名'},
        {title: '支店名'},
        {title: '手数料率(％)', class: ' text-center'},
        {title: '備考'},
        {title: '住所'},
        {title: ''},
      ],
      // リスト
      list: [],
      // 絞込み
      filter: {
        clientCode: '',
        client: '',
        client2: '',
        rate: '手数料あり',
        memo: '',
        address: ''
      },
      // 手数料率絞り込み候補
      rateList: utils.clone(RATE_LIST),
      // 編集モーダル
      editModalShow: false,
      // 編集対象データ
      editData: null
    }
  },

  computed: {
  },

  watch: {
    filter: {
      handler: function() {
        this.getData()
      },
      deep: true
    },

    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 初期にリセット
     */
    closed() {
      this.editModalShow = false
      this.editData = null
    },

    /**
     * リスト取得
     */
    async getData() {
      this.loading = true
      const res = await backend.searchData('estimateCommissionRate/getClientList', this.filter)
      if (res.data.data && res.data.data.length) {
        this.list = res.data.data
      } else {
        this.list = []
      }
      if (res.data.data2 && res.data.data2.length) {
        this.rateList = utils.clone(RATE_LIST).concat(res.data.data2)
      }
      this.loading = false
    },

    /**
     * 編集ボタンイベント
     * @param row 編集対象データ
     */
    edit(row) {
      this.editData = null
      // 編集
      if (row) {
        this.editData = row
      }

      this.editModalShow = true
    },

    /**
     * 編集モーダルclode
     */
    closeModal() {
      this.editModalShow = false
      this.getData()
    },
  }
}
</script>
<style scoped>
.main-height {
  height: 85vh;
}

.h-95vh {
  height: 95vh;
}

.h-93vh {
  height: 93vh;
}

.main {
  min-width: 700px;
}

.b-top {
  border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
  background: #fbeceb;
}

.nothing {
  position: absolute;
  top: 45%;
  left: 40%;
}

.hover-color:hover {
  background: #fbeceb !important;
}
</style>