<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div v-if="waiting" class="flex justify-center items-center loading-dialog2">
      <p class="text-gray-400 font-bold animate-pulse" v-if="waiting">{{ waiting }}</p>
    </div>
    <div class="main m-1">
      <!-- メール送信画面 -->
      <div v-if="isMail">
        <!-- 得意先名 -->
        <div class="text-lg font-bold text-blue-600 mb-5">
          {{ clientName }}
        </div>
        <!-- メールアドレス -->
        <div class="mb-3">
          <div class="flex justify-between">
            <div class="text-xs font-bold mt-auto">送信先
              <span class="-top-0.5 ml-1 text-red-500"> * </span>
            </div>
            <PrimaryButton
              text="送信先追加"
              @click="mailAdd()"
              class="h-7 bg-blue-50 text-blue-900 border-blue-900 m-1"
            >
              <Icon
                iconName="PlusCircle" 
                slot="before"
                class="w-4 h-4 mr-0.5"
              />
            </PrimaryButton>
          </div>
          <div v-for="(m, i) in mail" :key="i">
            <div class="flex">
              <WmsSuggestInput
                :name="'mail' + i" 
                :required="true"
                :selectionItems="mailList"
                :displayField="['client', 'client2', 'pic', 'mail']"
                v-model="mail[i]"
                class="w-full"
                @selected="mailSelected($event, i)"
              >
              <!-- 検索アイコン -->
              <template #addon-content>
                <Icon
                  iconName="Search" 
                  :clickable="true" 
                  class="w-5 h-5"
                  @click="openClientModal(i)"
                />
              </template>
              </WmsSuggestInput>
              <div class="m-auto ml-2">
                <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" @click="mailDelete(i)"/>
              </div>
            </div>
          </div>
        </div>
        <!-- CC -->
        <div class="mb-3">
          <div class="flex justify-between">
            <div class="text-xs font-bold mt-auto">CC</div>
            <PrimaryButton
              text="CC追加"
              @click="ccAdd()"
              class="h-7 bg-blue-50 text-blue-900 border-blue-900 m-1"
            >
              <Icon
                iconName="PlusCircle" 
                slot="before"
                class="w-4 h-4 mr-0.5"
              />
            </PrimaryButton>
          </div>
          <div v-for="(m, j) in cc" :key="j">
            <div class="flex">
              <WmsSuggestInput
                :name="'cc' + j" 
                :required="true"
                :selectionItems="ccList"
                :displayField="['name', 'mail']"
                v-model="cc[j]"
                class="w-full"
              >
              </WmsSuggestInput>
              <div class="m-auto ml-2">
                <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" @click="ccDelete(j)"/>
              </div>
            </div>
          </div>
        </div>
        <!-- BCC -->
        <div class="mb-3">
          <div class="flex justify-between">
            <div class="text-xs font-bold mt-auto">BCC（大野さんのメールアドレスです）</div>
          </div>
          <div class="">
            <WmsTextInput
              name="bcc"
              v-model="bcc"
            />
          </div>
        </div>
        <!-- 件名 -->
        <div class="mb-3">
          <WmsTextInput
            name="subject" 
            caption="件名" 
            :required="true"
            :error="subject==''"
            v-model="subject"
          />
        </div>
        <!-- 本文 -->
        <div class="mb-3">
          <WmsTextInput 
            name="text" 
            caption="本文"
            :multiline="true"
            :rows="15"
            :required="true"
            :error="text==''"
            v-model="text"
          />
        </div>
            
        <!-- 履歴 -->
        <div v-if="mailHistory && mailHistory.length" class="w-full mb-5">
          <label class="block text-xs font-bold text-green-700 mb-1">
            送信履歴
          </label>

          <div v-if="mailHistory && mailHistory.length" class="border border-green-600 rounded overflow-auto">
            <table class="w-full">
              <thead>
                <tr>
                  <th v-for="(c, ci) in columns" :key="ci" class="sticky font-normal text-xs py-3 text-green-700 mb-auto text-center">
                    {{ c }}
                  </th>
                </tr>
              </thead>
              <tr v-for="(h, ri) in mailHistory" :key="ri" class="text-gray-500 text-sm">
                <!-- 送信結果 -->
                <td class="text-center">
                  {{ h.sendStatus }}
                </td>
                <!-- 送信者 -->
                <td class="text-center">
                  {{ h.staff }}
                </td>
                <!-- 送信日時 -->
                <td class="text-center">
                  {{ h.date }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <PrimaryButton
          text="送信"
          @click="sendMail()"
          class="w-11/12 block m-auto"
        >
        </PrimaryButton>
      </div>

      <!-- FAX用紙出力画面 -->
      <div v-else>
        <PrimaryButton
          text="PDF出力"
          @click="pdfBtn()"
          :disabled="waiting?true:false"
          class="block ml-auto"
        >
          <LoadingIcon v-if="waiting" slot="before" class="h-4 w-4" />
          <Icon v-else iconName="Printer" slot="before" class="h-4 w-4" />
        </PrimaryButton>
        <div id="output" style="height: 100%;">
          <div style="font-size: xx-large; text-align: center;">
            FAX
          </div>

          <div style="display: flex; margin: 45px 2px; border: solid 1px gray; border-radius: 1rem;">
            <!-- 送信先 -->
            <div style="width: 50%;">
              <div style="font-weight: bold; border-bottom: 2px solid gray; width: 100%; text-align: center; padding: 5px 0px;">TO</div>
              <div style="padding-left: 15px; height: 193px; border-right: solid 1px gray;">
                <!-- 得意先名 -->
                <div style="border-bottom: 1px solid gray; width: 100%; font-weight: bold; min-height: 36px;">
                  <input
                    v-if="!waiting"
                    type="text"
                    class="border-0 w-full font-bold pb-0.5 pt-6"
                    v-model="zenkakuName1"
                  />
                  <div v-else style="width: 100%; padding: 1.5rem 0.75rem 0.125rem 0.75rem;">
                    {{ zenkakuName1 }}
                  </div>
                </div>

                <!-- 支店名 -->
                <div style="border-bottom: 1px solid gray; width: 100%; min-height: 36px;">
                  <input
                    v-if="!waiting"
                    type="text"
                    class="border-0 w-full pb-0.5"
                    v-model="senkakuName2"
                  />
                  <div v-else style="width: 100%; padding: 0.5rem 0.75rem 0.125rem 0.75rem;">
                    {{ senkakuName2 }}
                  </div>
                </div>

                <!-- 物件名 -->
                <div style="border-bottom: 1px solid gray; width: 100%; min-height: 36px;">
                  <input
                    v-if="!waiting"
                    type="text"
                    class="border-0 w-full pb-0.5"
                    v-model="siteName"
                  />
                  <div v-else style="width: 100%; padding: 0.5rem 0.75rem 0.125rem 0.75rem;">
                    {{ siteName }}
                  </div>
                </div>

                <!-- 担当者 -->
                <div style="border-bottom: 1px solid gray; width: 100%; min-height: 36px; margin-bottom: 30px; margin-top: auto;">
                  <WmsSuggestInput
                    v-if="!waiting"
                    name="faxPic" 
                    :selectionItems="mailList"
                    :displayField="['client', 'client2', 'pic']"
                    v-model="faxPic"
                    class="w-full fax-pic"
                    @selected="picSelected($event)"
                  >
                  <!-- 検索アイコン -->
                  <template #addon-content>
                    <Icon
                      iconName="Search" 
                      :clickable="true" 
                      class="w-5 h-5 mr-0.5"
                      @click="openClientModal(null)"
                    />
                  </template>
                  </WmsSuggestInput>
                  <!-- <input
                    v-if="!waiting"
                    type="text"
                    class="border-0 w-full pb-0.5"
                    v-model="faxPic"
                  /> -->
                  <div v-else style="width: 100%; padding: 0.5rem 0.75rem 0.125rem 0.75rem;">
                    {{ faxPic }}
                  </div>
                </div>
              </div>
              <div style="font-weight: bold; width: 100%; text-align: center; padding: 8px 0px; letter-spacing: 0.12em; border-top: 2px solid gray;">{{ today }}</div>
            </div>
            <!-- アポロ管財 -->
            <div style="width: 50%;">
              <div style="font-weight: bold; border-bottom: 2px solid gray; width: 100%; text-align: center; padding: 5px 0px;">FROM</div>
              <div style="display: flex; width: fit-content; margin: auto; font-size: 14px; height: 193px;">
                <div style="margin: auto;">
                  <img :src="logo" style="height: 35px; width: 224px; margin: auto; display: block;" />
                  <div style="display: flex; text-align: center; justify-content: center;">
                    <div style="margin-right: 13px;">{{ apolloInfo.zip }}</div>
                    <div>{{ apolloInfo.address }}</div>
                  </div>
                  <div style="display: flex; text-align: center; justify-content: center;">
                    <div style="margin-right: 13px;">TEL.{{ apolloInfo.tel }}</div>
                    <div>FAX.{{ apolloInfo.fax }}</div>
                  </div>
                  <div style="text-align: center;">業務サポート部 {{ userFullName }}</div>
                </div>
              </div>
              <div style="font-weight: bold; width: 100%; text-align: center; padding: 8px 0px; letter-spacing: 0.12em; border-top: 2px solid gray;">送信枚数  本葉を含めて   1枚</div>
            </div>
          </div>

          <!-- 建物管理部問い合わせ先 -->
          <div v-if="!waiting" class="">
            <div class="flex justify-between">
              <div class="text-xs font-bold mt-auto">建物管理部 担当者選択（この欄はPDFに表示されません、本文に適用されます。）</div>
              <PrimaryButton
                text="担当者追加"
                @click="ccAdd()"
                class="h-7 bg-blue-50 text-blue-900 border-blue-900 m-1"
              >
                <Icon
                  iconName="PlusCircle" 
                  slot="before"
                  class="w-4 h-4 mr-0.5"
                />
              </PrimaryButton>
            </div>
            <div v-for="(m, j) in cc" :key="j">
              <div class="flex">
                <WmsSuggestInput
                  :name="'cc' + j" 
                  :required="true"
                  :selectionItems="ccList"
                  :displayField="'name'"
                  v-model="cc[j]"
                  class="w-full"
                >
                </WmsSuggestInput>
                <div class="m-auto ml-2">
                  <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" @click="ccDelete(j)"/>
                </div>
              </div>
            </div>
          </div>

          <!-- 件名 -->
          <div style="text-align: center; margin: 70px 3px 15px 3px; font-size: large; border-bottom: solid 1px gray;">
            <input
              v-if="!waiting"
              type="text"
              class="border-0 w-full text-center"
              style="font-size: large;"
              v-model="subject"
            />
            <div v-else>
              {{ subject }}
            </div>
          </div>

          <!-- 本文 -->
          <div v-if="!waiting">
            <textarea 
              name="text" 
              class="border-0 w-98per rounded p-5 resize-none"
              style="height: 500px; line-height: 40px;"
              v-model="text"
            >
            </textarea>
          </div>
          <div v-else style="height: 500px; width: 95%; padding: 1.25rem;  white-space: pre-line; line-height: 40px;" class="w-full">
            {{ text }}
          </div>
        </div>
      </div>
    </div>

    <!-- メアドマスタモーダル -->
    <ClientMailModal
      v-model="clientModalShow"
      :clientModalShow="clientModalShow"
      :selectable="true"
      :index="index"
      @closeClientModal="closeClientModal"
    >
    </ClientMailModal>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import Icon from '@components/Icon.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import ClientMailModal from '../../Master/components/ClientMailModal.vue'
import LoadingIcon from '@assets/loading-circle.svg'
import * as clientMailManager from '@managers/clientMailManager'
import * as moment from 'moment'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as backend from '@libs/backend'
import { APOLLO_INFO } from '@libs/constants'

export default {
  components: {
    Modal,
    Icon,
    PrimaryButton,
    WmsTextInput,
    TrashIcon,
    WmsSuggestInput,
    ClientMailModal,
    LoadingIcon
  },

	props: {
		open: {
			type: Boolean,
			default: false
		},
    // メール選択の場合
		isMail: {
			type: Boolean,
			default: false
		},
    // FAX番号
    clientFax: {
      type: String,
      default: null
    },
    // 得意先名（親画面で入力されたもの）
		clientName: {
			type: String,
			default: null
		},
    // 得意先名と支店名（コードから取得したもの）
		client: {
			type: Object
		},
    // メインデータ
    requestPost: {
      type: Object
    }
	},

  data() {
    return {
      // ローディング
      waiting: '',
      // メールアドレス
      mail: [],
      // CC
      cc: [''],
      // BCC
      bcc: '',
      // メールタイトル
      subject: null,
      // 本文（メール、FAX）
      text: null,
      // 未来か否か
      isFuture: false,
      // 区分
      type: '入社',
      // 対象日
      date: ' 月 日',
      // メールマスタ画面
      clientModalShow: false,
      // メールリスト
      mailList: [],
      // CCリスト
      ccList: [
        { name: '', mail: '' }
      ],
      // メール履歴
      mailHistory: [],
      // メール履歴カラム
      columns: ['結果', '送信者', '日時'],
      // 得意先担当者
      pics: [],
      // 自社情報
      apolloInfo: APOLLO_INFO,
      // FAX用得意先名
      zenkakuName1: '',
      // FAX用支店名
      senkakuName2: '',
      // 物件名
      siteName: '',
      // FAX用得意先の担当者
      faxPic: 'ご担当者様',
      // 検索を押した行
      index: null,
      // アポロ管財ロゴ
      logo: `${utils.getSystemOrigin()}${process.env.VUE_APP_PUBLICPATH}images/apolloBlack.png`,
    }
  },

  computed: {
    /**
     * ユーザー名（苗字）
     */
    userName() {
      return utils.getSurname(this.$store.getters.user.user_name)
    },

    /**
     * ユーザー名（フル）
     */
    userFullName() {
      return utils.deleteKana(this.$store.getters.user.user_name).replace('　', ' ')
    },

    /**
     * ユーザーID
     */
    userId() {
      return this.$store.getters.user.id
    },

    /**
     * 本日日付
     */
    today() {
      return moment().format('YYYY年M月D日')
    }
  },

  watch: {
    async open() {
      if (this.open) {
        await this.getData()
      }
    },

    pics() {
      if (this.isMail) {
        const toClient = this.setToClientName()
        const pic = this.setToPic()
        const text = toClient + `\r\n${pic}\r\n\r\n`

        if (this.text && this.text.indexOf('お世話になっております。') > -1) {
          let add = this.text.substr(this.text.indexOf('お世話になっております。'))
          this.text = text + add
        }
      }
    },

    cc() {
      const text = '弊社建物管理部  ' + this.cc.join('、もしくは ') + ' '

      if (this.text && this.text.indexOf('お問い合わせにつきましては、') > -1 && this.text.indexOf('までご連絡ください。') > -1) {
        let before = this.text.substr(0, this.text.indexOf('お問い合わせにつきましては、') + 14)
        let add = this.text.substr(this.text.indexOf('までご連絡ください。'))
        this.text = before + text + add
      }
    }
  },

  methods: {
    /**
     * 得意先取得
     */
    async getData() {
      let tar = null
      // 入社か、退職を判断
      if (this.requestPost.employmentClass && this.requestPost.employmentClass[0]) {
        if (this.requestPost.employmentClass[0].indexOf('退職') > -1) {
          this.type = '退職'
          tar = Number(this.requestPost.employmentRetirementDate.replace(/-/g, ''))
          this.date = moment(new Date(this.requestPost.employmentRetirementDate)).format('YYYY年M月D日')
        } else {
          this.type = '入社'
          tar = Number(this.requestPost.employmentHireDate.replace(/-/g, ''))
          this.date = moment(new Date(this.requestPost.employmentHireDate)).format('YYYY年M月D日')
        }

        // 対象日が過去か未来かを判断
        const today = Number(moment().format('YYYYMMDD'))
        // 対象日が未来の場合
        if (tar > today) {
          this.isFuture = true
        } else {
          this.isFuture = false
        }

        this.siteName = utils.zenkaku(this.requestPost.employmentBuildingName)

        // メアドマスタ取得
        await this.getMailList()
        // 得意先名全角
        this.getZenkaku()
        // アポロ担当者リスト
        this.getCcList()

        // メールの場合
        if (this.isMail) {
          await this.getMailHistory()
        }
        // 本文をセット
        this.setText()

        // メアドマスタから該当のデータを取得
        this.getDataFromMailMaster()
      }

      if (!this.mail.length) {
        this.mail = ['']
      }
    },

    /**
     * 本文をセット
     */
    setText() {
      let mailText = ''
      let mailText2 = ''

      // 件名
      this.subject = '【' + this.requestPost.employmentBuildingName + '】 清掃員' + this.type + 'のご連絡'

      let text = 'いたします。'
      let text2 = 'ます、'
      let text3 = '急なお知らせで、'
      if (!this.isFuture) {
        text = 'いたしました。'
        text2 = 'ました、'
        text3 = 'ご連絡が遅くなり、'
      }

      // メールの場合
      if (this.isMail) {
        const toClient = this.setToClientName()
        const pic = this.setToPic()
        mailText = toClient + `\r\n${pic}\r\n\r\n`

        mailText2 = '\r\n\r\nアポロ管財株式会社\r\nTEL.03-5438-8804（代）\r\nFAX.03-5438-8809\r\n' + this.userFullName
      }
      
      // 入社
      const mailHire = 
        mailText +

        `お世話になっております。アポロ管財業務サポート部の${this.userName}と申します。\r\n` +

        `この度、${this.date}より（日常清掃員）${this.requestPost.employmentName} が入社${text}\r\n` +

        `${text3}誠に申し訳ございません。\r\n` +

        `お問い合わせにつきましては、弊社建物管理部  〇〇までご連絡ください。\r\n` +

        'よろしくお願いいたします。' +
        
        mailText2

      // 退職
      const mailRetire = 
        mailText +

        `お世話になっております。アポロ管財業務サポート部の${this.userName}と申します。\r\n` +

        `${this.requestPost.employmentBuildingName} で勤務しており${text2}\r\n` +

        `日常清掃員  ${this.requestPost.employmentName} ですが、一身上の都合により${this.date}付で退職${text}\r\n` +      

        `${text3}誠に申し訳ございません。\r\n` +

        '後任が決まるまでの間、代行員にて対応させていただきます。\r\n' +

        'お問い合わせにつきましては、弊社建物管理部  〇〇までご連絡ください。' +

        'よろしくお願いいたします。' +
        
        mailText2

      if (this.type == '入社') {
        this.text = mailHire
      } else {
        this.text = mailRetire
      }
    },

    /**
     * 得意先名と支店名の間に改行
     */
    setToClientName() {
      let toClient = utils.zenkaku(this.client.clientName)
      if (this.client.clientDepartmentName) {
        toClient += '\r\n' + utils.zenkaku(this.client.clientDepartmentName)
      }
      return toClient
    },

    /**
     * 担当者名をセット
     */
    setToPic() {
      let pic = 'ご担当者様'
      if (this.pics && this.pics.length) {
        const res = Array.from(new Set(this.pics))
        const res2 = res.filter((r) => {
          return r
        })
        pic = res2.join('、')
      }
      return pic
    },

    /**
     * メアドマスタ取得
     */
    async getMailList() {
      this.mailList = []
      const res = await clientMailManager.getMail(this)
      if (res && res.length) {
        res.forEach((m) => {
          if (m.details && m.details.length) {
            const mail = []
            const pic = []
            m.details.forEach((d) => {
              if (d.mail) {
                mail.push(d.mail)
              }
              if (d.pic) {
                pic.push(d.pic)
              }
            })
            m.mail = mail.join('、')
            m.pic = pic.join('、')
          }
        })
        this.mailList = res
      }
    },

    /**
     * メアドマスタから該当の得意先データを取得
     */
    getDataFromMailMaster() {
      if (this.mailList && this.mailList.length) {
        // 得意先名・支店名の完全一致
        const res = this.mailList.find((m) => {
          return ((utils.hankaku(m.client) == utils.hankaku(this.client.clientName) && ((!m.client2 && !this.client.clientDepartmentName) || utils.hankaku(m.client2) == utils.hankaku(this.client.clientDepartmentName))))
        })

        if (res && res.client) {
          // メールの場合
          if (this.isMail) {
            this.mailSelected(res, 0)
          // FAXの場合
          } else {
            this.picSelected(res)
          }
        }
      }
    },

    /**
     * 履歴取得
     */
    async getMailHistory() {
      this.mailHistory = []
      this.mailHistory = await clientMailManager.getHistoryByRequestNo(this, this.requestPost.requestNo)
    },

    /**
     * CCリスト取得、Bccもセット
     */
    async getCcList() {
      this.ccList = []
      let res = await backend.searchData('user/getUserList', { day: utils.getToday() })
      let userList = res.data.data
      if (userList && userList.length) {
        for (let i = 0; i < userList.length; i++) {
          const u = userList[i]
          if (u.employmentCC) {
            this.ccList.push({ name: utils.deleteSpace(utils.deleteKana(u.userName)), mail: u.eMailAdress })
          }
        }

        // 大野さんのメアド取得
        let ohno = userList.find((us) => {
          return us.id == '6244'
        })
        if (ohno) {
          this.bcc = ohno.eMailAdress
        }
      }
    },

    /**
     * メールアドレス追加
     */
    mailAdd() {
      this.mail.push('')
      this.pics.push('')
    },

    /**
     * cc追加
     */
    ccAdd() {
      this.cc.push('')
    },

    /**
     * メアド削除
     */
    mailDelete(i) {
      this.mail.splice(i, 1)
      this.pics.splice(i, 1)
      if (!this.mail.length) {
        this.mailAdd()
      }
    },

    /**
     * ccメアド削除
     */
    ccDelete(i) {
      this.cc.splice(i, 1)
      if (!this.cc.length) {
        this.ccAdd()
      }
    },

    /**
     * メール選択
     * @param e 選択したデータ
     * @param idx インデックス
     */
    mailSelected(e, idx) {
      const ad = e.details.map((m) => {
        return m
      })
      if (ad && ad.length) {
        for (let i = 0; i < ad.length; i++) {
          const a = ad[i]
          if (i == 0) {
            this.mail.splice(idx, 1, a.mail)
            this.pics.splice(idx, 1, a.pic)
          } else {
            this.mail.splice(idx, 0, a.mail)
            this.pics.splice(idx, 0, a.pic)
          }
        }
      }
    },

    /**
     * メール送信
     */
    async sendMail() {
      let validate = await this.validation()
      if (!validate) {
        return
      }

      const cc = this.cc.filter((c) => {
        return c
      })

      if (!cc || !cc.length) {
        const d1 = await dialogs.showConfirmDialog('CCの宛先確認', 'CCの宛先がありませんが、よろしいでしょうか？')
        if (d1 != 'YES') {
          return
        }
      }

      if (!this.bcc) {
        const d1 = await dialogs.showConfirmDialog('BCCの宛先確認', 'BCCに大野さんのメールがないですが、よろしいでしょうか？')
        if (d1 != 'YES') {
          return
        }
      }
      
      const dialog = await dialogs.showConfirmDialog('送信してよろしいでしょうか？', '')
      if (dialog != 'YES') {
        return
      }

      this.waiting = 'Sending...'

      const address = this.mail.filter((m) => {
        return m
      })

      let bcc = []
      if (this.bcc) {
        bcc = [this.bcc]
      }

      const param = {
        to: address,
        cc,
        bcc,
        subject: this.subject,
        text: this.text,
        staff: this.userName,
      }

      const result = await backend.postData('employment/sendmail', param)

      // 履歴を保存
      await this.saveHistory(result, param)

      this.waiting = ''
    },

    /**
     * 結果を履歴に保存
     */
    async saveHistory(result, param) {
      let sendStatus = '成功'
      if (result.status != 200) {
        sendStatus = '失敗'
        await dialogs.showErrorDialog('送信失敗しました', result)
      } else {
        await dialogs.showSuccessDialog('送信しました。')
      }
      param.sendStatus = sendStatus
      param.requestNo = this.requestPost.requestNo
      param.date = moment().format('YYYY/MM/DD HH:mm')
      const his = await clientMailManager.historyRegister(this, param)
      if (!his) {
        await dialogs.showErrorDialog('送信履歴保存エラー', '送信履歴保存中にエラーが発生しました。')
      }
      if (!this.mailHistory) {
        this.mailHistory = []
      }
      this.mailHistory.push(param)
    },

    /**
     * チェック処理
     */
    async validation() {
      if (this.isMail) {
        if (!this.mail || !this.mail.length) {
          await dialogs.showErrorDialog('メールアドレス未入力', 'メールアドレスを入力してください。')
          return false
        }
        const address = this.mail.filter((m) => {
          return m
        })
        if (!address || !address.length) {
          await dialogs.showErrorDialog('メールアドレス未入力', 'メールアドレスを入力してください。')
          return false
        }
        if (!this.subject) {
          await dialogs.showErrorDialog('件名未入力', '件名を入力してください。')
          return false
        }
      }
      if (!this.text) {
        await dialogs.showErrorDialog('本文未入力', '本文を入力してください。')
        return false
      } else if (this.text.indexOf('〇〇') > -1) {
        await dialogs.showErrorDialog('本文に○○があります', '本文を完成させてください。')
        return false
      }
      return true
    },

    /**
     * 得意先名全角取得
     */
    getZenkaku() {
      this.zenkakuName1 = ''
      this.senkakuName2 = ''
      
      if (this.client) {
        this.zenkakuName1 = utils.zenkaku(this.client.clientName)
        this.senkakuName2 = utils.zenkaku(this.client.clientDepartmentName)
      }
    },

    /**
     * 担当者選択
     */
    picSelected(e) {
      this.faxPic = e.pic || 'ご担当者様'
    },

    /**
     * PDF出力
     */
    async pdfBtn() {
      let validate = await this.validation()
      if (!validate) {
        return
      }
      this.waiting = 'Downloading...'
      await utils.wait(500)
      const param = {
        html: document.querySelector("#output").outerHTML
      }
      const res = await backend.postBlob2('employment/pdf', param)
      try {
        const uint8Array = res.data
        const blob = new Blob([uint8Array], { type : "application/pdf" })
        const url = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        let name = this.client.clientName + '_' + this.requestPost.employmentBuildingName + '_' + this.type

        a.download = name + '.pdf'
        a.click()
        a.remove()
        this.waiting = ''
      } catch (e) {
        console.log(e)
        alert(e)
        this.waiting = ''
      }
    },

    /**
     * メアドマスタモーダルopen
     * @param index インデックス
     */
    openClientModal(index) {
      this.index = index
      this.clientModalShow = true
    },

    /**
     * メアドマスタモーダルclose
     * @param row 反映するデータ
     */
    closeClientModal(row) {
      if (row) {
        if (this.isMail) {
          this.mailSelected(row, row.index)
        } else {
          this.picSelected(row)
        }
      }
      this.clientModalShow = false
    },

    /**
     * 閉じる
     */
    closed() {
      // ローディング
      this.waiting = false
      // メールアドレス
      this.mail = []
      // CC
      this.cc = ['']
      // メールタイトル
      this.subject = null
      // メール本文
      this.text = null
      // 未来か否か
      this.isFuture = false
      // 区分
      this.type = '入社'
      // 対象日
      this.date = ' 月 日'
      // メールマスタ画面
      this.clientModalShow = false
      // メールリスト
      this.mailList = []
      // メール履歴
      this.mailHistory = []
      // 得意先担当者
      this.pics = []
      // FAX用得意先名
      this.zenkakuName1 = ''
      // FAX用支店名
      this.senkakuName2 = ''
      // 物件名
      this.siteName = ''
      // FAX用得意先の担当者
      this.faxPic = 'ご担当者様'
    }
  }
}
</script>
<style scoped>
.main {
  min-width: 700px;
}

.fax-pic >>> input[type=text] {
  border: none;
  padding-top: 0.55rem !important;
  padding-bottom: 0.125rem !important;
  font-size: medium !important;
}
</style>