<template>
  <div>
    <CheckListTableComponent
      typeName='巡回清掃'
      :type=2
      routerName='RoundScheduleListByStaff'
      :siteLocation="siteLocation"
    >
    </CheckListTableComponent>
  </div>
</template>

<script>
import CheckListTableComponent from '../components/RoundCheckListTableComponent.vue'

export default {
  components: {
    CheckListTableComponent,
  },

  props: {
    // 位置情報
    siteLocation: {
      type: Object
    },
  },
}
</script>