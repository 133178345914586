<template>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
        <div class="overflow-hidden rounded main-height">
            <WmsTextInput 
                name="name" 
                caption="名称（得意先名など）" 
                class="mb-3 w-60"
                v-model="val.name"
            />

            <div class="flex justify-center">
                <div class="mr-3 text-gray-700">
                    ①
                </div>
                <div>
                    <input
                        type="color"
                        class="w-20"
                        v-model="val.color1"
                    >
                </div>
            </div>
            <div class="flex justify-center">
                <div class="mr-3 text-gray-700">
                    ②
                </div>
                <div>
                    <input
                        type="color"
                        class="w-20"
                        v-model="val.color2"
                    >
                </div>
            </div>
            <div class="flex justify-center">
                <div class="mr-3 text-gray-700">
                    ③
                </div>
                <div>
                    <input
                        type="color"
                        class="w-20"
                        v-model="val.color3"
                    >
                </div>
            </div>

            <div class="mt-3 w-full mx-auto">
                <PrimaryButton text="保存" size="normal w-full" @click="save()" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'
// import * as utils from '@libs/utils'
export default {
    components: {
        WmsTextInput,
        Modal,
        PrimaryButton
    },

    props: ['editModalShow', 'target', 'originalList'],

    data() {
        return {
            // 編集データ
            val: {
                name: '',
                color1: '#ffffff',
                color2: '#ffffff',
                color3: '#ffffff'
            },
            // 編集か否か
            isEdit: false
        }
    },

    computed: {
    },

    watch: {
        editModalShow() {
            if (this.editModalShow) {
                this.getData()
            }
        }
    },

    methods: {
        /**
         * 編集対象データ取得
         */
        async getData() {
            // 編集対象データがある場合
            if (this.target) {
                this.isEdit = true
                this.val = {}
                this.val = {
                    id: this.target.id,
                    name: this.target.name,
                    color1: this.target.color1,
                    color2: this.target.color2,
                    color3: this.target.color3
                }
            } else {
                this.isEdit = false
            }
        },

        /**
         * 入力チェック
         */
        async validate() {
            if (!this.val.name || this.val.name == '') {
                await dialogs.showErrorDialog('名称', '名称を入力してください。')
                return false
            }
            return true
        },

        /**
         * 保存イベント
         */
        async save() {

            if (!await this.validate()) {
                return
            }

            let result

            // 更新
            if (this.val.id && this.val.id != '') {
                this.val.updateUserId = this.$store.getters.user.id
                result = await backend.postData('photoReportColor/save', this.val)
                logManager.recordingByRegist(this, false, '報告書カラー', `更新（${this.val.name}）`)

            // 新規
            } else {
                this.val.insertUserId = this.$store.getters.user.id
                console.log(this.val)
                result = await backend.postData('photoReportColor/save', this.val)
                logManager.recordingByRegist(this, false, '報告書カラー', `新規登録（${this.val.name}）`)
            }
            
            if (!result) {
                await dialogs.showErrorDialog('保存エラー', '保存中にエラーが発生しました。')
                return
            }

            this.closed
            this.$emit('closePhotoReportColorEditModal')
        },

        /**
         * 初期化
         */
        closed() {
            // 編集データ
            this.val = {
                name: '',
                color1: '#ffffff',
                color2: '#ffffff',
                color3: '#ffffff'
            },
            // 編集か否か
            this.isEdit = false
        }
    }
}
</script>
<style scoped>
.main-height {
    max-height: calc(100% - 200px);
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>