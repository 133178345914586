<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height">
      <div class="mb-11">
        <div class="mb-3 text-sm">
          得意先コード： <span class="font-bold">{{ val.clientCode }}</span>
        </div>
        <div class="mb-3 text-sm">
          得意先名： <span class="font-bold">{{ val.clientName }}</span>
        </div>
        <div class="mb-3 text-sm">
          支店名： <span class="font-bold">{{ val.clientName2 }}</span>
        </div>
      </div>

      <div class="text-xs font-bold text-gray-700 mb-1">
        手数料率
      </div>
      <input
        type="number"
        placeholder="手数料率"
        class="border border-gray-300 rounded-md leading-5 focus:bg-white focus:ring-white focus:placeholder-gray-500 focus:text-gray-900" 
        v-model="val.rate"
      />
      <span class="ml-3">％</span>

      <WmsTextInput 
        name="memo" 
        caption="備考" 
        class="mt-3 mb-5"
        v-model="val.memo"
      />
      <div class="mt-3 mb-2 mx-2">
        <PrimaryButton text="保存" size="normal w-full" :disabled="!val || !val.clientCode" @click="save()" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'
export default {
  components: {
    WmsTextInput,
    Modal,
    PrimaryButton,
  },

  props: ['open', 'target'],

  data() {
    return {
      // 編集データ
      val: {
        clientCode: '',
        client: '',
        client2: '',
        address: '',
        rate: null,
        memo: ''
      },
    }
  },

  watch: {
    open() {
      if (this.open) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 編集対象データ取得
     */
    async getData() {
      // 編集対象データがある場合
      if (this.target) {
        this.val = utils.clone(this.target)
      } else {
        this.val = {}
      }
    },

    /**
     * 保存イベント
     */
    async save() {
      await backend.postData('estimateCommissionRate/save', { ...this.val, user: this.$store.getters.user.id })

      let newFlg = '新規登録'
      // 更新の場合
      if (this.target.rateId) {
        newFlg = '更新'
      }

      // ログ用メッセージ
      let name = this.val.clientName
      if (this.val.clientName2) {
        name += `（${this.val.clientName2}）`
      }
      let rate = 'なし'
      if (this.val.rate) {
        rate = this.val.rate + '%'
      }
      logManager.recording(this, logManager.Loglevel.INFO, '手数料率マスタ', newFlg, `得意先：${name}、手数料率：${rate}で登録しました。`)

      await dialogs.showSuccessDialog('保存完了', '保存完了しました。')

      this.$emit('closeModal')
      this.closed
    },

    /**
     * 初期化
     */
    closed() {
    }
  }
}
</script>
<style scoped>

</style>