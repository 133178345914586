import * as logManager from '@managers/logManager'
// 募集媒体リスト
const MEDIA_TASKS_TYPE = 'employment_media'

/**
 * 募集媒体リスト取得
 * @param {*} context 
 * @returns 
 */
export async function getMediaList(context) {
  const searchCondition = {
    searchCondition: {
      type: MEDIA_TASKS_TYPE,
      status: { $ne: "remove" },
    },
    sort: { sortNum: 1 }
  }
  try {
    const responseData = await context.$pigeon.searchTasks(searchCondition)
    return responseData
  } catch (error) {
    console.log('getMediaList error !!!')
    console.log(error)
    alert(error)
    return []
  }
}

/** 
 * 募集媒体リスト削除
 */
export async function deleteTask(context, data) {
  try {
    await context.$pigeon.deleteTask(data._id)
    logManager.recording(context, logManager.Loglevel.INFO, '募集媒体リスト', '削除', `「${data.name}」を削除しました。`)
    return true
  } catch (error) {
    console.log('employmentMediaManager.deleteTask error !!!')
    console.log(error)
    alert(error)
    return false
  }
}

export async function saveData(context, data) {
  if (data._id) {
    // 更新
    update(context, data._id, data)
  } else {
    // 新規
    register(context, data)
  }
}

/**
 * 更新
 * @param {*} context 
 * @param id 更新するID
 * @param {*} data 更新データ
 * @returns 
 */
export async function update(context, id, data) {
  try {
    await context.$pigeon.updateTask(id, data, {})
    logManager.recording(context, logManager.Loglevel.INFO, '募集媒体リスト', '更新', `「${data.name}」を更新しました。`)
    return true
  } catch (error) {
    console.log('employmentMediaManager.update error !!!')
    console.log(error)
    alert(error)
    return false
  }
}

/**
 * 新規登録
 * @param {*} context 
 * @param {*} data 登録データ
 * @returns 
 */
export async function register(context, data) {
  try {
    await context.$pigeon.registerTask(MEDIA_TASKS_TYPE, data, {})
    logManager.recording(context, logManager.Loglevel.INFO, '募集媒体リスト', '新規登録', `「${data.name}」を登録しました。`)
    return true
  } catch (error) {
    console.log('employmentMediaManager.register error !!!')
    console.log(error)
    alert(error)
    return false
  }
}