<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">
			<div class="">
				<div class="text-sm text-gray-500 mb-5">
					色・位置をお選びください。
				</div>

				<div class="flex">
					<PrimaryButton text="配色を参照" class="w-44 ml-1 mr-2 bg-blue-400" @click="reference()" >
						<Icon slot="before" iconName="DocumentSearch" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
					</PrimaryButton>
					<PrimaryButton text="この配色を保存" class="w-44 bg-blue-600 mr-1" @click="regist()" >
						<Icon slot="before" iconName="ClipboardCopy" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
					</PrimaryButton>
				</div>

				<div class="flex mt-5">
					<div class="w-60">
						①背景および得意先名の色
					</div>
					<div>
						<input
							type="color"
							class="w-20"
							v-model="bgColor"
						>
					</div>
				</div>
				
				<div class="flex">
					<div class="w-60">
						②物件名および清掃箇所の色
					</div>
					<div>
						<input
							type="color"
							class="w-20"
							v-model="textColor"
						>
					</div>
				</div>
				
				<div class="flex">
					<div class="w-60">
						③タイトルの色
					</div>
					<div>
						<input
							type="color"
							class="w-20"
							v-model="titleColor"
						>
					</div>
				</div>
				
				<div class="flex mt-5">
					<div class="w-60 my-auto">
						得意先・物件名の位置
					</div>
					<div>
						<select
							name="align"
							v-model="textAlign"
							class="focus:bg-yellow-100 border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500 block w-full"
						>
							<option
								v-for="ali in alignList" :key='ali.id'
								:value="ali.id"
								class="bg-white">
								{{ ali.name }}
							</option>
						</select>
					</div>
				</div>
				
				<PrimaryButton text="報告書に反映" class="w-full max-w-md mt-5" @click="saveColor()" ></PrimaryButton>
			</div>

			<!-- 写真報告書カラー一覧 -->
			<photoReportColorModal 
				v-model="photoReportColorModalShow"
				:photoReportColorModalShow="photoReportColorModalShow"
				:isChoice="true"
				:selecter="true"
				@closePhotoReportColorModal="closePhotoReportColorModal"
			/>

			<!-- 写真報告書カラー編集モーダル -->
			<PhotoReportColorEditModal
				v-model="photoReportColorEditModalShow"
				:editModalShow="photoReportColorEditModalShow"
				:target="registColor"
				@closePhotoReportColorEditModal="closePhotoReportColorEditModal"
			/>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import Icon from '@components/Icon.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import PhotoReportColorModal from '../../PhotoReport/components/PhotoReportColorModal.vue'
import PhotoReportColorEditModal from '../components/PhotoReportColorEditModal.vue'

export default {
	components: {
		Modal,
		PrimaryButton,
		Icon,
		PhotoReportColorModal,
		PhotoReportColorEditModal
	},

	props: ['open', 'color', 'align'],

	data() {
		return {
			bgColor: null,
			textColor: null,
			titleColor: null,
			textAlign: null,
			alignList: [
				{ id: 'right', name: '右' },
				{ id: 'center', name: '中央' },
				{ id: 'left', name: '左' }
			],
			// 写真報告書カラーモーダル開閉
			photoReportColorModalShow: false,
			// 写真報告書カラー編集モーダル開閉
			photoReportColorEditModalShow: false,
			// カラー登録データ
			registColor: {},
			// 選択カラーデータ
			selectedColor: null
		}
	},

	computed: {
	},

	watch: {
		open() {
			if (this.open) {
				this.bgColor = this.color.bgColor
				this.textColor = this.color.textColor
				this.titleColor = this.color.titleColor
				this.textAlign = this.align || 'right'
			}
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
		},

		/**
		 * 色決定ボタン
		 */
		async saveColor() {
			const color = { bgColor: this.bgColor, textColor: this.textColor, titleColor: this.titleColor }
			const align = { align: this.textAlign }
			this.$emit('close', { color, ...align })
		},

		/**
		 * 配色を参照
		 */
		async reference() {
			this.selectedColor = null
			this.photoReportColorModalShow = true
		},

		/**
		 * 写真報告書カラー一覧 モーダルclose
		 */
		closePhotoReportColorModal(selectedColor) {
			this.photoReportColorModalShow = false
			if (selectedColor && selectedColor.color1) {
				this.bgColor = selectedColor.color1
				this.textColor = selectedColor.color2
				this.titleColor = selectedColor.color3
			}
		},

		/**
		 * 配色を保存
		 */
		async regist() {
			this.registColor = {
				id: null,
				name: '',
				color1: this.bgColor,
				color2: this.textColor,
				color3: this.titleColor
			}
			this.photoReportColorEditModalShow = true
		},

		/**
		 * 写真報告書カラー編集 モーダルclose
		 */
		closePhotoReportColorEditModal() {
			this.photoReportColorEditModalShow = false
		}
	}
}
</script>
<style scoped>
</style>