import Vue from 'vue'
import Vuex from 'vuex'
import * as backend from '@libs/backend'

const appVersion = require('../../package.json').version
const beta = require('../../package.json').beta 
const appVersionString = `${appVersion} ${beta}`

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      appVersion: appVersionString,
      user: null,
      userList: [],
      unitList: [],
      estimateCategoryList: [],
      estimateSubItemList: [],
      estimateItemPriceList: [],
      // requestPostListState: null,
      departmentList: [],
      transDepartmentList: [],
      auth: null,
      userDepartment:[],
      transUserList: [],
      // メインタスク権限
      mainAuth: false,
      // サブタスク権限
      subAuth: false,
      // 見積り権限
      estimateAuth: false,
      // ログインユーザーに関するデータ
      loginUserData: null,
      // 部署の兼任を含めたログインユーザーの所属部署・課
      dualPosition: [],
      // 発注率
      purchaseRate: []
    },
    getters: {
      appVersion: (state) => {
        return state.appVersion
      },
      isSignin: (state) => {
        return !(state.user === null)
      },
      user: (state) => {
        return state.user
      },
      userList: (state) => {
        return state.userList
      },
      unitList: (state) => {
        return state.unitList
      },
      estimateCategoryList: (state) => {
        return state.estimateCategoryList
      },
      estimateSubItemList: (state) => {
        return state.estimateSubItemList
      },
      estimateItemPriceList: (state) => {
        return state.estimateItemPriceList
      },
      // requestPostListState: (state) => {
      //   return state.requestPostListState
      // },
      departmentList: (state) => {
        return state.departmentList
      },
      transDepartmentList: (state) => {
        return state.transDepartmentList
      },
      auth: (state) => {
        return state.auth
      },
      userDepartment: (state) => {
        return state.userDepartment
      },
      transUserList: (state) => {
        return state.transUserList
      },
      mainAuth: (state) => {
        return state.mainAuth
      },
      subAuth: (state) => {
        return state.subAuth
      },
      estimateAuth: (state) => {
        return state.estimateAuth
      },
      loginUserData: (state) => {
        return state.loginUserData
      },
      dualPosition: (state) => {
        return state.dualPosition
      },
      purchaseRate: (state) => {
        return state.purchaseRate
      },
    },
    mutations: {
      setUser(state, user) {
        state.user = user
      },
      setUserList(state, userList) {
        state.userList = userList
      },
      setEstimateCategoryList(state, estimateCategoryList) {
        state.estimateCategoryList = estimateCategoryList
      },
      setEstimateSubItemList(state, estimateSubItemList) {
        state.estimateSubItemList = estimateSubItemList
      },
      setEstimateItemPriceList(state, estimateItemPriceList) {
        state.estimateItemPriceList = estimateItemPriceList
      },
      // setRequestPostListState(state, requestPostListState) {
      //   state.requestPostListState = requestPostListState
      // },
      setDepartmentList(state, departmentList) {
        state.departmentList = departmentList
      },
      setTransDepartmentList(state, transDepartmentList) {
        state.transDepartmentList = transDepartmentList
      },
      setAuth(state, auth) {
        state.auth = auth
      },
      setUserDepartment(state, userDepartment) {
        state.userDepartment = userDepartment
      },
      setTransUserList(state, transUserList) {
        state.transUserList = transUserList
      },
      setMainAuth(state, mainAuth) {
        state.mainAuth = mainAuth
      },
      setSubAuth(state, subAuth) {
        state.subAuth = subAuth
      },
      setEstimateAuth(state, estimateAuth) {
        state.estimateAuth = estimateAuth
      },
      setLoginUserData(state, loginUserData) {
        state.loginUserData = loginUserData
      },
      setDualPosition(state, dualPosition) {
        state.dualPosition = dualPosition
      },
      setPurchaseRate(state, purchaseRate) {
        state.purchaseRate = purchaseRate
      },
    },
    actions: {
      setUser(context, user) {
        context.commit('setUser', user);
      },
      setAuth(context, auth) {
        context.commit('setAuth', auth);
      },
      setMainAuth(context, mainAuth) {
        context.commit('setMainAuth', mainAuth);
      },
      setSubAuth(context, subAuth) {
        context.commit('setSubAuth', subAuth);
      },
      setEstimateAuth(context, estimateAuth) {
        context.commit('setEstimateAuth', estimateAuth);
      },
      setLoginUserData(context, loginUserData) {
        context.commit('setLoginUserData', loginUserData);
      },
      setDualPosition(context, dualPosition) {
        context.commit('setDualPosition', dualPosition);
      },
      async initialize(context, pigeon) {
        const userList = await pigeon.getUserList()
        context.commit('setUserList', userList)

        const estimateCategoryList = await pigeon.getTasksByFormId('estimate_category_master')
        context.commit('setEstimateCategoryList', estimateCategoryList)

        const estimateSubItemList = await pigeon.getTasksByFormId('estimate_sub_item_master', { parentCode: 1, order:1 })
        context.commit('setEstimateSubItemList', estimateSubItemList)
        
        const estimateItemPriceList = await pigeon.getTasksByFormId('estimate_item_price_master')
        context.commit('setEstimateItemPriceList', estimateItemPriceList)
        
        const departmentList = await backend.getData('department/get')
        context.commit('setDepartmentList', departmentList.data.data)
        
                
        const transDepartmentList = await backend.getData('department/getTrans')
        context.commit('setTransDepartmentList', transDepartmentList.data.data)
                
        const userDepartment = await backend.getData('userDepartment/get')
        context.commit('setUserDepartment', userDepartment.data.data)

        const transUserList = await backend.getData('userDepartment/getTransUser')
        context.commit('setTransUserList', transUserList.data.data)
        
        const purchaseRate = await pigeon.getTasksByFormId('estimate_purchase_rate_master')
        context.commit('setPurchaseRate', purchaseRate)
      },
      terminate(context) {
        context.commit('setUserList', [])
        context.commit('setEstimateCategoryList', [])
        context.commit('setEstimateSubItemList', [])
        context.commit('setEstimateItemPriceList', [])
        context.commit('setDepartmentList', [])
        context.commit('setTransDepartmentList', [])
        context.commit('setUserDepartment', [])
        context.commit('setTransUserList', [])
        context.commit('setPurchaseRate', [])
        // context.commit('setAuth', [])
      },
      // setEstimateListState(context, estimateListState) {
      //   context.commit('setEstimateListState', estimateListState)
      // },
      setEstimateSubItemListState(context, estimateSubItemListState) {
        context.commit('setEstimateSubItemListState', estimateSubItemListState)
      },
      setEstimateItemPriceListState(context, estimateItemPriceListState) {
        context.commit('setEstimateItemPriceListState', estimateItemPriceListState)
      },
      // setRequestPostListState(context, requestPostListState) {
      //   context.commit('setRequestPostListState', requestPostListState)
      // },
      setDepartmentListState(context, departmentListState) {
        context.commit('setDepartmentListState', departmentListState)
      },
      setTransDepartmentListState(context, transDepartmentListState) {
        context.commit('setTransDepartmentListState', transDepartmentListState)
      },
      setUserDepartmentState(context, setUserDepartmentState) {
        context.commit('setUserDepartmentState', setUserDepartmentState)
      },
      setTransUserListState(context, transUserListState) {
        context.commit('setTransUserListState', transUserListState)
      },
      setPurchaseRateState(context, setPurchaseRateState) {
        context.commit('setPurchaseRateState', setPurchaseRateState)
      },
    },
    modules: {},
})

export default store
