<template>
  <div class="h-full">
    <VirtualDataTable 
      :columns="columns"
      :columnWidth="columnWidth" 
      :columnAlignment="columnAlignment" 
      :rowHeight="60" 
      :data="filteredData" 
      :selectable="true"
      :rowClassesFunc="rowClassesFunc"
      :isSort="true"
      :initSortItem="sortItem"
      :initSortCount="sortCount"
      @dblClickRow="dblClickRow"
      @changeSort="changeSort"
    />
  </div>
</template>

<script>
import VirtualDataTable from '@components/VirtualDataTable.vue'
import * as requestPostManager from '@managers/requestPostManager'
import * as backend from '@libs/backend'
import * as utils from '@libs/utils'

// カラム幅
const columnWidth = [
  '60px', // 未完了
  '90px', // ステータス
  // '150px', // 社内/お客様
  '150px', // お問い合わせカテゴリー
  'auto', // タイトル
  '200px', // お客様会社名
  '140px', // お客様担当者名
  // 'auto', // アポロ管財依頼先部署
  '140px', // 担当者
  // 'auto', // 依頼主
  '150px', // 期限
  '60px', // コピー
  '60px', // 編集
  '90px', // 依頼登録
]

// カラム位置
const columnAlignment = [
  'left',
  'center', // ステータス
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
  'left',
  // 'left',
  // 'left',
  // 'left',
]

// 検索対象プロパティ
const searchKeys = ['initialTransaction', 'category2', 'category', 'inquiry_title', 'clientName', 'clientPersonName', 'toDepartment', 'toStaffName', 'byUser', 'telephoneNumber', 'requestNo', 'interviewsKana', 'employmentKana']

// 日付絞込み対象プロパティ
const searchDateKey = ['limitDate']

export default {
  components: {
    VirtualDataTable
  },

  props: [ 
    'searchKeyword',
    'searchCategory',
    'searchClientName',
    'searchClientPersonName',
    'searchToDepartment',
    'searchToStaffName',
    'searchByUser',
    'searchLimitDate',
    'searchStatus',
    'period',
    'freeze',
    'mainTask',
    'orderTask',
    'employmentTask',
    'employmentRegularTask',
    'filterToStaffUser',
    'filterByUser',
    'withSub',
    'sortItem',
    'sortBy'
  ],

  data() {
    return {
      columns: [
        [
          {
            name: 'Incomplete',
            title: '操作',
            type: 'actionText',
            url: '',
            onclick: (rowData) => {
              this.change(rowData)
            },
            valueFunc: rowData => {
              if (rowData.type == 'apollo_request_post' || rowData.type == 'order') {
                if(rowData.status == 'open') {
                  return '未完了'
                } else if(rowData.status == 'done') {
                  return '完了済'
                }
              } else {
                return ''
              }
            }
          }
        ],
        [ { iconType: 'solid', title: 'ステータス', type: 'icon' } ],
        // [ { name: 'category2String', title: '社内/お客様', type: 'text', columnSortDisable: true } ],
        [ { name: 'categoryString', title: 'お問い合わせカテゴリー', type: 'text', columnSortDisable: true } ],
        [ { name: 'inquiry_title', title: 'タイトル', type: 'text', columnSortDisable: true } ],
        [ { name: 'clientName', title: 'お客様会社名', type: 'text', columnSortDisable: true } ],
        [ { name: 'clientPersonName', title: 'お客様担当者名', type: 'text', columnSortDisable: true }, ],
        // [ { name: 'toDepartment', title: 'アポロ管財依頼先部署', type: 'text', columnSortDisable: true } ],
        [ { name: 'toStaffName', title: '担当者', type: 'text', columnSortDisable: true } ],
        // [ { name: 'byUser', title: '依頼主', type: 'text', columnSortDisable: true } ],
        [ 
          { 
            name: 'limitDate', 
            title: '期限', 
            type: 'text',
            columnSortDisable: true,
            valueFunc: rowData => {
              const d = utils.stringToDate(rowData.limitDate)
              if (d != 'Invalid Date') {
                return utils.formatDate(d, '{yyyy}年{MM}月{dd}日')
              } else {
                return ''
              }
            }
          } 
        ],
        [
          // {
          //   name: 'operation',
          //   title: '操作',
          //   type: 'link',
          //   url: '/RequestPost?requestPostId=${record._id}&mode=2',
          //   valueFunc: requestPostData => {
          //     if (requestPostData.type == 'apollo_request_post') {
          //       return 'コピー'
          //     } else {
          //       return ''
          //     }
          //   }
          // }
          {
            name: 'operation',
            title: '操作',
            type: 'link',
            url: '/RequestPost?requestPostId=${record._id}&mode=3',
            valueFunc: requestPostData => {
              if (requestPostData.type == 'apollo_request_post') {
                return '複写'
              } else {
                return ''
              }
            }
          }
        ],
        [
          {
            name: 'editLink',
            title: '',
            type: 'link',
            url: rowData => {
              if (rowData.status != 'done') {
                if (rowData.type == 'apollo_request_post') {
                  return '/RequestPost?requestPostId=${record._id}&mode=1'
                } else if (rowData.type == 'order') {
                  return '/RequestPost?orderId=${record._id}&mode=6'
                }
              } else {
                return ''
              }
            },
            valueFunc: rowData => {
              if ((rowData.type == 'apollo_request_post' || rowData.type == 'order') && rowData.status != 'done') {
                return '編集'
              } else {
                return ''
              }
            }
          }
        ],
        [
          {
            name: 'requestRegistration',
            title: '',
            type: 'link',
            url: rowData => {
              if (rowData.status != 'done') {
                if (rowData.type == 'apollo_request_post') {
                  if (rowData.temporarySaveFlag == true) {
                    return ''
                  } else {
                    return '/taskRequest?requestPostId=${record._id}'
                  }
                } else if (rowData.type == 'order') {
                  return '/taskRequest?orderId=${record._id}&mode=1'
                } else {
                  return ''
                }
              } else {
                return ''
              }
            },
            colorTable: rowData => {
              if ((rowData.type == 'apollo_request_post' || rowData.type == 'order') && rowData.status != 'done') {
                if (rowData.temporarySaveFlag && rowData.temporarySaveFlag == true) {
                  return 'black'
                } else {
                  return 'blue'
                }
              } else {
                return ''
              }
            },
            valueFunc: rowData => {
              if ((rowData.type == 'apollo_request_post' || rowData.type == 'order') && rowData.status != 'done') {
                if (rowData.temporarySaveFlag) {
                  return '仮登録'
                } else {
                  return '依頼登録'
                }
              } else {
                return ''
              }
            }
          }
        ],
      ],
      columnWidth,
      columnAlignment,
      data: [],
      filteredData: [],
      fetchDate: '',
      userName: '',
      // データが多すぎて表示不可
      overData: false,
      sortCount: 0
    }
  },

  watch: {
  },

  created() {
    this.userName = this.$store.getters.user.user_name

    // 初期ソート sortBy ⇒ sortCount 変換
    if (this.sortBy && this.sortItem) {
      this.sortCount = this.sortBy == -1 ? 0 : 1
    }
  },

  methods: {
    async fetchRequestPosts() {
      // 管理者ユーザー、大野様、ログインユーザーの所属部署（兼任も含めて）が人事部、所属部署課長クラスなら、true
      const isEmpRes = await backend.searchData('user/getDepartment', { user: this.userName })
      const isEmployment = utils.isRegularStaffAuthorized(this.$store.getters.user.id, isEmpRes)
      const departmentArr = utils.departmentArr(isEmpRes)

      if (!this.freeze) {
        // console.log('RequestPostTable::fetchRequestPosts')
        this.$emit('search-start')
        this.fetchDate = utils.getToday()

        let key = this.searchKeyword
        let toStaffUserKey = ''
        let byUserKey = ''

        // ログインユーザーで担当者を絞込み
        if (this.filterToStaffUser) {
          toStaffUserKey = utils.deleteKana(this.userName)
        }

        // ログインユーザーで依頼主を絞込み
        if (this.filterByUser) {
          byUserKey = utils.deleteKana(this.userName)
        }

        // 期限フォーマット統一
        let limitDate = ''
        if (this.searchLimitDate) {
          limitDate = utils.hankaku(this.searchLimitDate)
          limitDate = limitDate.replace(/年/g, '-').replace(/月/g, '-').replace(/日/g, '').replace(/\//g, '-')

          // 年月日やハイフンなどがある場合
          if (limitDate.indexOf('-') > -1) {
            const limitArr = limitDate.split('-')
            for (let i = 0; i < limitArr.length; i++) {
              const li = limitArr[i]
              if (li.length == 1) {
                // 0詰めする
                limitArr[i] = li.padStart(2, '0')
              }
            }
            limitDate = limitArr.join('-')
          
          // 年月日やハイフンなど何もなく、数字のみの場合
          } else {
            if (limitDate.length == 8) {
              limitDate = limitDate.substring(0, 4) + '-' + limitDate.substring(4, 6) + '-' + limitDate.substring(6)
            } else if (limitDate.length == 6) {
              limitDate = limitDate.substring(0, 4) + '-' + limitDate.substring(4, 6)
            }
          }
        }
        const list = await requestPostManager.searchRequestPosts(
          this,
          searchKeys,
          key,
          searchDateKey,
          this.period,
          this.searchToDepartment,
          this.sortItem,
          this.sortBy,
          this.mainTask,
          this.withSub,
          this.orderTask,
          this.employmentTask,
          this.employmentRegularTask,
          toStaffUserKey,
          byUserKey,
          limitDate,
          this.searchCategory,
          this.searchClientName,
          this.searchClientPersonName,
          this.searchToStaffName,
          this.searchByUser,
          this.searchStatus,
          this.$store.getters.user.id
        )

        this.overData = false
        if (list.error) {
          if (list.msg && list.msg.indexOf('limit') > -1) {
            this.overData = true
          } else {
            alert('絞り込み条件を追加してください。\r\n' + list.msg)
          }
          this.data = []
        }

        this.data = []
        // データを成形
        for (let i = 0; i < list.length; i++) {
          const main = list[i]

          if (this.isRemoveEmploymentData(isEmployment, main, departmentArr)) {
            continue
          }

          this.data.push(main)
          // お問い合わせカテゴリー を文字列に変換
          let category = main.category
          if (category) {
            main.categoryString = category.join(',')
          } else {
            main.categoryString = ''
          }

          // 社内/お客様 を文字列に変換
          let category2 = main.category2
          if (category2) {
            main.category2String = category2.toString()
          } else {
            main.category2String = ''
          }

          // ステータスアイコンをセット
          this.setStatusIcon(main)

          // サブタスク表示の場合
          if (this.withSub) {
            // メインのサブ
            if (main.taskRequest) {
              for (let j = 0; j < main.taskRequest.length; j++) {
                let subTasks = main.taskRequest[j]
                subTasks.inquiry_title = main.inquiry_title + '　' + subTasks.requestTask
                // ステータスアイコンをセット
                this.setStatusIcon(subTasks)
                this.data.push(subTasks)
              }
            }
            // 受注のサブ
            if (main.taskRequestOrder) {
              for (let j = 0; j < main.taskRequestOrder.length; j++) {
                let subTasks = main.taskRequestOrder[j]
                subTasks.inquiry_title = main.inquiry_title + '　' + subTasks.requestTask
                // ステータスアイコンをセット
                this.setStatusIcon(subTasks)
                this.data.push(subTasks)
              }
            }
          }
        }
        console.log(this.data.length)

        // 配列項目ソート対応
        this.data = this.sortDataToArrayItem(this.data, this.sortItem, this.sortBy)

        this.filteredData = this.data

        this.$emit('search-end', { res: this.data, overData: this.overData })
      }
    },

    /**
     * 非表示にするデータか確認
     * @param isEmployment 人事部か否か
     * @param row 対象データ
     * @param departmentArr 所属部署の配列（部長課長クラスのみ配列あり）
     */
    isRemoveEmploymentData(isEmployment, row, departmentArr) {
      // 社員雇い入れがOFFは非表示
      if (!this.employmentRegularTask && row.category.includes('雇い入れ') && row.employmentRegularStaff) {
        return true
      }
      // DC雇い入れがOFFは非表示
      if (!this.employmentTask && row.category.includes('雇い入れ') && !row.employmentRegularStaff) {
        return true
      }
      // 人事部の場合は表示する:false返却
      if (isEmployment) {
        return false
      }
      // 社員の雇い入れの場合
      if (row.category.includes('雇い入れ') && row.employmentRegularStaff) {
        // 所属部署の課長・部長クラスなら表示する:false返却
        if (utils.isRegularStaffAuthorized2(departmentArr, row.employmentDepartmentId)) {
          return false
        }
        return true
      } else {
        return false
      }
    },

    /**
     * ステータスアイコンをセット
     * @param original 対象データ
     */
    setStatusIcon(original) {
      if ((original.status == 'open' && original.limitDate < this.fetchDate)) {
        original.iconName = 'ExclamationCircle'
      } else if(original.status == 'done') {
        original.iconName = 'CheckCircle'
      } else if(original.status == 'open' && original.limitDate >= this.fetchDate) {
        original.iconName = ''
      } else {
        original.iconName = 'MinusCircle'
      }
    },

    /**
     * 行の背景色をセット
     * @param rowData 行データ
     */
    rowClassesFunc(rowData) {
      if (rowData.limitDate < this.fetchDate && rowData.status == 'open') {
        return ['bg-red-100']
      } else if(rowData.status == 'done') {
        return ['bg-blue-100']
      } else if (rowData.limitDate == this.fetchDate && rowData.status == 'open') {
        return ['bg-yellow-100']
      } else {
        return ['bg-green-100']
      }
    },

    /**
     * リクエストポストID より タスク依頼IDを取得
     */
    // async getTaskRequestIdByRequestPostId(requestPostId) {
    //   // RPに紐づく、タスク依頼セットを検索
    //   const tr = await taskRequestManager.getByRequestPostId(this, requestPostId, null)
    //   // 見つかった場合、IDを返却
    //   if (tr) {
    //     return tr._id
    //   } else {
    //     return null
    //   }
    // },

    /**
     * 未完了ボタンを押下後、データを取得
     * @param {*} rowData 
     */
    change(rowData) {
      this.$emit('change', rowData)
    },

    /**
     * 行 ダブルクリック イベント
     * @param {*} item 
     */
     dblClickRow(item) {
      if (item.type == 'apollo_request_post') {
        this.$router.push({ name: 'RequestPost', query: { mode: 1, requestPostId: item._id }})
      } else if (item.type == 'order') {
        this.$router.push({ name: 'RequestPost', query: { mode: 6, orderId: item._id }})
      } else if (item.type == 'task_request') {
        this.$router.push({ name: 'RequestPost', query: { mode: 1, requestPostId: item.requestPostId }})
      } else if (item.type == 'task_request_order') {
        this.$router.push({ name: 'RequestPost', query: { mode: 6, orderId: item.orderId }})
      }
     },   

     /*
     * 一覧データ 各項目のソート切り替え
     * @param {*} column 
     * @param {*} sort 
     */
    async changeSort(column, sort) {
      // this.sortBy = sort
      // this.sortItem = column[0].name
      await this.$emit('change-sort', { item: column[0].name, by: sort})
      this.fetchRequestPosts()
    },


    /**
     * 一覧データ 配列項目ソート
     * @param {*} data 一覧データ
     * @param {*} item ソート項目
     * @param {*} orderby ソート順
     */
    sortDataToArrayItem(data, item, orderby) {

      if (orderby == 1 ) {
        if (item == 'categoryString') {
          data.sort(function(a, b) {
            if (a.categoryString > b.categoryString) {
              return 1
            } else {
              return -1
            }
          })
        }
      } else {
        if (item == 'categoryString') {
          data.sort(function(a, b) {
            if (a.categoryString < b.categoryString) {
              return 1
            } else {
              return -1
            }
          })
        }
      }

      if (orderby == 1 ) {
        if (item == 'category2String') {
          data.sort(function(a, b) {
            if (a.category2String > b.category2String) {
              return 1
            } else {
              return -1
            }
          })
        }
      } else {
        if (item == 'category2String') {
          data.sort(function(a, b) {
            if (a.category2String < b.category2String) {
              return 1
            } else {
              return -1
            }
          })
        }
      }

      return data
    }
  },
}
</script> 
