<template>
  <div>
    <div class="min-450px mr-auto ml-auto mt-10">
      <div class="">
        <WmsDateInput name="date" :caption="type==1||type==4?'点検予定日':'作業日'" v-model="inPlanDate" :min="minLimitDate" 
          @change="changePlanDate()" />
      </div>
      <div class="text-xs text-gray-600 font-bold mt-5">
        <div class="mb-1">担当者</div>
        <WmsSuggestInput
          name="staff"
          :embed="true"
          displayField="name"
          :dynamic="false"
          :incrementalSearch="false"
          :selectionItems="staffList"
          v-model="staff.name"
          @selected="staffSelected($event)"
          @change="staffChange()"
        />
      </div>
    </div>

    <div class="rounded main-height min-450px ml-auto mr-auto">

      <!-- ルートボタン -->
      <div class="text-center text-sm font-medium border-gray w-auto mt-5 ml-auto">
        <a :href="route()" target="_blank" class="cursor-pointer">
          <PrimaryButton text="ルート一括" class="bg-teal-600 hover:bg-teal-700" />
        </a>
      </div>

      <div class="toggle flex items-center mb-3">
        <span class="text-gray-500 font-bold text-xs mr-2">並べ替え</span>
        <Toggle v-model="sortable" size="small" />
      </div>

      <table class="main table-auto">
        <thead class="main">
          <tr class="border border-gray-200">
            <th v-for="(c, index) in columns" :key="'f2' + index" scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider bg-colu">
              {{ c.title }}
            </th>
          </tr>
        </thead>

        <draggable key="unPatrol" :list="schedule.list" direction="vertical" handle=".row-drag-handle" tag="tbody" @change="$emit('scheduleOrderChange')"
          class="bg-white divide-y divide-gray-200">

          <tr v-for="(row, i) in schedule.list" :key="i" class="border border-gray-200 tracking-wider" :class="rowColor(row)">

            <!-- No -->
            <td class="col-no row-drag-handle" :class="sortable?'prasp':''">
              <div class="text-center text-gray-500 ">
                {{ i + 1 }}
              </div>
            </td>
            <!-- サイクル期限 -->
            <td class="px-3 py-3 whitespace-pre-line text-sm text-gray-700">
              {{ formatDate(row.limit) }}
            </td>
            <!-- 物件名 -->
            <td class="px-3 py-3 whitespace-pre-line text-sm text-gray-700 min-150">
              {{ row.siteName }}
              <div v-if="row.ridgeCode" class="text-red-700 font-bold">
                {{ row.ridgeName }}
              </div>
            </td>
            <!-- Weight -->
            <td class="px-3 py-3 whitespace-pre-line text-sm text-gray-700 text-center col-no">
              {{ row.weight }}
            </td>
            <!-- 連日 定期清掃のみ -->
            <td v-if="type == 3&&(!row.subCount||row.subCount=='0')&&row.type==3" class="whitespace-pre-line text-sm text-gray-700 text-center cursor-pointer" @click="setMulti(row, i)">
              <div class="text-xs text-white font-bold rounded p-1 mr-1" :class="row.subDate&&row.subDate.length?'bg-indigo-50 text-indigo-700 border-indigo-700 border':'bg-indigo-700 text-white'">連日</div>
            </td>
            <!-- 何日目の作業日 定期清掃のみ -->
            <td v-else-if="type == 3&&row.subCount&&row.subCount!='0'&&row.type==3" class="whitespace-pre-line text-sm text-gray-700 text-center cursor-pointer">
              <div class="text-indigo-600 font-bold">
                {{ row.subCount }}日目
              </div>
            </td>
            <td v-else-if="type==3"></td>
            <!-- 区分 -->
            <td class="m-1 whitespace-pre-line text-xs font-bold w-30px">
              <div :class="textColor(row)">
                {{ row.typeName }}
              </div>
            </td>
            <!-- 削除ボタン -->
            <td class="px-3 py-0.5 text-center text-sm font-medium border-gray">
              <div class="flex justify-center">
                <div v-if="!row.patrolScheduleId" @click="$emit('deleteRow', i)">
                  <TrashIcon
                    class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
                </div>
              </div>
            </td>
          </tr>
        </draggable>
      </table>
      <div class="flex justify-around w-full font-bold text-lg border p-3"
        :class="totalWeight > 300 ? 'text-red-600 border-red-300' : 'text-blue-600 border-blue-300'">
        <span>{{ schedule.list.length }} 件</span>
        <span>Weight : {{ totalWeight }}</span>
      </div>
    </div>

    <div class="flex mt-10 mb-3 ml-auto mr-auto min-450px">
      <PrimaryButton text="クリア" size="normal hover:bg-red-600" class="red w-full mr-1" @click="$emit('scheduleSearchClear')" />
      <PrimaryButton text="保存" size="normal" class="blue w-full ml-1" @click="$emit('save')" />
    </div>
    <!-- 連日選択モーダル -->
    <MultiDateModal 
      v-model="openMultiDate"
      :target="multiTarget"
      :open="openMultiDate"
      @selectedMulti="selectedMulti"
      @modalClose="modalClose"
    />
  </div>
</template>

<script>
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsDateInput from '@wmscomponents/WmsDateInput.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import Toggle from '@components/Toggle.vue'
import draggable from "vuedraggable"
import { TrashIcon } from '@vue-hero-icons/solid'
import * as utils from '@libs/utils'
import * as dialogs from '@libs/dialogs'
import * as moment from 'moment'
import MultiDateModal from '../components/MultiDateModal.vue'

export default {
  components: {
    PrimaryButton,
    WmsDateInput,
    WmsSuggestInput,
    Toggle,
    draggable,
    TrashIcon,
    MultiDateModal
  },

  data() {
    return {
      inPlanDate: '',
      // 最少日
      minLimitDate: '',
      // 連日指定モーダル
      openMultiDate: false,
      // 連日対象データ
      multiTarget: {},
      // 並べ替え
      sortable: false,
    }
  },

  props: {
    // スタッフリスト
    staffList: [],
    // 区分
    type: {
      type: Number,
      default: null
    },
    // 点検予定日
    planDate: String,
    // スタッフ
    staff: {
      name: '',
      userId: null,
      patrolId: null
    },
    // スケジュール
    schedule: {
      list: []
    },
    columns: []
  },

  computed: {
    totalWeight() {
      let result = 0
      if (this.schedule.list && this.schedule.list.length) {
        for (let i = 0; i < this.schedule.list.length; i++) {
          const l = this.schedule.list[i]
          const w = Number(l.weight)
          if (w && !isNaN(w)) {
            result += Number(w)
          }
        }
      }
      return result
    }
  },

  watch: {
    /**
     * 選択した点検予定日
     */
    async planDate() {
      this.inPlanDate = this.planDate
      this.$emit('getByDate');
    }
  },

  async created() {
    this.minLimitDate = utils.getToday()
  },

  methods: {
    /**
     * 予定日変更
     */
     async changePlanDate() {
      this.$emit('changePlanDate', this.inPlanDate);
    },

    /**
     * スタッフ選択
     * @param e 選択対象
     */
    async staffSelected(e) {
      this.$emit('staffSelected', e);
    },

    /**
     * スタッフ変更
     */
    staffChange() {
      this.$emit('staffChange');
    },

    /**
     * ルート一括ボタン
     */
    route() {
      if (!this.schedule.list.length) {
        return
      } else if (this.schedule.list.length == 1) {
        return 'https://www.google.com/maps/dir/?api=1&destination=' + this.schedule.list[0].siteAddress + '&travelmode=driving&hl=ja&gl=jp'
      } else {
        let result = []
        let way = ''
        for (let i = 0; i < this.schedule.list.length; i++) {
          const l = this.schedule.list[i]
          if (l.siteAddress != '') {
            if (i != this.schedule.list.length - 1) {
              result.push(l.siteAddress)
            } else {
              way = `&waypoints=${result.join('|')}&destination=${l.siteAddress}`
            }
          }
        }
        return `https://www.google.com/maps/dir/?api=1${way}&travelmode=driving&hl=ja&gl=jp`
      }
    },

    /**
     * 日付表示
     * @param d 日付
     */
    formatDate(d) {
      return moment(d).format('YYYY/MM/DD')
    },

    /**
     * 複数日選択モーダル開く
     * @param row 対象
     * @param i index
     */
    async setMulti(row, i) {
      if (!this.planDate) {
        await dialogs.showWarningDialog('作業日が未選択です', '作業日を選択してください。')
        return
      }
      this.openMultiDate = true
      this.multiTarget = row
      this.multiTarget.selectedDate = this.planDate
      this.multiTarget.idx = i
    },

    /**
     * 連日決定イベント
     */
    selectedMulti(val) {
      this.$emit('selectedMulti', val);
    },

    /**
     * 複数日選択モーダル閉じる
     */
    modalClose() {
      this.openMultiDate = false
    },

    /**
     * 行の背景色
     * @param row  対象行
     */
    rowColor(row) {
      // 画面のtypeは背景色無し
      // if (!row.patrolScheduleId || row.type == this.type) {
      //   return 'bg-white'
      // }
      if (row.claim) {
        return 'bg-green-50'
      } else if (row.type == 1) {
        return 'bg-blue-50'
      } else if (row.type == 2) {
        return 'bg-pink-50'
      } else if (row.type == 3) {
        return 'bg-yellow-50'
      } else if (row.type == 4) {
        return 'bg-purple-50'
      }
    },

    /**
     * 文字色
     * @param row 対象行
     */
    textColor(row) {
      if (row.claim) {
        return 'text-green-700'
      } else if (row.type == 1) {
        return 'text-blue-700'
      } else if (row.type == 2) {
        return 'text-pink-700'
      } else if (row.type == 3) {
        return 'text-yellow-500'
      } else if (row.type == 4) {
        return 'text-purple-700'
      }
    }
  },
}
</script>
<style scoped>
.main {
  width: 100%;
}

.bg-colu {
  background: #ebf0fb;
}

.col-no {
  width: 40px;
}

.min-150 {
  min-width: 150px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.w-30p {
  width: 30%;
}

.min-450px {
  min-width: 450px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
