<template>
  <div>
    <div v-if="loading" class="w-full h-full flex justify-center items-center loading">
      <p class="text-gray-400 font-bold animate-pulse">Searching...</p>
    </div>

    <PageHeader title="サブタスク一覧" class="list">
      <template #title-header-content>
        <div class="flex-1 pl-2 flex ml-6 justify-end items-center">

          <div class="flex-1 mr-2">
            <label for="search" class="sr-only">Search</label>
            <div class="relative text-light-blue-100 focus-within:text-gray-400">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <Icon iconName="Search" class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              </div>
              <input 
                type="search"
                v-model="searchKeyword" 
                class="block w-full bg-light-blue-700 bg-opacity-50 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="キーワード検索"
              />
            </div>
          </div>

          <div class="ml-auto flex button-contents">
            <PrimaryButton text="検索" @click="search" :buttonContents="'要'" />
          </div>

          <!-- モバイル版 -->
          <div v-if="isMobileDevice||isTabletDevice" class="flex">
            <!-- メイン担当者絞込み -->
            <div class="ml-auto button-contents">
              <PrimaryButton text="メイン担当者がユーザー名" v-tooltip="'メイン担当者をユーザー名で絞込み'" :buttonContents="'要'" :class="filterMainTaskUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('mainTaskUser')">
              </PrimaryButton>
            </div>

            <!-- サブタスク担当者絞込み -->
            <div class="ml-auto button-contents">
              <PrimaryButton text="サブ担当者がユーザー名・未定" v-tooltip="'サブタスク担当者をユーザー名・未定で絞込み'" :buttonContents="'要'" :class="filterSubTaskUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('subTaskUser')">
              </PrimaryButton>
            </div>

            <div class="ml-auto flex button-contents">
              <PrimaryButton text="本日までの期限（サブタスク）" :class="untilTodayState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'本日までの期限（サブタスク）'" @click="untilToday()" :buttonContents="'要'">
              </PrimaryButton>
            </div>

            <div class="ml-auto flex button-contents">
              <PrimaryButton text="未完了（メイン）" :class="clickIncompleteMain ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'未完了（メイン）'" @click="selectStatusMain('open')" :buttonContents="'要'">
              </PrimaryButton>
            </div>

            <div class="ml-auto flex button-contents">
              <PrimaryButton text="未完了（サブタスク）" :class="clickIncompleteOnlyState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'未完了（サブタスク）'" @click="selectStatus('open')" :buttonContents="'要'">
              </PrimaryButton>
            </div>
          </div>

          <!-- PC版 -->
          <div v-else class="flex">
            <!-- メイン担当者絞込み -->
            <div class="ml-auto button-contents">
              <PrimaryButton  v-tooltip="'メイン担当者をユーザー名で絞込み'" :buttonContents="'要'" :class="filterMainTaskUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('mainTaskUser')">
                <Icon slot="before" iconName="User" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <!-- サブタスク担当者絞込み -->
            <div class="ml-auto button-contents">
              <PrimaryButton  v-tooltip="'サブタスク担当者をユーザー名・未定で絞込み'" :buttonContents="'要'" :class="filterSubTaskUser ? 'clickButtonColor' : 'normalButtonColor'" @click="filter('subTaskUser')">
                <Icon slot="before" iconName="UserRemove" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <div class="ml-auto flex button-contents">
              <PrimaryButton :class="untilTodayState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'本日までの期限（サブタスク）'" @click="untilToday()" :buttonContents="'要'">
                <Icon slot="before" iconName="Clock" iconType="outline" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <div class="ml-auto flex button-contents">
              <PrimaryButton :class="clickIncompleteMain ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'未完了（メイン）'" @click="selectStatusMain('open')" :buttonContents="'要'">
                <Icon slot="before" iconName="Exclamation" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>

            <div class="ml-auto flex button-contents">
              <PrimaryButton :class="clickIncompleteOnlyState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'未完了（サブタスク）'" @click="selectStatus('open')" :buttonContents="'要'">
                <Icon slot="before" iconName="ExclamationCircle" iconType="solid" :strokeWidth="2" class="h-5 w-5" />
              </PrimaryButton>
            </div>
          </div>

          <div class="ml-auto flex button-contents">
            <PrimaryButton text="受注取消済み"  :class="clickRemoveState ? 'clickButtonColor' : 'normalButtonColor'" v-tooltip="'受注取消したデータを表示します'" @click="selectRemoveStatus(!clickRemoveState)" :buttonContents="'要'" />
          </div>

          <div class="ml-auto flex button-contents">
            <PrimaryButton text="クリア" class="normalButtonColor" @click="clear" :buttonContents="'要'" />
          </div>

          <Icon 
            iconName="Refresh" 
            :clickable="false" 
            :class="{'animate-spin': loading}" 
            class="text-white hover:text-gray-300 active:text-gray-400 ml-4 cursor-pointer"
            @click.native="refresh"
          />
        </div>
      </template>
      <template #page-header-content>
        <div class="flex items-center">
          <!-- 検索欄が多くなったので、コメントアウト -->
          <!-- <h1 class="text-lg font-bold text-white flex items-center">
            <Icon iconName="Share" class="w-7 h-7 mr-2" /> <span class="mt-0">サブタスク進捗一覧</span>
          </h1> -->

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-sm">メインタスク</span>
            <Toggle v-model="mainTask" size="small" class="ml-2"/>
          </div>

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-sm">受注タスク</span>
            <Toggle v-model="orderTask" size="small" class="ml-2"/>
          </div>

          <div class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-sm">DC雇い入れタスク</span>
            <Toggle v-model="employmentTask" size="small" class="ml-2"/>
          </div>

          <div v-if="isRegularStaffAuthorized" class="flex items-center ml-5" v-tooltip="'検索開始は、検索ボタンを押してください'">
            <span class="text-gray-100 font-bold text-sm">社員雇い入れタスク</span>
            <Toggle v-model="employmentRegularTask" size="small" class="ml-2"/>
          </div>

          <div class="flex flex-1 ml-8 justify-end">
            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <DateSelect
                id="filterDate"
                v-if="true"
                ref="dateSelect"
                :presetName="limitPeriodName"
                :period="limitPeriod"
                :none="true"
                @change="periodChange"
                :titleText="limitPeriod.start?'':'期限（メイン）'"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div id="request-no" class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="requestNo" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="お問い合わせ番号 (メイン)"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="inquiry_title" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="タイトル（メイン）"
                v-tooltip="'カッコは無視して検索します'"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="toStaffName" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="担当者（メインorサブ）"
                v-tooltip="'カッコは無視して検索します'"
              />
            </div>

            <label for="search" class="sr-only">Search</label>
            <div class="relative focus-within:text-gray-400 search-contents">
              <input 
                type="search"
                v-model="category" 
                class="block w-full py-2 pr-3 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                placeholder="お問い合わせカテゴリー"
              />
            </div>

            <div class="relative focus-within:text-gray-400 search-contents">
              <WmsSuggestInput
                name="toDepartment" 
                :dynamic="false"
                :incrementalSearch="false"
                :filter="{}"
                :selectionItems="toDepartmentList"
                displayField="name"
                placeholder="部署（サブ）"
                v-model="toDepartment"
                :clearable="true"
                class="p-0.5 block w-full border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-white focus:border-white focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm"
                :embed="true"
                id="toDepartment"
              />
            </div>
          </div>
        </div>       
      </template>
    </PageHeader>

    <main class="absolute top-32 w-screen bottom-4 -mt-2 list2">
      <div class="mx-auto px-2 sm:px-4 lg:px-8 h-full">
        <div class="bg-white rounded-md border border-gray-200 ---overflow-auto h-full">

          <RequestPostProgressTable 
            v-show="!loading && searchResults.length > 0" 
            ref="requestPostProgressTable" 
            :searchKeyword="searchConditionReset.searchKeyword" 
            :searchCategory="searchConditionReset.category"
            :searchToStaffName="searchConditionReset.toStaffName"
            :searchRequestNo="searchConditionReset.requestNo"
            :searchInquiry_title="searchConditionReset.inquiry_title"
            :searchLimitPeriod="searchConditionReset.limitPeriod"
            :searchStatus="searchCondition.searchStatus"
            :searchMainStatus="searchCondition.searchMainStatus"
            :searchToDepartment="searchConditionReset.toDepartment"
            :sortItem="searchConditionReset.sortItem"
            :sortBy="searchConditionReset.sortBy"
            :category2Company="category2Company"
            :category2Customer="category2Customer"
            :category2CustomerDirect="category2CustomerDirect"
            :period="period" 
            :freeze="freeze"
            :mainTask="mainTask"
            :orderTask="orderTask"
            :employmentTask="employmentTask"
            :employmentRegularTask="employmentRegularTask"
            :filterMainTaskUser="filterMainTaskUser"
            :filterSubTaskUser="filterSubTaskUser"
            @search-start="searchStart" 
            @search-end="searchEnd"
            @change-sort="changeSort"
          />
          
          <div v-if="!loading && !initial && overData" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="ExclamationCircle"
              message="対象のメインのデータが多いため、期限で絞り込みをしてください。"
            />
          </div>
          <div v-else-if="!loading && !initial && searchResults.length === 0" class="w-full h-full flex justify-center items-center text-gray-400">
            <EmptyMessage
              iconName="Document"
              message="該当する案件はありません。"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Icon from '@components/Icon.vue'
import PageHeader from '@components/PageHeader.vue'
import RequestPostProgressTable from '../components/RequestPostProgressTable.vue'
import DateSelect from '@components/DateSelect.vue'
import EmptyMessage from '@components/EmptyMessage.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import * as utils from '@libs/utils'
import Toggle from '@components/Toggle.vue'
import * as backend from '@libs/backend'

const sessionStorageItemNameReset = 'requestPostProgressListConditionReset'
const sessionStorageItemName = 'requestPostProgressListCondition'
const sessionMainTask = 'progressListSessionMainTask'
const sessionOrderTask = 'progressListSessionOrderTask'
const sessionEmploymentTask = 'progressListSessionEmploymentTask'
const sessionEmploymentRegularTask = 'progressListSessionEmploymentRegularTask'
const sessionFilterMainTaskUser = 'sessionFilterMainTaskUser'
const sessionFilterSubTaskUser = 'sessionFilterSubTaskUser'

const startDate = '2022-10-01'

export default {
  components: {
    Icon,
    RequestPostProgressTable,
    DateSelect,
    PageHeader,
    EmptyMessage,
    PrimaryButton,
    WmsSuggestInput,
    Toggle
  },

  data() {
    return {
      searchKeyword: '',
      searchCategory2: '',
      period: {
        start: startDate
      },
      searchResults: [],
      loading: false,
      initial: true,
      freeze: true,
      radioCategory2: '',
      category2Company: true,
      category2Customer: true,
      category2CustomerDirect: true,
      filterMainTaskUser: true,
      filterSubTaskUser: true,
      untilTodayState: false,
      // 未完了（サブ）
      clickIncompleteOnlyState: false,
      // 未完了（メイン）
      clickIncompleteMain: false,
      // 受注取消を表示
      clickRemoveState: false,
      // メニュー画面に戻ったら検索条件リセット
      searchConditionReset: {
        searchKeyword : '',
        limitPeriodName: 'custom',
        limitPeriod: {},
        requestNo: '',
        inquiry_title : '', 
        category : '',
        toStaffName : '',
        toDepartment: '',
        sortItem : 'createDate',
        sortBy: -1
      },
      // メニュー画面戻ってもリセットされない
      searchCondition: {
        searchStatus: '',
        searchMainStatus: ''
      },
      mainTask: true,
      orderTask: true,
      employmentTask: true,
      employmentRegularTask: false,
      limitPeriodName: 'custom',
      limitPeriod: {},
      requestNo: '',
      inquiry_title: '',
      toStaffName: '',
      category: '',
      searchStatus: '',
      // 部署検索リスト
      toDepartmentList: [],
      // 部署検索
      toDepartment: '',
      // データが多すぎて表示不可
      overData: false,
      // 社員雇い入れ切り替えタブ表示
      isRegularStaffAuthorized: false
    }
  },

  watch: {
    mainTask() {
      sessionStorage.setItem(sessionMainTask, this.mainTask)
    },

    orderTask() {
      sessionStorage.setItem(sessionOrderTask, this.orderTask)
    },

    employmentTask() {
      sessionStorage.setItem(sessionEmploymentTask, this.employmentTask)
    },

    employmentRegularTask() {
      sessionStorage.setItem(sessionEmploymentRegularTask, this.employmentRegularTask)
    }
  },

  computed: {
    // state() {
    //   return this.$store.getters.requestPostListState
    // },

    appVersion() {
      return this.$store.getters.appVersion
    },

    isMobileDevice() {
      return utils.deviceInfo.isMobile
    },

    isTabletDevice() {
      return utils.deviceInfo.isTablet
    },
  },

  created() {
    console.log('RequestPostListPage created.')
    this.freeze = true
    this.getRegularStaffAuthorized()
    this.loadCondition()
  },

  mounted() {
    console.log('RequestPostListPage mounted.')
    this.loadState()
    this.$nextTick(() => {
      this.freeze = false
      this.refresh()
    })  
  },

  methods: {
    // saveState() {
    //   console.log('saveState')

    //   const newState = {}
    //   newState.searchKeyword = this.searchConditionReset.searchKeyword
    //   newState.limitPeriodName = this.limitPeriodName
    //   newState.limitPeriod = this.limitPeriod ? { start: this.limitPeriod.start, end: this.limitPeriod.end } : null
    //   newState.period = this.period ? { start: this.period.start, end: this.period.end } : null
    //   this.$store.dispatch('setRequestPostListState', newState)
    // },

    loadState() {
      // const state = this.state
      // if (state) {
      //   this.searchConditionReset.searchKeyword = state.searchKeyword
      //   this.limitPeriodName = state.limitPeriodName
      //   this.limitPeriod = state.limitPeriod ? { start: state.limitPeriod.start, end: state.limitPeriod.end } : null
      //   this.period = state.period ? { start: state.period.start, end: state.period.end } : null
      // }

      // 未完了（サブ）
      if (!this.searchCondition.searchStatus && this.searchCondition.clickIncompleteOnlyState == false) {
        // 未完了のみボタンが押されていない場合
        this.clickIncompleteOnlyState = this.searchCondition.clickIncompleteOnlyState
      } else if (this.searchCondition.searchStatus) {
        // 未完了のみボタンが押されている場合
        this.selectStatus(this.searchCondition.searchStatus)
      } else {
        // 未完了のみボタン 初期値
        this.selectStatus('open')
        this.searchCondition.searchStatus = 'open'
      }

      // 未完了（メイン）
      if (!this.searchCondition.searchMainStatus && this.searchCondition.clickIncompleteMain == false) {
        // 未完了のみボタンが押されていない場合
        this.clickIncompleteMain = this.searchCondition.clickIncompleteMain
      } else if (this.searchCondition.searchMainStatus) {
        // 未完了のみボタンが押されている場合
        this.selectStatusMain(this.searchCondition.searchMainStatus)
      } else {
        // 未完了のみボタン 初期値
        this.selectStatusMain('open')
        this.searchCondition.searchMainStatus = 'open'
      }

      // 受注取消ボタン
      if (this.searchCondition.clickRemoveState) {
        this.clickRemoveState = this.searchCondition.clickRemoveState
        this.selectRemoveStatus(true)
      }

      if (this.searchCondition.untilTodayState) {
        // 当日のみボタンが押されている場合
        this.untilToday()
      }

      this.searchKeyword = this.searchConditionReset.searchKeyword
      this.limitPeriodName = this.searchConditionReset.limitPeriodName || 'custom'
      this.limitPeriod = this.searchConditionReset.limitPeriod || {}
      this.requestNo = this.searchConditionReset.requestNo
      this.inquiry_title = this.searchConditionReset.inquiry_title
      this.toStaffName = this.searchConditionReset.toStaffName
      this.category = this.searchConditionReset.category
      // 部署検索
      if (this.searchConditionReset.toDepartment) {
        this.toDepartment = this.searchConditionReset.toDepartment
      } else {
        this.toDepartment = ''
      }
      
      // カレンダーに反映
      this.$refs.dateSelect.setRange(this.limitPeriod, this.limitPeriodName, true)

      // 部署検索リスト生成
      let departmentList = this.$store.getters.departmentList
      let transDepartmentList = this.$store.getters.transDepartmentList
      this.toDepartmentList = [ ...departmentList, ...transDepartmentList ]
    },

    loadCondition() {
      // 見積りから遷移した場合の検索設定
      this.fromEstimateView()

      const searchJsonReset = sessionStorage.getItem(sessionStorageItemNameReset)
      const searchJson = sessionStorage.getItem(sessionStorageItemName)
      if(searchJsonReset){
        this.searchConditionReset = JSON.parse(searchJsonReset)
      }

      if(searchJson){
        this.searchCondition = JSON.parse(searchJson)
      }

      if (sessionStorage.getItem(sessionMainTask) == 'false') {
        this.mainTask = false
      } else {
        this.mainTask = true
      }
      
      if (sessionStorage.getItem(sessionOrderTask) == 'false') {
        this.orderTask = false
      } else {
        this.orderTask = true
      }

      if (sessionStorage.getItem(sessionEmploymentTask) == 'false') {
        this.employmentTask = false
      } else {
        this.employmentTask = true
      }

      if (!sessionStorage.getItem(sessionEmploymentRegularTask) || sessionStorage.getItem(sessionEmploymentRegularTask) == 'false') {
        this.employmentRegularTask = false
      } else {
        this.employmentRegularTask = true
      }

      // メイン担当者
      if (sessionStorage.getItem(sessionFilterMainTaskUser) == 'false') {
        this.filterMainTaskUser = false
      } else {
        this.filterMainTaskUser = true
      }

      // サブタスク担当者
      if (sessionStorage.getItem(sessionFilterSubTaskUser) == 'false') {
        this.filterSubTaskUser = false
      } else {
        this.filterSubTaskUser = true
      }
    },

    async refresh() {
      this.loading = true
      await utils.wait(500)
      await this.$refs.requestPostProgressTable.fetchRequestPosts()
      await utils.wait(500)
      this.loading = false
    },

    /**
     * 検索
     */
    searchStart() {
      this.loading = true
    },

    searchEnd(data) {
      // this.saveState()

      const self = this
      self.initial = false
      self.loading = false
      self.searchResults = data.res
      self.overData = data.overData
    },

    /**
     * 絞込み
     * @param target 絞込み対象
     */
    filter(target) {
      if (target == 'mainTaskUser') {
        this.filterMainTaskUser = !this.filterMainTaskUser
      } else if (target == 'subTaskUser') {
        this.filterSubTaskUser = !this.filterSubTaskUser
      }
    },

    // 当日のみボタン 押下
    untilToday() {
      if(!this.untilTodayState) {
        let today = utils.formatDateString(new Date(), '{yyyy}-{MM}-{dd}')
        this.period = { end: today }
      } else {
        this.period = { start: startDate }
      }
      // console.log(this.period)
      // this.$refs.dateSelect.setRange(this.period)

      //ボタンの色を変更
      this.untilTodayState = !this.untilTodayState
    },

    /**
     * 未完了(サブ)ボタン
     */
    selectStatus(status) {
      //ボタンの色を変更
      this.clickIncompleteOnlyState = !this.clickIncompleteOnlyState
      if (this.clickIncompleteOnlyState) {
        this.searchStatus = status
        this.clickRemoveState = false
      } else {
        this.searchStatus = ''
      }
    },

    /**
     * 未完了（メイン）ボタン
     */
    selectStatusMain(status) {
      //ボタンの色を変更
      this.clickIncompleteMain = !this.clickIncompleteMain
      if (this.clickIncompleteMain) {
        this.searchMainStatus = status
        this.clickRemoveState = false
      } else {
        this.searchMainStatus = ''
      }
    },

    /**
     * 受注取消表示ボタン
     */
    selectRemoveStatus(remove) {
      // ONの場合
      if (remove) {
        this.clickRemoveState = true
        this.searchMainStatus = 'remove'
        this.searchStatus = ''
        // 未完了ボタン（メイン、サブ両方）をグレーにする
        this.clickIncompleteMain = false
        this.clickIncompleteOnlyState = false
        // 受注タスクは、必ず表示にする
        this.orderTask = true
        // 削除された張り紙タスクを取得する可能性があるのでOFF
        this.mainTask = false
        this.employmentTask = false
        this.employmentRegularTask = false
      // OFFの場合
      } else {
        this.clickRemoveState = false
        // 未完了ボタン（メイン、サブ両方）をONにする
        this.clickIncompleteMain = false
        this.clickIncompleteOnlyState = false
        this.selectStatus('open')
        this.selectStatusMain('open')
      }
    },

    // ソート change イベント
    async changeSort(data) {
      this.searchConditionReset.sortItem = data.item
      this.searchConditionReset.sortBy = data.by
      // セッションストレージに保存
      this.setSessionStorage(sessionStorageItemNameReset, this.searchConditionReset)
    },

    // 検索条件をクリア
    clear() {
      window.sessionStorage.clear()
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    },

    /**
     * 検索条件をセッションストレージへ保存
     */
    setSessionStorage(name, searchCondition) {
      const searchStr = JSON.stringify(searchCondition)
      sessionStorage.setItem(name, searchStr)
    },

    /**
     * 検索ボタン 押下
     */
    search() {
      // メニュー画面に戻ったらリセットされる
      this.searchConditionReset.searchKeyword = this.searchKeyword
      this.searchConditionReset.limitPeriodName = this.limitPeriodName
      this.searchConditionReset.limitPeriod = this.limitPeriod
      this.searchConditionReset.requestNo = this.requestNo
      this.searchConditionReset.inquiry_title = this.inquiry_title
      this.searchConditionReset.toStaffName = this.toStaffName
      this.searchConditionReset.category = this.category
      this.searchConditionReset.toDepartment = this.toDepartment

      // メニュー画面に戻ってもリセットされない
      this.searchCondition.untilTodayState = this.untilTodayState
      this.searchCondition.clickIncompleteOnlyState = this.clickIncompleteOnlyState
      this.searchCondition.searchStatus = this.searchStatus
      this.searchCondition.clickIncompleteMain = this.clickIncompleteMain
      this.searchCondition.searchMainStatus = this.searchMainStatus
      this.searchCondition.clickRemoveState = this.clickRemoveState

      // セッションストレージに検索条件を保存
      this.setSessionStorage(sessionStorageItemNameReset, this.searchConditionReset)
      this.setSessionStorage(sessionStorageItemName, this.searchCondition)
      sessionStorage.setItem(sessionFilterMainTaskUser, this.filterMainTaskUser)
      sessionStorage.setItem(sessionFilterSubTaskUser, this.filterSubTaskUser)
      this.$nextTick(function() {
        this.$refs.requestPostProgressTable.fetchRequestPosts()
      })
    },

    /**
     * 期限（メイン）検索 変更
     */
    periodChange(range, presetName) {
      this.limitPeriod = range
      this.limitPeriodName = presetName
    },

    /**
     * 見積りから遷移した場合の検索
     */
    fromEstimateView() {
      // 見積参照から遷移した場合
      if (this.$route.query.requestNo) {
        // 検索履歴をクリア
        window.sessionStorage.clear()
        // 問い合わせNoで検索をかける
        this.requestNo = this.$route.query.requestNo
        this.searchConditionReset.requestNo = this.$route.query.requestNo

        // メイン担当者絞り込み解除
        this.filterMainTaskUser = false
        // サブ担当者絞り込み解除
        this.filterSubTaskUser = false

        // 当日のみを解除(活性にし、非活性にする)
        this.untilTodayState = true
        this.untilToday()
        this.searchCondition.untilTodayState = this.untilTodayState

        // 未完了（メイン）を解除
        this.clickIncompleteMain = false
        this.searchCondition.clickIncompleteMain = false
        this.searchMainStatus = ''
        this.searchCondition.searchMainStatus = ''

        // 未完了（サブ）を解除
        this.clickIncompleteOnlyState = false
        this.searchCondition.clickIncompleteOnlyState = false
        this.searchStatus = ''
        this.searchCondition.searchStatus = ''

        // 受注取消を解除
        this.clickRemoveState = false
        this.searchCondition.clickRemoveState = false

        // セッションストレージに検索条件を保存
        sessionStorage.setItem(sessionFilterMainTaskUser, this.filterMainTaskUser)
        sessionStorage.setItem(sessionFilterSubTaskUser, this.filterSubTaskUser)
        this.setSessionStorage(sessionStorageItemNameReset, this.searchConditionReset)
        this.setSessionStorage(sessionStorageItemName, this.searchCondition)
      }
    },

    /**
     * 社員雇い入れ切り替えタブを表示
     */
    async getRegularStaffAuthorized() {
      // 管理者ユーザー、大野様、ログインユーザーの所属部署（兼任も含めて）が人事部、所属部署課長クラスなら、true
      const isEmpRes = await backend.searchData('user/getDepartment', { user: this.$store.getters.user.user_name })
      const isEmployment = utils.isRegularStaffAuthorized(this.$store.getters.user.id, isEmpRes)
      const departmentArr = utils.departmentArr(isEmpRes)
      if (isEmployment || (departmentArr && departmentArr.length)) {
        this.isRegularStaffAuthorized = true
      } else {
        this.isRegularStaffAuthorized = false
      }
    }
  },
}
</script>

<style scoped>
  .list {
    min-width: 1200px;
  }

  #toDepartment >>> input[type=text] {
    height: 39px;
    margin-top: -2px;
    border-radius: 0.375rem !important;
  }
  
  #filterDate {
    /* max-width: 240px !important; */
    width: auto !important;
  }

  #filterDate >>> div {
    color: rgb(70, 70, 70) !important;
  }

  #filterDate >>> input[type=text] {
    background: white !important;
    /* text-align: right; */
  }

  #request-no >>> input[type=search]::placeholder {
    font-size: small;
  }
</style>