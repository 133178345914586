<template>
    <div>
        <div class="detail overflow-hidden border border-gray-300 rounded">
            <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead>
                    <tr class="divide-x divide-gray-200">
                        <th v-for="(c, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                            {{c.title}}
                        </th>
                    </tr>
                </thead>

                    <tr v-for="(row, i) in commission" :key="i" class="divide-y divide-x divide-gray-200 bg-white tracking-wider">

                        <!-- No -->
                        <td class="b-top text-center px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900 col-no cursor-not-allowed">
                            {{ i + 1 }}
                        </td>

                        <!-- カテゴリー名 -->
                        <td class="px-4 py-2 whitespace-pre-line break-words text-sm text-gray-500 w-80 cursor-not-allowed">
                            {{ getNewLine(row.categoryName) }}
                        </td>
                        
                        <!-- 受注金額 -->
                        <td class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            {{ toLocale(row.estimatePrice) }}
                        </td>
                        
                        <!-- 手数料率 -->
                        <td v-if="allDisabled" class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            {{ row.rate }}
                        </td>
                        <td v-else class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-70 numeric-right">
                            <WmsSuggestInput
                                name="rate"
                                :embed="true"
                                :dynamic="false"
                                displayField="rate"
                                :incrementalSearch="false"
                                :selectionItems="rateList"
                                class="embed"
                                v-model="row.rate"
                                @selected="rateSelected"
                                @blur="rateBlur"
                            />
                        </td>

                        <!-- 手数料 -->
                        <td v-if="allDisabled" class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            {{ toLocale(row.commissionPrice) }}
                        </td>
                        <td v-else class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-70">
                            <NumericTextInput
                                name="commissionPrice"
                                v-model="row.commissionPrice"
                                class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                                @change="changeCommissionPrice()"
                            />
                        </td>

                        <!-- 手数料込 -->
                        <td class="text-right px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-200 cursor-not-allowed">
                            {{ toLocale(row.price) }}
                        </td>
                        <!-- <td v-else class="px-2 py-2 whitespace-nowrap text-sm text-gray-700 min-70">
                            <NumericTextInput
                                name="price"
                                v-model="row.price"
                                class="text-right w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-transparent" 
                            />
                        </td> -->

                    </tr>
            </table>
        </div>
        <div class="text-xs text-transparent">
            {{ commissionReload }}
        </div>
    </div>
    
</template>

<script>
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import NumericTextInput from '@components/NumericTextInput.vue'
import * as utils from '@libs/utils'

export default {
    components: {
        WmsSuggestInput,
        NumericTextInput
    },

    props: ['commission', 'allDisabled', 'commissionReload'],

    data() {
        return {
            // カラム
            columns: [
                {title: 'No.'},
                {title: 'カテゴリー'},
                {title: 'アポロ管財'},
                {title: '手数料率（%）'},
                {title: '手数料額'},
                {title: '手数料込'},
            ],
            // 率リスト
            rateList: [
                {rate: '10'}
            ]
        }
    },

    computed: {
        /**
         * 改行判定
         */
        getNewLine() {
            return (val) => {
                return utils.replaceNewLine(val)
            }
        }
    },

    methods: {

        /**
         * 手数料率選択イベント
         */
        rateSelected(val) {
            // 手数料率から金額を算出
            this.$emit('rateSelected', val.rate)
        },
        
        /**
         * 手数料率変更イベント
         */
        rateBlur() {
            // 手数料率から金額を算出
            this.$emit('rateBlur')
        },

        /**
         * 手数料変更イベント
         */
        changeCommissionPrice() {
            // 手数料から率を算出
            this.$emit('changeCommissionPrice')
        },

        /**
         * 3桁区切りの表示
         * @param value 値
         */
        toLocale(value) {
            if (value && value != '') {
                return Number(value).toLocaleString()
            } else {
                return ''
            }
        },
    }
}
</script>
<style scoped>
.col-no {
    width: 70px;  
}

.min-200 {
    min-width: 200px;
}

.min-70 {
    min-width: 70px;
}

.b-top {
    border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
    background: #fbeceb;
}

.numeric-right >>> input[type=text] {
    text-align: right !important;
}

@media screen and (max-width: 1200px) {
    .detail {
        overflow: auto;
    }
}
</style>