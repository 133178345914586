<template>
<!-- 履歴ボタンがあるため、このモーダルは残す -->
  <div>
    <Modal v-bind="$attrs" v-on="$listeners" @closed="closeModal">
      <div v-if="processing" class="flex justify-center items-center loading-dialog2">
        <p class="text-gray-600 font-bold animate-pulse" style="font-size: 50px">{{ processingText }}</p>
      </div>
      <template #action>
        <div class="content-container-app overflow-auto">
          <div class="text-lg text-blue-800 font-bold flex">
            <div>点検表</div>
            <div class="ml-auto mr-8">{{ point }} / {{ totalPoint }} 点</div>
            <div>
              <PrimaryButton text="履歴" size="normal " class="w-full mr-1 bg-orange" @click="openHistory()" />
            </div>
          </div>

          <div class="mt-5 ml-12 mr-12 mb-12">

            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold mr-auto info1">
                得意先 : {{ list.clientName1 }}
                <div v-if="list.clientName2 && list.clientName2 != ''" class="font-normal ml-20 text-xs text-gray">（{{ list.clientName2 }}）</div>
              </div>
              <div class="text-gray-600 font-bold info2">
                物件名 : {{ list.siteName }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                巡回日 : {{ list.today }}
              </div>
              <div class="text-gray-600 font-bold info2">
                巡回時間 : <span v-if="start!=''">{{ start }} ～ {{ end }}</span>
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold info1">
                巡回指導員 : {{ list.staff }}
              </div>
              <div class="text-gray-600 font-bold info2">
                指導員講習受講番号 : {{ list.patrolId }}
              </div>
            </div>
            <div class="w-11/12 mr-auto mt-5 info-group">
              <div class="text-gray-600 font-bold w-full">
                位置情報 : {{ location }}
              </div>
            </div>
          </div>

          <div v-for="(row, i) in checkList.items" :key="'r'+i" class="w-11/12 ml-auto mr-auto">
            <div v-if="!row.complete && !row.disabled && !toggleNA">
              <!-- No -->
              <div class="flex">
                <div v-if="row.sortNo>0" class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                <div v-else class="w-8 text-blue-700 font-bold">◆</div>
                <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
              </div>
              <!-- 項目 -->
              <div class="ml-8 mb-1 whitespace-pre-line">
                {{ row.item }}
              </div>
            </div>

            <!-- 非表示の該当なしを表示した場合 -->
            <div v-if="row.value=='N/A' && row.disabled && toggleNA">
              <!-- No -->
              <div class="flex">
                <div v-if="row.sortNo>0" class="w-8 text-blue-700 font-bold">{{ row.sortNo }}.</div>
                <div v-else class="w-8 text-blue-700 font-bold">◆</div>
                <div class="text-blue-700 font-bold ">{{ row.classification }}</div>
              </div>
              <!-- 項目 -->
              <div class="ml-8 mb-1 whitespace-pre-line">
                {{ row.item }}
              </div>
            </div>

            <div v-if="!row.complete && !row.disabled && !toggleNA && row.sortNo>0">
              <!-- 点数 -->
              <div class="ml-8 flex">
                <PrimaryButton text="指摘多数" size="normal" class="w-full mr-2" :class="row.value!='0'?'bg-gray-400':''" :disabled="true" @click="setPoint('0', i)" />
                <PrimaryButton text="指摘あり" size="normal" class="w-full mr-2" :class="row.value!='1'?'bg-gray-400':''" :disabled="true" @click="setPoint('1', i)" />
                <PrimaryButton text="良好" size="normal" class="w-full mr-2" :class="row.value!='2'?'bg-gray-400':''" :disabled="true" @click="setPoint('2', i)" />
                <PrimaryButton v-if="canEdit" text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" @click="setPoint('N/A', i)" />
                <PrimaryButton v-if="!canEdit" text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
              </div>
            </div>

            <!-- ◆確認事項 -->
            <div class="ml-auto " v-else-if="row.sortNo <= -1 && row.classification.indexOf('確認事項')>=0">  
              <div class="flex justify-end items-end">
                <div v-if="row.value=='N/A'" class="text-gray-700  mr-4 w-full ml-32p sm:text-sm border p-1.5 border-gray-300 rounded whitespace-pre-line">
                  {{ row.note }}
                </div>
                <PrimaryButton v-if="row.value=='N/A'" text="該当なし" size="normal" class="w-28 bg-teal-700 mr-2" :disabled="true" />
              </div>
            </div>

            <!-- ◆の項目 -->
            <div v-else-if="!row.complete && !row.disabled && !toggleNA && row.sortNo<=-1">
              <!-- 点数 -->
              <div class="ml-8 flex">
                <PrimaryButton v-if="row.classification.indexOf('指定報告書')>=0" text="未設置" size="normal" class="w-full mr-2" :class="row.value!='0'?'bg-gray-400':''" :disabled="true" @click="setPoint('0', i)" />
                <PrimaryButton text="指摘あり" size="normal" class="w-full mr-2" :class="row.value!='1'?'bg-gray-400':''" :disabled="true" @click="setPoint('1', i)" />
                <PrimaryButton text="良好" size="normal" class="w-full mr-2" :class="row.value!='2'?'bg-gray-400':''" :disabled="true" @click="setPoint('2', i)" />
                <PrimaryButton v-if="canEdit" text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" @click="setPoint('N/A', i)" />
                <PrimaryButton v-if="!canEdit" text="該当なし" size="normal" class="w-full" :class="row.value!='N/A'?'bg-gray-400 mr-2':'bg-teal-700 mr-2'" :disabled="true" />
              </div>
            </div>

            <!-- 指摘事項 -->
            <div v-if="(row.value=='0'||row.value=='1')" class="text-gray-700 mt-3 w-96pa ml-32p sm:text-sm border p-1 border-gray-300 rounded whitespace-pre-line">
              {{ row.note }}
            </div>

            <div v-if="!row.complete && !row.disabled && !toggleNA">
              <!-- 添付画像 -->
              <div class="ml-8 mt-3 flex">
                <div v-for="(p, j) in checkList.items[i].photo" :key="'p'+j" class="flex mr-3">
                  <a :href="fullImage" :data-lightbox="i * 100 + j" @click="clickImage(checkList.items[i].sortNo, i, j)">
                    <img :src="p" width="300">
                  </a>
                </div>
              </div>
              <div class="text-transparent">{{reload}}</div>
            </div>
          </div>

          <table v-if="!toggleNA" class="w-11/12 table-auto ml-auto mr-auto mt-3">
            <thead class="bg-blue-100">
              <tr class="border border-gray-200">
                <th v-for="(c, index) in columns" :key="'c'+index" scope="col" class="border border-gray-200 py-1 text-center text-xs font-medium text-gray-500 tracking-wider bg-colu">
                  {{c.title}}
                </th>
              </tr>
            </thead>

            <tr v-for="(row, i) in checkList.note" :key="'n'+i" class="border border-gray-200 bg-white tracking-wider">
              <!-- 時期 -->
              <td :rowspan="noteLength" v-if="i==noteFirst"  class="cursor-pointer hover-color border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                今回
              </td>
              <!-- No -->
              <td v-if="row.num>-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 cursor-not-allowed">
                <div>
                  {{ row.num }}
                </div>
              </td>
              <!-- 内容 -->
              <td v-if="row.num>-1" class="px-1 py-1 whitespace-pre-line text-sm min-200">
                {{ row.note }}
              </td>
            </tr>
            <div></div>
            <tr v-for="(row, i) in checkList.lastNote" :key="'ln'+i" class="border border-gray-200 bg-white tracking-wider">
              <!-- 時期 -->
              <td :rowspan="lastNoteLength" v-if="i==lastNoteFirst" class="border border-gray-200 text-center py-1 whitespace-pre-line text-sm text-gray-700 w-24">
                前回
              </td>
              <!-- No -->
              <td v-if="row.num>-1" class="px-3 py-1 border border-gray-200 text-right col-no h-10 text-red-700">
                {{ row.num }}
              </td>
              <!-- 内容 -->
              <td  v-if="row.num>-1" class="px-1 py-1 whitespace-pre-line text-sm min-200 h-10 text-red-700">
                {{ row.note }}
              </td>
              <td v-if="row.num>-1">
              </td>
            </tr>
          </table>
          <div v-if="!toggleNA" class="mt-3 w-11/12 m-auto mb-6">
            <div class="text-gray-700 text-sm">備考</div>
            <WmsTextInput 
              name="checkListMemo" 
              :multiline="true"
              :rows="memoCount"
              class="text-gray-700"
              v-model="checkList.memo"
              :disabled="true"
            />
          </div>
        </div>
      </template>
    </Modal>
    <!-- 履歴 -->
    <CheckListHistoryModal 
      v-model="historyModal"
      :target="target"
      :type="type"
      :claim="claim"
      :open="openHistoryModal"
      @modalClose="checkListHistoryModalClose"
    />
  </div>
</template>
<script>

import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import CheckListHistoryModal from '../components/CheckListHistoryModal.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import * as backend from '@libs/backend'
import moment from 'moment'

export default {
  components: {
    Modal,
    PrimaryButton,
    CheckListHistoryModal,
    WmsTextInput,
  },

  props: {
    target: {
      type: Object
    },
    type: {
      type: Number,
      default: null
    },
    // クレームか否か
    claim: {
      type: Boolean,
      default: false
    },
    open: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      // 表示データ
      list: [],
      // 開始時間
      start: '',
      // 終了時間
      end: '',
      // チェック項目
      checkList: {
        items: [
          {
            // 区分
            classification: '',
            // 内容
            item: '',
            // 結果
            value: '',
            // 該当なし履歴
            valueNA: false,
            // 該当なし非表示
            disabled: false,
            // 写真
            photo: [],
            // 完了ボタン
            complete: false
          }
        ],
        
        note:[
          {id: null, checklistId: null, num: '', note: ''}
        ],
        lastNote:[
          {id: null, checklistId: null, num: '', note: ''}
        ],
        memo: ''
      },
      // 得点
      point: 0,
      totalPoint: 0,
      // カラム
      columns: [
          {title: '時期'},
          {title: 'No.'},
          {title: '指摘事項および指導内容'},
          {title: ''}
      ],
      // 位置情報
      location: '',
      // 点検表マスタ設定モーダル
      openCheckListSettingModal: false,
      openModal: 0,
      // 並べ替え
      sortable: false,
      // 履歴モーダル
      historyModal: false,
      openHistoryModal: 0,
      // 編集できるか
      canEdit: true,
      // 備考の大きさ
      memoCount: 5,
      // 処理中
      processing: false,
      processingText: 'Loading...',
      // 写真更新
      reload: 0,
      // 点検完了日当日か
      isToday: false,
      // 完了ボタンが押されたか
      completeBtn: false,
      // 非表示の該当なしトグル
      toggleNA: false,
      // 保存したデータ
      registeredItem: [],
      // 添付画像 オリジナルデータ
      fullImage: '',
      // 全添付画像 オリジナルデータ
      fullImages: null,
    }
  },

  computed: {

    /**
     * 今回の特記事項の長さ（確認事項以外）
     */
    noteLength() {
      let length = 1

      if (this.checkList.note && this.checkList.note.length) {
        // 確認事項以外の項目の数を取得
        let leng = this.checkList.note.filter((l) => {
          return l.num > -1
        })
        if (leng) {
          length = leng.length
        }
      }
      return length || 1
    },

    /**
     * 今回の特記事項の時期を表示するインデックス
     */
    noteFirst() {
      if (this.checkList.note && this.checkList.note.length) {
        for (let i = 0; i < this.checkList.note.length; i++) {
          const n = this.checkList.note[i]
          if (n.num > -1) {
            return i
          }
        }
      }
      return 0
    },

    /**
     * 前回の特記事項の長さ（確認事項以外）
     */
    lastNoteLength() {
      let length = 1

      if (this.checkList.lastNote && this.checkList.lastNote.length) {
        // 確認事項以外の項目の数を取得
        let leng = this.checkList.lastNote.filter((l) => {
          return l.num > -1
        })
        if (leng) {
          length = leng.length
        }
      }
      return length || 1
    },

    /**
     * 前回の特記事項の時期を表示するインデックス
     */
    lastNoteFirst() {
      if (this.checkList.lastNote && this.checkList.lastNote.length) {
        for (let i = 0; i < this.checkList.lastNote.length; i++) {
          const n = this.checkList.lastNote[i]
          if (n.num > -1) {
            return i
          }
        }
      }
      return 0
    },
  },

  watch: {
    async open() {
      await this.getTarget()
    },
  },

  methods: {
    /**
     * 表示するデータ取得
     */
    async getTarget() {
      if (this.target && Object.keys(this.target).length) {
        this.processing = true
        this.processingText = 'Loading...'

        this.list = this.target
        this.list.today = moment().format('YYYY/MM/DD')

        // 巡回点検済み
        if (this.target.completionDate && this.target.completionDate != '') {
          this.canEdit = false
          this.list.today = moment(this.target.completionDate).format('YYYY/MM/DD')
          if (moment().format('YYYY/MM/DD') == this.list.today) {
            this.isToday = true
          } else {
            this.isToday = false
          }
          this.memoCount = 1

          // データ取得
          let scheduledDate = moment().format('YYYY-MM-DD')
          // 完了している場合
          if (this.target.completionDate) {
            scheduledDate = moment(this.target.completionDate).format('YYYY-MM-DD')
          }
          const compR = await backend.searchData('patrolResult/getByPatrolScheduleId', { patrolScheduleId: this.target.id, clientCode: this.target.clientCode, siteCode: this.target.siteCode, scheduledDate, type: this.type, claim: this.claim })
          if (compR.data && compR.data.data) {
            this.setData(compR.data.data)
            // 添付画像 取得
            this.getfullImages()
          }
        }
        this.processing = false
      }
    },

    /**
     * 保存している結果をセット
     */
    setData(r) {
      if (r.result.length) {
        let result = r.result[0]
        this.start = result.startTime
        if (result.endTime) {
          this.end = result.endTime
        } else {
          this.end = ''
        }
        this.location = result.location
        this.point = result.getPoint
        this.totalPoint = result.totalPoint
      }
      if (r.item) {
        this.checkList.items = r.item
      }
      if (r.note) {
        this.checkList.note = r.note
        for (let i = 0; i < this.checkList.items.length; i++) {
          const it = this.checkList.items[i]
          for (let j = 0; j < this.checkList.note.length; j++) {
            const no = this.checkList.note[j]
            if (it.sortNo == no.num) {
              it.note = no.note
              break
            }
          }
        }
      }
      if (r.lastNote) {
        this.checkList.lastNote = r.lastNote
      }
      if (r.memo && r.memo.length && r.memo[0].memo) {
        this.checkList.memo = r.memo[0].memo
        let c = 1
        if (this.checkList.memo != '') {
          let mIdx = 0
          for (let k = 0; k < this.checkList.memo.length; k++) {
            let memo = this.checkList.memo[k]
            if (memo == '\n') {
              mIdx = 0
            } else {
              mIdx++
              if (mIdx == 56) {
                const a = this.checkList.memo.slice(0, k)
                const b = this.checkList.memo.slice(k)
                this.checkList.memo = a + '\n' + b
                mIdx = 0
              }
            }
          }
          c = ( this.checkList.memo.match( /\n/g ) || [] ).length
        }
        
        if (c) {
          this.memoCount = c + 2
        } else {
          this.memoCount = 1
        }
      }
    },

    /**
     * 初期化
     */
    initData() {
      // 表示データ
      this.list = []
      // 開始時間
      this.start = ''
      // 終了時間
      this.end = ''
      // チェック項目
      this.checkList = {
        items: [
          {classification: '', item: '', value: '', valueNA: false, disabled: false, photo: [], complete: false}
        ],
        note: [
          {id: null, checklistId: null, num: '', note: ''},
        ],
        lastNote: [
          {id: null, checklistId: null, num: '', note: ''},
        ]
      }
      // 得点
      this.point = 0
      this.totalPoint = 0
      // カラム
      this.columns = [
          {title: '時期'},
          {title: 'No.'},
          {title: '指摘事項および指導内容'},
          {title: ''}
      ]
      // 位置情報
      this.location = ''
      // 点検表マスタ設定モーダル
      this.openCheckListSettingModal = false
      this.openModal = 0
      // 並べ替え
      this.sortable = false
      // 編集できるか
      this.canEdit = true
      // 備考の大きさ
      this.memoCount = 5
      // 処理中
      this.processing = false
      // 点検完了当日か
      this.isToday = false
      // 完了ボタン
      this.completeBtn = false
      // 非表示の該当なしトグル
      this.toggleNA = false
      // 保存したデータ
      this.registeredItem = []
      // 添付画像 オリジナルデータ
      this.fullImage = ''
      // 全添付画像 オリジナルデータ
      this.fullImages = null
    },

    /**
     * 履歴モーダルオープン
     */
    openHistory() {
      this.historyModal = true
      this.openHistoryModal++
    },

    /**
     * 履歴モーダルクローズ
     */
    checkListHistoryModalClose() {
      this.historyModal = false
    },

    // 添付画像 取得
    async getfullImages() {
      const res = await backend.searchData('patrolResult/getPhoto', { patrolId: this.target.id })
      if (res.data) this.fullImages = res.data
    },

    /**
     * 画像 クリック イベント
     * @param i 対象項目
     * @param j 画像インデックス
     */
    async clickImage(sortNo, i, j) {
      const key = this.target.id + '/' + sortNo + '/' + j
      if (this.fullImages && this.fullImages[key]) this.fullImage = this.fullImages[key]
      else this.fullImage = this.checkList.items[i].photo[j]
    },

    /**
     * モーダルを閉じる×ボタン
     */
    closeModal() {
      this.$emit('modalClose')
      this.initData()
    }
  }
}
</script>
<style scoped>
.content-container-app {
  width: 930px;
  height: calc(100vh - 130px);
  overflow: auto;
  padding: 10px;
}

.red {
  background: rgb(242, 70, 70);
}

.blue {
  background: rgb(86, 86, 235);
}

.max-w-lg {
  max-width: none !important;
}

.col-no {
  width: 70px;
}

.hover-color:hover {
  color: rgb(11, 118, 200);
}

.bg-orange {
  background-color: rgb(231 137 70);
}

.bg-orange:hover {
  background-color: rgb(239 111 20);
}

.vsa-item {
  background-color: rgb(214, 238, 243);
}

.file-btn {
  /* border: 3px solid blue;
  background-color: rgb(75, 191, 152); */
  display: inline-block;
  position: relative;
}

.file-type {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

_::-webkit-full-page-media,
_:future,
:root .name {
  top: 0;
  left: 0;
}

.mr-73px {
  margin-right: 73px;
}

.bg-darkblue {
  background: #010178;
}

.bg-darkgreen {
  background: #026248;
}

.ml-32p {
  margin-left: 32px;
}

.w-96pa {
  width: 96%;
}

.edit {
  border: 1px solid #053a3a;
}

.p-5p {
  padding: 5px;
}

.p-6p {
  padding: 6px;
}

.w-354 {
  width: 354px;
}

.w-370 {
  width: 370px;
}

.info-group {
  display: flex;
}

.info1 {
  width: 50%;
}

.info2 {
  width: 50%;
  margin-left: auto;
}

@media screen and (max-width: 900px) {
  .content-container-app {
    width: 900px !important;
    height: calc(100vh - 90px);
    overflow: auto;
  }

  .info-group {
    display: block;
  }

  .info1 {
    width: 100%;
  }

  .info2 {
    width: 100%;
    margin-right: auto;
  }

}
</style>