<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <PlusCircleIcon v-tooltip="'追加'" class="ml-auto mr-3 text-indigo-500 hover:text-indigo-700 active:text-indigo-600 cursor-pointer w-8 h-8" @click="edit(null)" />
    <div class="overflow-hidden rounded main-height">
      <div class="main-height overflow-auto">
        <table class="overflow-auto main table-auto mb-3">
          <thead class="main sticky top-0 bg-white">
            <tr>
              <th v-for="(f, index) in columns" :key="index" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
                <div v-if="f.title == '得意先'" class="">
                  <input
                      type="search"
                      name="filterClient" 
                      placeholder="得意先 検索"
                      class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900" 
                      v-model="filterClient"
                  />
                </div>
                <div v-if="f.title == '支店名'" class="">
                  <input
                      type="search"
                      name="filterClient2" 
                      placeholder="支店名 検索"
                      class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900" 
                      v-model="filterClient2"
                  />
                </div>
                <div v-if="f.title == 'メールアドレス'" class="">
                  <input
                      type="search"
                      name="filterMail" 
                      placeholder="メアド 検索"
                      class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900" 
                      v-model="filterMail"
                  />
                </div>
                <div v-if="f.title == '担当者'" class="">
                  <input
                      type="search"
                      name="filterPic" 
                      placeholder="担当者 検索"
                      class="w-full py-2 pr-3 border border-gray-300 rounded-md leading-5 focus:placeholder-gray-500 focus:text-gray-900" 
                      v-model="filterPic"
                  />
                </div>
                <div v-else></div>
              </th>
            </tr>
            <tr v-if="list && list.length" class="border border-gray-200">
              <th v-for="(c, index) in columns" :key="index" scope="col" class="p-3 text-xs font-medium text-gray-500 tracking-wider bg-colu" :class="index==0?'text-center':'text-left'">
                {{c.title}}
              </th>
            </tr>
          </thead>

          <tr v-for="(row, i) in list" :key="i" class="border border-gray-200 tracking-wider">

            <!-- code -->
            <!-- <td v-if="row.rowspan!=-1" class="b-top text-center p-3 whitespace-nowrap text-sm font-medium text-gray-500 col-no" :rowspan="row.rowspan">
              {{ row.clientCode }}
            </td> -->

            <!-- 得意先名 -->
            <td v-if="row.rowspan!=-1" class="p-3 whitespace-nowrap text-sm text-gray-700" :rowspan="row.rowspan">
              {{ row.client }}
            </td>

            <!-- 支店名 -->
            <td v-if="row.rowspan!=-1" class="p-3 whitespace-nowrap text-sm text-gray-700" :rowspan="row.rowspan">
              {{ row.client2 }}
            </td>

            <!-- メアド -->
            <td class="p-3 whitespace-nowrap text-sm text-gray-700">
              {{ row.mail }}
            </td>

            <!-- 担当者 -->
            <td class="p-3 whitespace-nowrap text-sm text-gray-700">
              {{ row.pic }}
            </td>
            
            <td v-if="row.rowspan!=-1" class="text-center text-sm font-medium border-gray" :rowspan="row.rowspan">
              <div class="flex justify-center">
                <div v-if="selectable" class="p-3">
                  <PrimaryButton text="反映" size="normal" @click="apply(row)" />
                </div>
                <div class="text-blue-700 p-3 cursor-pointer text-center m-auto w-fit" @click="edit(row)">
                  編集
                </div>
              </div>
            </td>
          </tr>
        </table>

      </div>
      <div v-if="!list || !list.length" class="flex justify-center items-center text-gray-500 font-bold nothing">
        <p class="text-gray-400 font-bold">対象データがありません</p>
      </div>

      <!-- 得意先メールマスタ編集 -->
      <ClientMailEditModal 
        v-model="clientEditModalShow"
        :clientEditModalShow="clientEditModalShow"
        :target="editData"
        :originalList="originalList"
        @closeClientEditModal="closeClientEditModal"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import ClientMailEditModal from '../components/ClientMailEditModal.vue'
import * as clientMailManager from '@managers/clientMailManager'
import * as utils from '@libs/utils'
import { PlusCircleIcon } from '@vue-hero-icons/solid'
export default {
  components: {
    Modal,
    PrimaryButton,
    ClientMailEditModal,
    PlusCircleIcon
  },

  props: ['clientModalShow', 'selectable', 'index'],

  data() {
    return {
      // カラム
      columns: [
          // {title: '得意先コード'},
          {title: '得意先'},
          {title: '支店名'},
          {title: 'メールアドレス'},
          {title: '担当者'},
          {title: ''},
      ],
      // リスト
      originalList: [],
      list: [],
      // 得意先絞込み
      filterClient: '',
      // 支店名絞込み
      filterClient2: '',
      // メアド絞込み
      filterMail: '',
      // 担当者絞込み
      filterPic: '',
      // 編集モーダル
      clientEditModalShow: false,
      // 編集対象データ
      editData: []
    }
  },

  computed: {
  },

  watch: {
    filterClient() {
      this.filtering()
    },

    filterClient2() {
      this.filtering()
    },

    filterMail() {
      this.filtering()
    },

    filterPic() {
      this.filtering()
    },

    clientModalShow() {
      if (this.clientModalShow) {
        this.getList()
      }
    }
  },

  methods: {
    /**
     * リスト取得
     */
    async getList() {
      // データ取得
      const result = await clientMailManager.getMail(this)
      if (result.length) {
        this.originalList = result
      } else {
        this.originalList =  [{
          clientCode: null,
          client: '',
          details: []
        }]
      }
      this.filtering()
    },

    /**
     * 検索
     */
    filtering() {
      this.list = []
      let res = []

      if (this.filterClient || this.filterClient2 || this.filterMail || this.filterPic) {
        for (let i = 0; i < this.originalList.length; i++) {
          const o = this.originalList[i]
          if (this.isHit(this.filterClient, o.client) && this.isHit(this.filterClient2, o.client2)) {
            if (o.details) {
              let ma = ''
              let pi = ''
              for (let j = 0; j < o.details.length; j++) {
                const de = o.details[j]
                ma += de.mail
                pi += de.pic
              }
              if (this.isHit(this.filterMail, ma) && this.isHit(this.filterPic, pi)) {
                res.push(o)
              }
            }
          }
        }
      } else {
        res = utils.clone(this.originalList)
      }

      // 画面表示の形に生成
      for (let i = 0; i < res.length; i++) {
        const r = res[i]
        for (let j = 0; j < r.details.length; j++) {
          const d = r.details[j]
          
          let rowspan = -1
          if (j == 0) {
            rowspan = r.details.length
          }

          this.list.push({
            _id: r._id,
            clientCode: r.clientCode,
            client: r.client,
            client2: r.client2,
            mail: d.mail,
            pic: d.pic,
            details: r.details,
            rowspan
          })
        }
      }
    },

    /**
     * 値を含んでいるか否か
     * @param val 検索値
     * @param tar 検索対象
     */
    isHit(val, tar) {
      if (!val) {
        return true
      } else if (tar && utils.hankaku(tar).toLowerCase().indexOf(utils.hankaku(val).toLowerCase()) != -1) {
        return true
      } else {
        return false
      }
    },

    /**
     * 反映ボタン
     * @param row 対象行
     */
    apply(row) {
      const p = []
      if (row.details) {
        row.details.forEach(d => {
          if (d.pic) {
            p.push(d.pic)
          }
        })
      }
      row.pic = p.join('、')
      row.index = this.index
      this.$emit('closeClientModal', row)
    },

    /**
     * 編集ボタンイベント
     * @param row 編集対象データ
     */
    edit(row) {
      this.editData = null
      // 編集
      if (row) {
        this.editData = []

        this.editData = this.list.filter((l) => {
          return row._id == l._id
        })
      }

      this.clientEditModalShow = true
    },

    /**
     * 編集モーダルclode
     */
    closeClientEditModal() {
      this.clientEditModalShow = false
      this.getList()
    },

    closed() {
      // リスト
      this.originalList = []
      this.list = []
      // 得意先絞込み
      this.filterClient = ''
      // 得意先絞込み
      this.filterClient = ''
      // 支店名絞込み
      this.filterClient2 = ''
      // メアド絞込み
      this.filterMail = ''
      // 担当者絞込み
      this.filterPic = ''
    }
  }
}
</script>
<style scoped>
.main-height {
  height: 85vh;
}

.h-95vh {
  height: 95vh;
}

.h-93vh {
  height: 93vh;
}

.main {
  min-width: 700px;
}
.col-no {
  width: 100px;  
}

.min-300 {
  min-width: 300px;
}

.min-70 {
  min-width: 70px;
}

.b-top {
  border-top: 1px solid rgb(228, 227, 227);
}

.bg-colu {
  background: #ebfbec;
}

.nothing {
  position: absolute;
  top: 45%;
  left: 40%;
}

@media screen and (max-width: 1200px) {
  .main {
      overflow: auto;
  }
}
</style>