import Vue from 'vue'
import VueRouter from 'vue-router'
import Signin from '../pages/Signin.vue'
import Launcher from '../pages/Launcher.vue'
import EstimationAppRoutes from '@Estimation/EstimationAppRoutes'
import RequestPostAppRoutes from '@RequestPost/RequestPostAppRoutes'
import RequestPost from '@RequestPost/pages/RequestPost.vue'
import LogAppRoutes from '@log/LogAppRoutes'
import UserAppRoutes from '@User/UserAppRoutes'
import DepartmentAppRoutes from '@Department/DepartmentAppRoutes'
import PatrolAppRoutes from '@Patrol/PatrolAppRoutes'
import RoundCleanAppRoutes from '@RoundClean/RoundCleanAppRoutes'
import FixedCleanAppRoutes from '@FixedClean/FixedCleanAppRoutes'
import FacilityPatrolAppRoutes from '@FacilityPatrol/FacilityPatrolAppRoutes'
import PhotoReportAppRoutes from '@PhotoReport/PhotoReportAppRoutes'
import MasterAppRoutes from '@Master/MasterAppRoutes'

import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: { isPublic: true, title: 'RP - サインイン' }
  },
  {
    path: '/',
    name: 'Launcher',
    component: Launcher,
    meta: { requiresAuth: true, title: 'RP - メニュー' }
  },
  {
    path: '/customersRequestPost',
    name: 'customersRequestPost',
    component: RequestPost,
    meta: { isPublic: true, title: 'RP - お客様メインタスク' }
  },
  {
    ...EstimationAppRoutes
  },
  {
    ...RequestPostAppRoutes
  },
  {
    ...LogAppRoutes
  },
  {
    ...UserAppRoutes
  },
  {
    ...DepartmentAppRoutes
  },
  {
    ...PatrolAppRoutes
  },
  {
    ...RoundCleanAppRoutes
  },
  {
    ...FixedCleanAppRoutes
  },
  {
    ...FacilityPatrolAppRoutes
  },
  {
    ...PhotoReportAppRoutes
  },
  {
    ...MasterAppRoutes
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isSignin) {
    next({ path: '/signin', query: { redirect: to.fullPath } });
  } else {
    next();
  }
})

router.afterEach((to) => {
  document.title = to.meta.title || 'apollo-RP'

  const targetPages = [
    // 写真報告関連
    '/PhotoReport', '/PhotoReportList',
    // 巡回 予定一覧
    '/ScheduleListByStaff', '/RoundScheduleListByStaff', '/FixedScheduleListByStaff', '/FacilityScheduleListByStaff',
    // 巡回 未点検一覧
    '/UnPatrolList', '/ClaimUnPatrolList', '/RoundUnPatrolList', '/FixedUnPatrolList', '/FacilityUnPatrolList',
    // 巡回 完了一覧
    '/CheckList', '/ClaimCheckList', '/RoundCheckList', '/FixedCheckList', '/FacilityCheckList'
  ]
  let isTargetPage = false
  for (let i = 0; i < targetPages.length; i++) {
    const t = targetPages[i]
    if (to.path.indexOf(t) >= 0) {
      isTargetPage = true
      console.log(to.path)
      break
    }
  }
  if (isTargetPage) {
    document.querySelector("meta[name='viewport']").setAttribute('content', '')
  } else {
    document.querySelector("meta[name='viewport']").setAttribute('content', 'width=device-width')
  }
})

export default router
