<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div class="rounded overflow-auto w-fit">
			<div class="">
				<div class="font-medium text-sm text-gray-500 mb-1">
					新規作成
				</div>
				<div class="text-sm text-gray-500 mb-2">
					テンプレートを選択してください。
				</div>
				<div v-for="(row) in list" :key="row.key" class="mb-2 mx-2">
					<PrimaryButton :text="row.typeName" size="normal" class="w-full" @click="templateSelected(row)" />
				</div>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import * as backend from '@libs/backend'

export default {
	components: {
		Modal,
		PrimaryButton,
		// Icon,
	},

	props: ['photoReportModalShow'],

	data() {
		return {
			list: [
				{ id: 1, type: 1, typeName: 'フリー入力', commonItem: false, title: 'フリー入力' }
			],

		}
	},

	computed: {
	},

	watch: {
		photoReportModalShow() {
			if (this.photoReportModalShow) {
				this.getData()
			}
		}
	},

	methods: {
		/**
		 * モーダルclose
		 */
		closed() {
		},

		async getData() {
			const res = await backend.getData('photoReport/getType')
			if (res.data.data && res.data.data.length) {
				this.list = res.data.data
			}
		},

		/**
		 * テンプレート選択イベント
		 */
		async templateSelected(row) {
      this.$router.push({ 
        name: 'PhotoReport',
        params: {
          target: row
        },
			})
			// this.$router.push({ name: row.key })
			this.$emit('close')
		}
	}
}
</script>
<style scoped>
</style>