<template>
	<Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
		<div v-if="!chartLoading&&chartData&&chartData.labels" class="overflow-auto main">
			<line-chart
				:chart-data="chartData"
				:height="100"
				:width="400"
			></line-chart>
		</div>
	</Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import LineChart from '../components/LineChart.vue'

export default {
	components: {
		Modal,
		LineChart
	},

	props: ['open', 'chartDataDays', 'chartLoading'],

	data() {
		return {
			chartData: {}
		}
	},

	watch: {
		open() {
			if (this.open && !this.chartLoading) {
				this.chartData = this.chartDataDays
			}
		}
	},

	methods: {
		closed() {
			console.log('CountingDetailAveDaysModal:closed')
		}
	}
}
</script>
<style scoped>
	.main {
		width: 90vw;
		height: fit-content;
	}
</style>