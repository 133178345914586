<template>
  <Modal v-bind="$attrs" v-on="$listeners" @closed="closed">
    <div class="overflow-hidden rounded main-height m-1">
      <div class="flex justify-between">

        <!-- 削除ボタン -->
        <div v-if="isEdit" class="ml-auto mt-1">
          <PrimaryButton text="削除" size="red-color text-white font-bold rounded px-3 py-2" class="" @click="deleteAll()" />
        </div>
      </div>

      <WmsSuggestInput
        name="client" 
        caption="得意先" 
        class="mb-3"
        :required="true"
        v-model="val.client"
        :selectionItems="clientLists"
        :displayField="['clientName', 'clientDepartmentName']"
        @selected="clientSelected"
        @change="clientChanged"
      />

      <WmsTextInput 
        name="client2" 
        caption="支店名" 
        class="mb-3"
        v-model="val.client2"
      />

      <!-- <WmsTextInput 
        name="clientCode" 
        caption="得意先コード"
        class="mb-3"
        v-model="val.clientCode"
      /> -->
      
      <!-- メール追加ボタン -->
      <div class="flex justify-end mb-1 mr-1">
        <PrimaryButton text="メール追加" size="normal" class="bg-blue-700" @click="add()" />
      </div>

      <p v-if="resultAddress" class="emailInputCheck">{{ resultAddress }}</p>

      <table class="overflow-auto main table-auto">
        <thead class="main">
          <tr class="bg-blue-50">
            <th v-for="(c, index) in columns" :key="index" scope="col" class="border border-gray-200 px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider">
              {{c.title}}
            </th>
          </tr>
        </thead>

        <tr v-for="(row, i) in val.details" :key="i" class="bg-white tracking-wider">
          <!-- メアド -->
          <td class="border border-gray-200 px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 min-300">
            <input 
              type="text"
              class="cursor-text border-none w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500"
              v-model="row.mail"
            />
          </td>

          <!-- 担当者 -->
          <td class="border border-gray-200 px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700 min-300">
            <input 
              type="text"
              class="cursor-text border-none w-full py-1 focus:bg-yellow-100 focus:ring-indigo-500 focus:border-indigo-500"
              v-model="row.pic"
            />
          </td>

          <!-- 削除 -->
          <td class="border border-gray-200 px-0.5 py-0.5 whitespace-nowrap text-sm text-gray-700">
            <div class="flex justify-center">
              <a href="#" @click="deleteRow(i)">
                <TrashIcon class="text-gray-400 cursor-pointer hover:text-gray-500 active:text-gray-600 w-5 h-5" />
              </a>
            </div>
          </td>
        </tr>
      </table>
      <div class="mt-3 mb-1 mx-1">
        <PrimaryButton text="保存" size="normal w-full " @click="save()" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal.vue'
import PrimaryButton from '@components/PrimaryButton.vue'
import WmsSuggestInput from '@wmscomponents/WmsSuggestInput.vue'
import WmsTextInput from '@wmscomponents/WmsTextInput.vue'
import { TrashIcon } from '@vue-hero-icons/solid'
import * as clientMailManager from '@managers/clientMailManager'
import * as estimateManager from '@managers/estimateManager'
import * as dialogs from '@libs/dialogs'
import * as logManager from '@managers/logManager'
export default {
  components: {
    WmsSuggestInput,
    WmsTextInput,
    Modal,
    PrimaryButton,
    TrashIcon,
  },

  props: ['clientEditModalShow', 'target', 'originalList'],

  data() {
    return {
      // カラム
      columns: [
        {title: 'メールアドレス'},
        {title: '担当者'},
        {title: '削除'},
      ],
      // 編集データ
      val: {
        clientCode: '',
        client: '',
        client2: '',
        details: [
            {
                mail: '',
                pic: ''
            }
        ]
      },
      // 編集か否か
      isEdit: false,
      // メアドチェック結果
      resultAddress: null,
      // 得意先データ取得
      clientLists: []
    }
  },

  computed: {
  },

  watch: {
    clientEditModalShow() {
      if (this.clientEditModalShow) {
        this.getData()
      }
    }
  },

  methods: {
    /**
     * 編集対象データ取得
     */
    async getData() {
      // 編集対象データがある場合
      if (this.target) {
        this.isEdit = true
        this.val = {}
        this.val = {
            _id: this.target[0]._id,
            clientCode: this.target[0].clientCode,
            client: this.target[0].client,
            client2: this.target[0].client2,
            details: []
        }
        for (let i = 0; i < this.target.length; i++) {
          const t = this.target[i]
          let detail = { mail: t.mail, pic: t.pic }
          this.val.details.push(detail)
        }
      } else {
        this.isEdit = false
      }

      this.clientLists = await estimateManager.getClient(null)
    },

    /**
     * 得意先選択イベント
     */
    clientSelected(selectedClient) {
      this.val.clientCode = selectedClient.clientCode
      this.val.client = selectedClient.clientName
      this.val.client2 = selectedClient.clientDepartmentName
    },

    /**
     * 得意先変更イベント
     */
    clientChanged() {
      if (!this.val.client) {
        this.val.clientCode = ''
        this.val.client2 = ''
      }
    },

    /**
     * メール追加ボタンイベント
     */
    add() {
      this.val.details.push({
          mail: '',
          pic: ''
      })
    },

    /**
     * 削除ボタンイベント
     * @param i インデックス
     */
    async deleteRow(i) {
      this.val.details.splice(i, 1)

      if (!this.val.details || !this.val.details.length) {
        this.add()
      }
    },

    /**
     * 得意先削除
     */
    async deleteAll() {
      let resDlog = await dialogs.showConfirmDialog('削除', '削除してよろしいでしょうか？')
      if (resDlog != 'YES') {
        return
      }

      let result = await clientMailManager.deleteClient(this, this.val._id)
      if (result) {

        logManager.recording(this, logManager.Loglevel.INFO, '得意先メールアドレス', '削除', `得意先（${this.val.client}）を削除しました。`)

        this.closed
        this.$emit('closeClientEditModal')

      } else {
        await dialogs.showErrorDialog('エラー', '削除中にエラーが発生しました。')
      }
    },
    

    /**
     * 入力チェック
     */
    async validate() {
      if (!this.val.client || this.val.client == '') {
        await dialogs.showErrorDialog('得意先名', '得意先名を入力してください。')
        return false
      }

      for (let j = 0; j < this.val.details.length; j++) {
        const m = this.val.details[j].mail
        if (m && m != '') {
          const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
          if (!reg.test(m)) {
            await dialogs.showErrorDialog('メールアドレス不正', `${j + 1}番目のメールアドレスが不正です。`)
            return false
          }
        }
      }
      return true
    },

    /**
     * 保存イベント
     */
    async save() {
      let detail = []
      let isPic = false
      // メアド・担当者、どちらか記載ある分を取得
      for (let i = 0; i < this.val.details.length; i++) {
        const d = this.val.details[i]
        if (d.mail != '' || d.pic != '') {
          detail.push(d)
          if (d.pic != '') {
              isPic = true
          }
        }
      }

      // メアド・担当者、未記入の場合、空を1行追加
      if (!detail || !detail.length) {
        detail = [{
            mail: '',
            pic: ''
        }]
      }

      this.val.details = detail

      if (!await this.validate()) {
        return
      }

      let result

      if (isPic) {
        let dLog = await dialogs.showConfirmDialog('担当者の敬称', '担当者はそのままメールに表示されます。\r\n敬称などの確認はよろしいでしょうか？')
        if (dLog != 'YES') {
          return
        }
      }

      // 更新
      if (this.val._id && this.val._id != '') {
        result = await clientMailManager.updateClient(this, this.val)

        logManager.recordingByRegist(this, false, '得意先メールアドレス', `得意先（${this.val.client}）`)

      // 新規
      } else {
        result = await clientMailManager.registerClient(this, this.val)

        logManager.recordingByRegist(this, true, '得意先メールアドレス', `得意先（${this.val.client}）`)
      }
      
      if (!result) {
        await dialogs.showErrorDialog('保存エラー', '保存中にエラーが発生しました。')
        return
      }

      this.closed
      this.$emit('closeClientEditModal')
    },

    /**
     * 初期化
     */
    closed() {
      // 編集データ
      this.val = {
        client: '',
        client2: '',
        details: [
            {
                mail: '',
                pic: ''
            }
        ]
      },
      // 編集か否か
      this.isEdit = false
    }
  }
}
</script>
<style scoped>
.main-height {
    max-height: calc(100% - 200px);
}

.min-300 {
    min-width: 300px;
}

.red-color {
    background: #c94242;
}

@media screen and (max-width: 1200px) {
    .main {
        overflow: auto;
    }
}
</style>